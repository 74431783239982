import { confirmAlert } from 'react-confirm-alert'

export const initiateDeviceReminder = (id, history) => {
  return confirmAlert({
    title: 'Important Reminder',
    message: "Note: This is a reminder to update your device's config by pressing the reset button twice to ensure the latest config is properly imported.",
    buttons: [
      {
        label: 'View Experiment',
        onClick: () => history.push(`/experiments/${id}/dashboard`)
      },
      {
        label: 'Where can I find the button? Refer to Step 6',
        onClick: () => {
          window.open('https://magnitudeio-95903.groovehq.com/help/how-to-change-the-light-color-in-my-exolab', '_blank')
          history.push(`/experiments/${id}/dashboard`)
        }
      }
    ]
  })
}

import { useSelector, useDispatch } from 'react-redux'
import { Box } from 'grommet'
import NavButton from './NavButton'
import { sidebarProps } from './constants'
import { useEffect, useState } from 'react'
import { addNodes } from '../../../actions/sidebar'
import { getUsersActivations, generateLessonNodes } from './utils'

const NavList = () => {
  const { ui, auth, initial } = useSelector(state => state) || {}
  const { enableStudentLedExoLabs } = initial || {}

  const returnKeys = () => {
    const keySet = new Set()

    auth.user.roles.forEach(({ type }) => {
      let roleKeys = sidebarProps[type].keys

      // line 17-22 is for omitting deviceManager from students nav menu
      if (type === 'student' && !enableStudentLedExoLabs) {
        roleKeys = roleKeys.filter((key) => key !== 'deviceManager')
      }

      roleKeys.forEach(key => {
        keySet.add(key)
      })
    })

    return Array.from(keySet)
  }

  const [nodes, setNodes] = useState(false)
  const dispatch = useDispatch()

  // On mount, generate lessons node for navbar
  useEffect(() => {
    (async function () {
      const nodes = await generateLessonNodes(auth.user.roles, initial.activations)
      dispatch(addNodes(nodes))
      setNodes(nodes)
    })()
  }, [])

  const NavListComp = ({ keys }) => {
    /*
      keys: here are all the keys for a user's navlist.
      ui.side.tree: we check if the initialState tree object contains that key.
      NOTE: if a navbar item is empty or blank, that means the initialState -
        object file doesn't contain that key and we must add it.
    */
    return (
      <Box style={{ flexDirection: 'column', 'overflowY': 'auto', position: 'relative', marginTop: '10px', marginBottom: '15px' }} height='90vh'>
        {keys.map((key, i) => {
          return key !== 'keys' && ui.sidebar.tree[key] && <NavButton flex={{ shrink: 0 }} key={i} {...ui.sidebar.tree[key]} />
        })}
      </Box>
    )
  }

  // only render once nodes object is created
  if (nodes) {
    // here we assemble the keys for mapping the user's navbar.
    const newKeys = [...returnKeys(), ...getUsersActivations(auth, nodes, initial)]

    return <NavListComp keys={newKeys} />
  } else {
    return null
  }
}

export default NavList

export const sidebarProps = {
  'teacher': {
    keys: [
      'dashboard',
      'announcements',
      'lessonLibrary',
      'lessons',
      'notes',
      'classes',
      'featuredLab',
      'groundTrials',
      'deviceManager'
    ]
  },
  'district-admin': {
    keys: [
      'dashboard',
      'announcements',
      'lessonLibrary',
      'studentNotes'
    ]
  },
  'student': {
    keys: [
      'dashboard',
      'announcements',
      'myNotes',
      'essays',
      'featuredLab',
      'groundTrials',
      'deviceManager'
    ]
  },
  'superadmin': {
    keys: [
      'dashboard',
      'announcements',
      'lessonLibrary',
      'createLesson',
      'manageUsers',
      'featuredLab',
      'groundTrials',
      'devicesManager'
    ]
  },
  'author': {
    keys: [
      'dashboard',
      'announcements',
      'lessonLibrary',
      'lessons'
    ]
  },
  'editor': {
    keys: []
  },
  'homeschool': {
    keys: []
  }
}

// REDUCERS
import api from '../reducers/api'
import auth from '../reducers/auth'
import ui from '../reducers/ui'
import initial from '../reducers/initial'
import googleClassroom from '../reducers/googleClassroom'

const createRootReducer = {
  auth,
  initial,
  ui,
  api,
  googleClassroom
}

export default createRootReducer

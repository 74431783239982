import { Box, Heading } from 'grommet'
import { useSelector } from 'react-redux'
import DraggableContainer from '../Draggable'
import Card from './Card'
import CompactCard from './Card/CompactCard'
import { moveLesson } from '../../actions/lessons'
import { hasRole, userHasProductActivated } from '../../utils/utilityFuncs'

const LessonsList = ({ product, lessons, notDraggable, isVertical }) => {
  const { roles } = useSelector(state => state.auth.user)
  const { activations } = useSelector(state => state.initial)
  const canEdit = hasRole(['author', 'superadmin'], roles)

  // items for the draggable component
  const items = lessons.map((lesson, i) => ({
    id: `lesson-card-${i}`,
    props: lesson,
    moveAction: (payload) => moveLesson(payload, lesson)
  }))

  const showLessons = (product) => {
    // allows authors, admins, and products that are global to pass.
    if (canEdit || product.isGlobal) return true

    // otherwise we check to see if this use has this product activated.
    return userHasProductActivated(product, activations)
  }

  if (lessons.length && showLessons(product)) {
    return (
      <Box pad='medium'>
        <Heading level={3}> {product.name} </Heading>
        {canEdit && !notDraggable
          ? (
            <DraggableContainer
              component={CompactCard}
              items={items}
              type='lessons'
              maxItems={4} // max per row
              direction={isVertical ? 'vertical' : 'horizontal'}
            />
          ) : (
            <Box direction='row' wrap>
              {lessons.map((lesson, i) =>
                <Card key={i} {...lesson} notDraggable />
              )}
            </Box>
          )
        }
      </Box>
    )
  } else {
    return null
  }
}

export default LessonsList

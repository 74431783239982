
// ACTION TYPES
import {
  SIDEBAR_TOGGLE,
  SIDEBAR_FOCUS,
  SIDEBAR_SUBMENU_TOGGLE,
  SET_LESSON_NODES,
  SHOW_NAVBAR,
  HIDE_NAVBAR
} from '../../actions/sidebar'

// MAIN REDUCER
export default function sidebar (
  state,
  action
) {
  switch (action.type) {
    default: {
      return state
    }
    // add cases here
    case SIDEBAR_TOGGLE: {
      // when the Sidebar is open/closed
      return {
        ...state,
        active: action.payload
      }
    }
    case SHOW_NAVBAR: {
      return {
        ...state,
        show: true
      }
    }
    case HIDE_NAVBAR: {
      return {
        ...state,
        show: false
      }
    }
    case SET_LESSON_NODES: {
      // here we copy the lesson library state
      const lessonLibrary = { ...state.tree.lessonLibrary }

      // lesson nodes we want to add to lessonLibrary
      const nodes = action.payload

      // spread lesson nodes
      const newLessonLibrary = {
        ...lessonLibrary,
        nodes
      }

      return {
        ...state,
        tree: {
          ...state.tree,
          lessonLibrary: newLessonLibrary
        }
      }
    }
    case SIDEBAR_FOCUS: {
      // when the Sidebar is moused over
      const newState = {
        ...state,
        focused: action.payload
      }

      if (action.payload === false) {
        newState.active = false

        // set any of our child submenus to inactive
        const newTree = {
          ...newState.tree
        }

        for (let nodeKey in newTree) {
          // for all tree nodes that have "open",
          // turn "open" value to payload.open
          const node = newTree[nodeKey]
          if (node.open !== undefined) {
            node.open = false
          }
        }

        // attach our mutated tree
        newState.tree = newTree
      }

      return newState
    }
    case SIDEBAR_SUBMENU_TOGGLE: {
      // when a sub-menu in the Sidebar is opened/closed
      const newState = {
        ...state
      }

      const { key, open } = action.payload

      if (newState.tree[key] !== undefined) {
        // to do a deep  update, we copy our sub-states
        // then re-attach the copied, modified object
        // to our copied newState
        const newTree = {
          ...newState.tree
        }

        // modify our property
        newTree[key].open = open
        // attach our new tree
        newState.tree = newTree
      } else if (key === 'ALL') {
        // close all submenus
        const newTree = {
          ...newState.tree
        }

        for (let nodeKey in newTree) {
          // for all tree nodes that have "open",
          // turn "open" value to payload.open
          const node = newTree[nodeKey]
          if (node.open !== undefined) {
            node.open = open
          }
        }

        // attach our mutated tree
        newState.tree = newTree
      }

      return newState
    }
  }
}

// FETCH THUNK
import { dispatchFetchApi } from './thunk'

// TYPES
export const NOTES_LIST = 'NOTES_LIST'

export function listNotesAction (payload) {
  return {
    type: NOTES_LIST,
    payload
  }
}

export const getNotes = () => {
  return dispatchFetchApi(
    '/notes',
    listNotesAction
  )
}

/* ADD NOTE */
export const NOTE_ADD = 'NOTE_ADD'

export function addNoteAction (payload) {
  return {
    type: NOTE_ADD,
    payload
  }
}

export const addNote = (payload) => {
  return (dispatch) => {
    dispatch(dispatchFetchApi(
      '/note',
      addNoteAction,
      'POST',
      payload
    ))
    // redux-first-history
    // dispatch(push('/notes'))
  }
}

/* EDIT NOTES */
export const NOTE_EDIT = 'NOTE_EDIT'

export function editNoteAction (payload) {
  return {
    type: NOTE_EDIT,
    payload
  }
}

export const editNote = (payload) => {
  // notes id
  const { _id } = payload
  return dispatchFetchApi(
    `/notes/${_id}`,
    editNoteAction,
    'PUT',
    payload
  )
}

/* DELETE NOTE */
export const NOTE_DELETE = 'NOTE_DELETE'

export function deleteNoteAction (payload) {
  return {
    type: NOTE_DELETE,
    payload
  }
}

export const deleteNote = (payload) => {
  const { _id } = payload
  return dispatchFetchApi(
    `/notes/${_id}`,
    deleteNoteAction,
    'DELETE',
    payload
  )
}

export function notesListAction (payload) {
  return {
    type: NOTES_LIST,
    payload
  }
}

export const EDIT_ESSAY_NOTE = 'EDIT_ESSAY_NOTE'

export function editEssayNoteAction (payload) {
  return {
    type: EDIT_ESSAY_NOTE,
    payload
  }
}

export const editEssayNote = (payload) => {
  const { _id } = payload
  return dispatchFetchApi(
    `/notes/${_id}`,
    editEssayNoteAction,
    'PUT',
    payload
  )
}

// action for creating a note by type (experiment, lesson)
export const addTypeNote = (payload, type) => {
  return dispatchFetchApi(
    `/${type}/${payload._id}/notes`,
    addNoteAction,
    'POST',
    payload
  )
}

export const ADD_CLASS_LESSON_NOTE = 'ADD_CLASS_LESSON_NOTE'

export const addClassLessonNoteAction = (payload) => {
  return {
    type: ADD_CLASS_LESSON_NOTE,
    payload
  }
}

export const addClassLessonNote = (classId, lessonId, content) => {
  return dispatchFetchApi(
    `/classes/${classId}/lessons/${lessonId}/notes`,
    addClassLessonNoteAction,
    'POST',
    { content }
  )
}

// action for getting notes by type
export const getTypeNotes = (_id, type) => {
  return dispatchFetchApi(
    `/${type}/${_id}/notes`,
    listNotesAction
  )
}

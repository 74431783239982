import { sendData, fetchData, fetchApi } from '../actions/thunk'


// Get all labs constants + actions
export const GET_LABS_SUCCESS = 'GET_LABS_SUCCESS'
export const GET_LABS_FAIL = 'GET_LABS_FAIL'

export const getLabsSuccess = (payload) => {
  return {
    type: GET_LABS_SUCCESS,
    payload
  }
}

export const getLabsFail = (error) => {
  return {
    type: GET_LABS_FAIL,
    payload: error
  }
}

export const getLabs = () => {
  return fetchData(
    '/labs',
    getLabsSuccess,
    getLabsFail
  )
}

// Get featured labs
export const getFeaturedLab = () => {
  return fetchData(
    '/labs/featured',
    getLabsSuccess,
    getLabsFail
  )
}

// Get a lab by id constants + actions
export const GET_LAB_SUCCESS = 'GET_LAB_SUCCESS'
export const GET_LAB_FAIL = 'GET_LAB_FAIL'

export const getLabSuccess = (payload) => {
  return {
    type: GET_LAB_SUCCESS,
    payload
  }
}

export const getLabFail = (error) => {
  return {
    type: GET_LAB_FAIL,
    payload: error
  }
}

export const getLab = (labId) => {
  return fetchData(
    `/labs/${labId}?includeDataEventDates=true`,
    getLabSuccess,
    getLabFail
  )
}

// Add a lab constants + actions
export const ADD_LAB_SUCCESS = 'ADD_LAB_SUCCESS'
export const ADD_LAB_FAIL = 'ADD_LAB_FAIL'

export const addLabSuccess = (payload) => {
  return {
    type: ADD_LAB_SUCCESS,
    payload
  }
}

export const addLabFail = (error) => {
  return {
    type: ADD_LAB_FAIL,
    payload: error
  }
}

export const addLab = (data) => {
  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      '/labs',
      'POST',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(addLabSuccess(payload))
      return '/devices/iss-labs'
    }
  }
}

// Edit lab constants + actions
export const EDIT_LAB_SUCCESS = 'EDIT_LAB_SUCCESS'
export const EDIT_LAB_FAIL = 'EDIT_LAB_FAIL'

export const editLabSuccess = (payload) => {
  return {
    type: EDIT_LAB_SUCCESS,
    payload
  }
}

export const editLabFail = (error) => {
  return {
    type: EDIT_LAB_FAIL,
    payload: error
  }
}

export const editLab = (data, _id) => {
  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      `/labs/${_id}`,
      'PUT',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(editLabSuccess(payload))
      return '/devices/iss-labs'
    }
  }
}

// End lab constants + actions
export const ENDLAB_SUCCESS = 'ENDLAB_SUCCESS'
export const ENDLAB_FAIL = 'ENDLAB_FAIL'

export const endLabSuccess = (payload) => {
  return {
    type: ENDLAB_SUCCESS,
    payload
  }
}

export const endLabFail = (error) => {
  return {
    type: ENDLAB_FAIL,
    payload: error
  }
}

export const endLab = (_id) => {
  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      `/labs/${_id}/end`,
      'POST',
      { endDate: Date.now }
    ))

    if (type === 'SUCCESS') {
      dispatch(endLabSuccess(payload))
      return '/devices/iss-labs'
    }
  }
}

// Delete lab constants + actions
export const DELETE_LAB_SUCCESS = 'DELETE_LAB_SUCCESS'
export const DELETE_LAB_FAIL = 'DELETE_LAB_FAIL'

export const deleteLabSuccess = (payload) => {
  return {
    type: DELETE_LAB_SUCCESS,
    payload
  }
}

export const deleteLabFail = (error) => {
  return {
    type: DELETE_LAB_FAIL,
    payload: error
  }
}

export const deleteLab = (_id) => {
  return sendData(
    `/labs/${_id}`,
    'DELETE',
    {},
    deleteLabSuccess,
    deleteLabFail
  )
}

// Get all experiments for labs constants + actions
export const GET_LAB_EXPERIMENTS_SUCCESS = 'GET_LAB_EXPERIMENTS_SUCCESS'
export const GET_LAB_EXPERIMENTS_FAIL = 'GET_LAB_EXPERIMENTS_FAIL'

export const getLabExperimentsSuccess = (payload) => {
  return {
    type: GET_LAB_EXPERIMENTS_SUCCESS,
    payload
  }
}

export const getLabExperimentsFail = (error) => {
  return {
    type: GET_LAB_EXPERIMENTS_FAIL,
    payload: error
  }
}

export const getLabExperiments = (_id) => {
  return fetchData(
    `/labs/${_id}/experiments`,
    getLabExperimentsSuccess,
    getLabExperimentsFail
  )
}

export const CLEAR_LAB = 'CLEAR_LAB'

// Clear lab api
export const clearLab = () => {
  return {
    type: CLEAR_LAB
  }
}

export const GET_LAB_DATA_SUCCESS = 'GET_LAB_DATA_SUCCESS'
export const GET_LAB_DATA_FAIL = 'GET_LAB_DATA_FAIL'

export const getLabDataSuccess = (payload) => {
  return {
    type: GET_LAB_DATA_SUCCESS,
    payload
  }
}

export const getLabDataFail = (error) => {
  return {
    type: GET_LAB_DATA_FAIL,
    payload: error
  }
}

export const getLabData = (id, beginTime, endTime) => {
  return fetchData(
    `/labs/${id}/data?begin=${beginTime}&end=${endTime}`,
    getLabDataSuccess,
    getLabDataFail
  )
}

import { useSelector, useDispatch } from 'react-redux'
import { Box, TextInput, Text, Select, Anchor } from 'grommet'
import { useDebounce } from '../../../hooks/useDebounce'
import { FormItem } from '../../Form/FormItem'
import { setSchoolState, setOnboardingState } from '../../../actions/onboarding'
import { useEffect, useState, useMemo } from 'react'
import countryList from 'react-select-country-list'
import { initSchoolState } from '../../../reducers/ui/onboarding'

const CreateSchool = () => {
  const { schoolState: state, showDistrictCreate } = useSelector(state => state.ui.onboarding)
  const defaultOptions = useMemo(() => countryList().getData(), [])
  const [options, setOptions] = useState(defaultOptions)

  const dispatch = useDispatch()
  const flag = ['name', 'location', 'countryName'].map(name => state[name]).includes('')
  const debounceFlag = useDebounce(flag, 100)

  // handles onChange
  useEffect(() => {
    dispatch(setOnboardingState({
      nextBtnDisabled: flag
    }))
  }, [debounceFlag])

  const onClick = () => {
    dispatch(setOnboardingState({
      showSchoolCreate: false,
      schoolState: initSchoolState
    }))
  }

  return (
    <Box gap='small' pad='small'>
      <Text size='small' weight='bold'> Enter school information: </Text>
      <Box>
        <FormItem label='School Name*:' name='name' required>
          <TextInput
            name='name'
            id='name'
            placeholder='Name'
            value={state.name}
            onChange={(e) => dispatch(setSchoolState({ 'name': e.target.value }))}
          />
        </FormItem>
        <FormItem label='Location*:' name='location' size='medium' required>
          <TextInput
            name='location'
            id='location'
            placeholder='Location'
            value={state.location}
            onChange={(e) => dispatch(setSchoolState({ 'location': e.target.value }))}
          />
        </FormItem>
        <FormItem label='Country*:' name='countryName' size='medium' required>
          <Select
            id='countryName'
            name='countryName'
            onSearch={text => {
              const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
              const exp = new RegExp(escapedText, 'i')
              setOptions(defaultOptions.filter(o => exp.test(o.label)))
            }}
            onClose={() => setOptions(defaultOptions)}
            value={state.countryName}
            valueKey='value'
            labelKey='label'
            placeholder='Country'
            options={options}
            onChange={({ option }) => dispatch(setSchoolState({ countryName: option }))}
          />
        </FormItem>
      </Box>
      {!showDistrictCreate && (
        <Anchor label='Return to search view' onClick={onClick} margin={{ left: 'xsmall' }} />
      )}
    </Box>
  )
}

export default CreateSchool

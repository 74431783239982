import {
  CLASSES_LIST,
  CLASS_ADD,
  CLASS_ARCHIVE,
  CLASS_UNARCHIVE
} from '../../actions/class'

import {
  GET_USER_INFO
} from '../../actions/user'

const initialState = {
  isFetched: false,
  entities: {},
  ids: []
}

export default function classes (state = initialState, { payload, type }) {
  switch (type) {
    case CLASSES_LIST: {
      const { entities, ids } = payload.classes
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case CLASS_ADD: {
      const newClass = payload.class

      // copy state
      const ids = [...state.ids]
      const entities = { ...state.entities }

      // add new classes to state
      ids.push(newClass._id)
      entities[newClass._id] = newClass

      return {
        ...state,
        ids,
        entities
      }
    }
    case CLASS_ARCHIVE: {
      const newClass = payload.class
      const newEntities = { ...state.entities }

      newEntities[newClass._id] = {
        ...newClass,
        active: false
      }

      return {
        ...state,
        entities: newEntities
      }
    }
    case CLASS_UNARCHIVE: {
      const newClass = payload.class
      const newEntities = { ...state.entities }

      newEntities[newClass._id] = {
        ...newClass,
        active: true
      }
      return {
        ...state,
        entities: newEntities
      }
    }
    case GET_USER_INFO: {
      return {
        ...state,
        isFetched: true,
        ...payload.classes
      }
    }
    case 'CLEAR_CLASSES': {
      return initialState
    }
    default: {
      return state
    }
  }
}

// IMPORTS
import { Image } from 'grommet'

// ASSETS
import logo from '../../assets/logo.png'
import logoWhite from '../../assets/logo_white.png'

const Logo = ({ dark, ...rest }) => (
  <Image
    src={dark ? logo : logoWhite}
    alt='Magnitude.io - Powered by Curiosity'
    fit='contain'
    {...rest}
  />
)

export default Logo

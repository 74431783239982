import { dispatchFetchApi, fetchApi } from './thunk'
import { listClassesAction } from '../actions/class'

/* Get teachers constants + actions */
export const GET_ALL_TEACHERS = 'GET_ALL_TEACHERS'

export function getAllTeachersAction (payload) {
  return {
    type: GET_ALL_TEACHERS,
    payload
  }
}

export const getTeachers = () => {
  return dispatchFetchApi(
    '/teachers',
    getAllTeachersAction
  )
}

/* Get all students by teacher id constants + actions */
export const GET_STUDENTS_BY_TEACHER = 'GET_STUDENTS_BY_TEACHER'

export function getStudentsByTeacherAction (payload) {
  return {
    type: GET_STUDENTS_BY_TEACHER,
    payload
  }
}

export const getStudentsByTeacher = (teacherId) => {
  return dispatchFetchApi(
    `/teachers/${teacherId}/students`,
    getStudentsByTeacherAction
  )
}

export const getATeachersClasses = (_id) => {
  return dispatchFetchApi(
    `/teachers/${_id}/classes`,
    listClassesAction
  )
}

export const getTeacher = (_id) => {
  return fetchApi(`/teachers/${_id}`)
}

// payload { district, school }

export const onboardFreeTeacherAccount = (data) => {
  return fetchApi(
    '/users/me/onboarding',
    'POST',
    data
  )
}

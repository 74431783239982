import {
  LESSON_GET
} from '../../actions/lessons'

import {
  SECTION_SET_ANSWER
} from '../../actions/sections'

export default function entry (
  state = {
    /*
      <lesson section id>: <text string, or number index of multiple choice selection>
    */
  },
  action
) {
  switch (action.type) {
    default: {
      return state
    }
    case SECTION_SET_ANSWER: {
      const newState = { ...state }
      const {
        _id,
        value,
        type
      } = action.payload

      let answer = null

      switch (type) {
        case 'multiple':
          answer = parseInt(value.split('c')[1])
          break
        case 'essay':
          answer = value
          break
      }

      newState[ _id ] = answer

      return newState
    }
    case LESSON_GET: {
      let newState = { ...state }
      const { lesson } = action.payload

      if (lesson && lesson.sections && lesson.sections.length > 0) {
        /* set up our UI reducer to have an empty slot for potential answers */
        for (let s = 0; s < lesson.sections.length; s++) {
          const section = lesson.sections[s]
          newState[ section._id ] = null
        }
      }

      return newState
    }
    /*
    case ANSWERS_LIST_SUCCESS: {
      // update our in-process section answers with any previously answered questions
      const newState = {}   // starting fresh

      console.log(newState, action.payload)

      const { lessonEntryAnswers } = action.payload

      for (let id in lessonEntryAnswers.entities) {
        newState[ id ] = lessonEntryAnswers.entities[id].input
      }

      return newState
    }
    */
  }
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Text, Anchor, TextInput } from 'grommet'
import { useDebounce } from '../../../hooks/useDebounce'
import { districtProps, schoolProps } from './constants'
import { setOnboardingState } from '../../../actions/onboarding'
import FlowButtons from '../FlowButtons'
import { initSchoolState } from '../../../reducers/ui/onboarding'
import ExampleSearch from './ExampleSearch'

const SearchFilterComp = ({ searchValue, name, displayName, apiMethod, onChange }) => {
  const debounceQuery = useDebounce(searchValue, 500)
  const { onboarding } = useSelector(state => state.ui)
  const dispatch = useDispatch()

  useEffect(() => {
    const trimmedValue = searchValue.trim()

    const callApi = async () => {
      if (trimmedValue !== '' && !onboarding[name]) {
        // hit api to search for district / school information.
        const { payload } = await apiMethod()

        if (payload) {
          dispatch(setOnboardingState({
            [name]: payload[displayName.toLowerCase()],
            showFlowButtons: true
          }))
        }
      }
    }
    callApi()
  }, [debounceQuery])

  const onClick = () => {
    if (displayName === 'District') {
      dispatch(setOnboardingState({
        showDistrictCreate: true,
        districtSelection: null,
        districtQuery: ''
      }))
    }
    dispatch(setOnboardingState({
      showSchoolCreate: true,
      schoolSelection: null,
      schoolQuery: '',
      schoolState: initSchoolState,
      nextBtnDisabled: true
    }))
  }

  const onSearchClicked = () => {
    if (displayName === 'District') {
      dispatch(setOnboardingState({
        districtSelection: null,
        districtQuery: ''
      }))
    }
    dispatch(setOnboardingState({
      schoolSelection: null,
      schoolQuery: '',
      schoolState: initSchoolState,
      nextBtnDisabled: true,
      showFlowButtons: false
    }))
  }

  const NoResultsFound = () => {
    return (
      <Box direction='row' gap='xsmall'>
        <Text size='small'>
          {`Can't find a match for your search result?`}
          <Anchor label={`Create ${displayName}`} onClick={onClick} margin={{ left: 'xsmall' }} />
        </Text>
      </Box>
    )
  }

  const data = onboarding[name]
  const props = displayName === 'District' ? districtProps : schoolProps

  return (
    <Box>
      <Box gap='small'>
        <Box direction='row' gap='small'>
          <ExampleSearch type={displayName} />
          <Box basis='1/2' gap='xsmall'>
            {data
              ? (
                <Box pad='small' round='5px' background='light-3'>
                  <Text weight='bold'> {displayName} Found: </Text>
                  {props.map(({ name, value }, i) => (
                    <Text key={i} size='small'> {name}: {data[value]} </Text>
                  ))}
                  <Anchor size='small' label='Search Again' onClick={onSearchClicked} />
                </Box>
              ) : (
                <TextInput onChange={onChange} value={searchValue} placeholder={`Search ${displayName.toLowerCase()} name...`} />
              )
            }
            <NoResultsFound />
          </Box>
        </Box>
      </Box>
      <FlowButtons name={name} />
    </Box>
  )
}

export default SearchFilterComp

module.exports = {
  auth: {
    // everything related to user auth & login
    isAuthenticated: false,
    user: {
      // actual user object, with roles, account info, etc
    }
  },
  api: {
    // API models available to this user based on their role and relationships.
    districts: {
      isFetched: false,
      entities: {},
      ids: []
    },
    schools: {
      isFetched: false,
      entities: {},
      ids: []
    },
    classes: {
      isFetched: false,
      entities: {},
      ids: []
    },
    lessons: {
      isFetched: false,
      entities: {},
      ids: []
    },
    roles: {
      isFetched: false,
      entities: {},
      ids: []
    },
    users: {
      isFetched: false,
      entities: {},
      ids: []
    },
    products: {
      isFetched: false,
      entities: {},
      ids: []
    },
    productData: {
      /*
        for dynamic products, list them by key here, e.g.:
        5789542hfgdyaug687942fg4: {
          // an exolab, cansat, or aq monitor
          isFetched: false,
          entities: {},
          ids: []
        }
      */
    }
  },

  ui: {
    // arbitrary ui states, like a sketch state or lesson entry state
    loader: {
      loading: false
    },

    cansat: {
      device: null,
      flight: null,
      data: null // also could become an API state
    },

    sketch: {
      shield: 'green',
      sketches: [], // this should become an API state
      name: null,
      source: '',
      port: false
    },

    lesson: {
      // this state should reference a lesson entity, then keep track of state
      isFetched: false,
      title: null,
      description: null,
      approved: false,
      author: null,
      duration: null,
      grade: null,
      image: null,
      language: null,
      product: null,
      sections: [],
      subject: null
    },
    myNotes: {
      ids: [],
      entities: {},
      isFetched: false
    },
    sections: {
      /*
        <lesson section id>: <text string, or number index of multiple choice selection>
      */
    },

    sidebar: {
      show: true,
      // whether the sidebar is drawn "open"
      active: false,
      // whether the sidebar has mouse/cursor focus
      focused: false,
      // whether any child dropdowns are active
      childrenActive: false,
      // the tree of nav items our sidebar should display
      // this is a normalized data structure, which is optimized
      // for redux.
      tree: {
        dashboard: {
          text: 'Dashboard',
          name: 'dashboard',
          icon: 'Home',
          color: 'status-critical',
          url: '/'
        },
        announcements: {
          text: 'Announcements',
          icon: 'Menu',
          name: 'accouncements',
          color: 'status-critical',
          url: '/announcements'
        },
        lessonLibrary: {
          text: 'Lesson Library',
          icon: 'Menu',
          name: 'lessonLibrary',
          color: 'dark-3',
          open: false,
          nodes: {}
        },
        lessons: {
          text: 'My Lessons',
          icon: 'Menu',
          name: 'myLessons',
          color: 'dark-3',
          url: '/my-lessons'
        },
        notes: {
          text: 'Notes',
          icon: 'Menu',
          name: 'notes',
          color: 'dark-3',
          url: '/notes',
          open: false,
          nodes: {
            myNotes: {
              text: 'My Notes',
              name: 'myNotes',
              icon: 'Menu',
              color: 'dark-3',
              url: '/my-notes'
            },
            studentNotes: {
              text: 'Student Notes',
              name: 'studentNotes',
              icon: 'Menu',
              color: 'dark-3',
              url: '/student-notes'
            }
          }
        },
        studentNotes: {
          text: 'Student Notes',
          name: 'studentNotes',
          icon: 'Menu',
          color: 'dark-3',
          url: '/student-notes'
        },
        myNotes: {
          text: 'My Notes',
          name: 'myNotes',
          icon: 'Menu',
          color: 'dark-3',
          url: '/my-notes'
        },
        essays: {
          text: 'My Essays',
          name: 'essays',
          icon: 'Menu',
          color: 'dark-3',
          url: '/students/my-essays'
        },
        classes: {
          text: 'Classes',
          name: 'classes',
          icon: 'Menu',
          color: false,
          url: '/classes'
        },
        districts: {
          text: 'Districts',
          name: 'districts',
          icon: 'Menu',
          color: false,
          url: '/districts'
        },
        users: {
          text: 'Users',
          name: 'users',
          icon: 'Menu',
          color: false,
          url: '/users'
        },
        createLesson: {
          text: 'Create Lesson',
          name: 'createLesson',
          icon: 'Menu',
          color: false,
          url: '/lessons/create'
        },
        manageUsers: {
          text: 'Manage Users',
          name: 'manageUsers',
          icon: 'Menu',
          color: false,
          open: false,
          nodes: {
            createUser: {
              text: 'Create User',
              name: 'createUser',
              icon: 'Menu',
              color: false,
              url: '/users/create'
            },
            modifyUser: {
              text: 'Modify User',
              name: 'modifyUsers',
              icon: 'Menu',
              color: false,
              url: '/users'
            }
          }
        },
        exolab: {
          text: 'ExoLab',
          name: 'exolab',
          icon: 'Menu',
          color: 'dark-3',
          url: '/exolab'
        },
        pastMissions: {
          text: 'Past ExoLab Missions',
          name: 'pastMissions',
          icon: 'Menu',
          color: 'dark-3',
          url: '/lessons/product/exolab/past-missions-links'
        },
        cansat: {
          text: 'CanSat',
          name: 'cansat',
          icon: 'Menu',
          color: 'dark-3',
          open: false,
          nodes: {
            devices: {
              text: 'My CanSat Devices',
              name: 'devices',
              icon: 'Menu',
              color: false,
              url: '/cansat/devices'
            },
            data: {
              text: 'Setup a Flight',
              name: 'data',
              icon: 'Menu',
              color: false,
              url: '/cansat/flight-setup'
            },
            flight: {
              text: 'My CanSat Data',
              name: 'flight',
              icon: 'Menu',
              color: 'neutral-4',
              url: '/cansat/flights'
            },
            sketch: {
              text: 'Install a Sketch',
              name: 'sketch',
              icon: 'Menu',
              color: 'status-ok',
              url: '/cansat/install-sketch'
            },
            extension: {
              text: 'Install Extension',
              name: 'extension',
              icon: 'Menu',
              color: 'dark-6',
              url: '/cansat/install-extension'
            },
            keys: [
              'devices',
              'data',
              'flight',
              'sketch',
              'extension'
            ]
          }
        },
        featuredLab: {
          text: 'Featured Lab',
          name: 'featuredLab',
          icon: 'Menu',
          color: false,
          url: '/featured-lab'
        },
        groundTrials: {
          text: 'Ground Trials',
          name: 'groundTrials',
          icon: 'Menu',
          color: false,
          url: '/exolab/ground-trials'
        },
        deviceManager: {
          text: 'Device Manager',
          name: 'deviceManager',
          icon: 'Menu',
          color: false,
          url: '/devices/manager'
        }
      }
    }
  }
}

// FETCH THUNKS
import { getFetch, dispatchFetchApi, fetchApi } from './thunk'
import { deleteDistrictAction } from './district'

// Get schools actions and constants
export const SCHOOLS_LIST = 'SCHOOLS_LIST'

export function listSchoolsAction (payload) {
  return {
    type: SCHOOLS_LIST,
    payload
  }
}

export const getSchools = () => {
  return dispatchFetchApi(
    '/schools',
    listSchoolsAction
  )
}

// add school actions + constants
export const SCHOOL_ADD = 'SCHOOL_ADD'

export function addSchoolAction (payload) {
  return {
    type: SCHOOL_ADD,
    payload
  }
}

export const addSchool = (data) => {
  const { redirect } = data
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(
      '/schools',
      'POST',
      data
    ))

    dispatch(addSchoolAction(payload))
    return `${redirect}/${payload.school.slug}`
  }
}

// edit school constants + actions
export const SCHOOL_EDIT = 'SCHOOL_EDIT'

export function editSchoolAction (payload) {
  return {
    type: SCHOOL_EDIT,
    payload
  }
}

export const editSchool = (data) => {
  const { _id } = data
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(
      `/schools/${_id}`,
      'PUT',
      data
    ))

    dispatch(editSchoolAction(payload))
    return payload.school.slug
  }
}

// delete school constants + actions
export const SCHOOL_DELETE = 'SCHOOL_DELETE'

export function deleteSchoolAction (payload) {
  return {
    type: SCHOOL_DELETE,
    payload
  }
}

export const deleteSchool = (data) => {
  const { _id } = data

  return dispatchFetchApi(
    `/schools/${_id}`,
    deleteDistrictAction,
    'DELETE',
    data
  )
}

// get students by school constants + actions
export const GET_SCHOOLS_STUDENTS = 'GET_SCHOOLS_STUDENTS'

export const getSchoolStudentsAction = (payload) => {
  return {
    type: GET_SCHOOLS_STUDENTS,
    payload
  }
}

export const getSchoolStudents = (id) => {
  return getFetch(
    `/schools/${id}/students`
  )
}

export function getTeachers (schoolId) {
  return getFetch(
    `/schools/${schoolId}/teachers`
  )
}

export const getSchool = (slug) => {
  return getFetch(`/schools/${slug}`)
}

export const SCHOOL_SEARCH = 'SCHOOL_SEARCH'

export const schoolSearchAction = (payload) => {
  return {
    type: SCHOOL_SEARCH,
    payload
  }
}

export const schoolSearch = (districtId, query) => {
  return fetchApi(
    `/districts/${districtId}/schools/search/?name=${query}`
  )
}

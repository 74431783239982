import React from 'react'
import { Box, Text, Anchor, Heading } from 'grommet'
import Logo from '../../Logo'

const Error = () => {
  return (
    <Box gap='large' margin='large'>
      <Box height='75px' width='280px' alignSelf='start' margin={{ vertical: 'small' }}>
        <Logo dark />
      </Box>
      <Heading>Oops, something went wrong...</Heading>
      <Text size='small'>
        This email is already registered in our system. Would you like to login instead?
        <Anchor href='//classroom.magnitude.io' size='small' alignSelf='center'> Try logging in </Anchor>
      </Text>
    </Box>
  )
}

export default Error

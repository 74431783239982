// IMPORTS
import React, { Component } from 'react'
import {
  Home,
  Book,
  Bookmark,
  UserSettings,
  Logout,
  List,
  Satellite,
  Database,
  Test,
  FormNext,
  Launch,
  Deploy,
  Dashboard,
  Bus,
  UserManager,
  UserPolice,
  Add,
  CloudComputer,
  Workshop,
  Group,
  Organization,
  Products,
  Cubes,
  Notes,
  UserExpert
} from 'grommet-icons'

export default function renderIcon (string) {
  let icon = <List size='15px' />
  switch (string) {
    case 'UserExpert':
      icon = <UserExpert size='15px' />
      break
    case 'Bus':
    case 'Classes':
    case 'Class':
      icon = <Bus size='15px' />
      break
    case 'UserManager':
    case 'Districts':
    case 'District':
      icon = <UserManager size='15px' />
      break
    case 'UserPolice':
      icon = <UserPolice size='15px' />
      break
    case 'Create':
      icon = <Add size='15px' />
      break
    case 'Home':
      icon = <Home size='15px' />
      break
    case 'Book':
      icon = <Book size='15px' />
      break
    case 'Bookmark':
      icon = <Bookmark size='15px' />
      break
    case 'Lessons':
      icon = <Book size='15px' />
      break
    case 'Satellite':
      icon = <Satellite size='15px' />
      break
    case 'Database':
      icon = <Database size='15px' />
      break
    case 'Data':
      icon = <Database size='15px' />
      break
    case 'Test':
      icon = <Test size='15px' />
      break
    case 'Exolab':
      icon = <Test size='15px' />
      break
    case 'Launch':
      icon = <Launch size='15px' />
      break
    case 'Cansat':
      icon = <Launch size='15px' />
      break
    case 'Deploy':
      icon = <Deploy size='15px' />
      break
    case 'Flight':
      icon = <Deploy size='15px' />
      break
    case 'Dashboard':
      icon = <Dashboard size='15px' />
      break
    case 'CloudComputer':
      icon = <CloudComputer size='15px' />
      break
    case 'Sketch':
      icon = <CloudComputer size='15px' />
      break
    case 'Classes':
      icon = <Workshop size='15px' />
      break
    case 'Workshop':
      icon = <Workshop size='15px' />
      break
    case 'Group':
    case 'Users':
      icon = <Group size='15px' />
      break
    case 'Roles':
    case 'UserSettings':
      icon = <UserSettings size='15px' />
      break
    case 'Organization':
    case 'Schools':
      icon = <Organization size='15px' />
      break
    case 'Products':
    case 'Cubes':
      icon = <Cubes size='15px' />
      break
    case 'Notes':
    case 'My notes':
      icon = <Notes size='15px' />
      break
  }
  return icon
}

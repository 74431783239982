// Actions for displaying successful overlay
export const SHOW_SUCCESS = 'SHOW_SUCCESS'

export const showSuccess = (message) => {
  return {
    type: SHOW_SUCCESS,
    payload: message
  }
}

// Action for displaying error overlay
export const SHOW_ERROR = 'SHOW_ERROR'

export const showError = (message) => {
  return {
    type: SHOW_ERROR,
    payload: message
  }
}

export const SHOW_MESSAGE = 'SHOW_MESSAGE'

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
}

// Action for clearing overlay
export const CLEAR_FLASH_OVERLAY = 'CLEAR_FLASH_OVERLAY'

export const clearFlashOverlay = () => {
  return {
    type: CLEAR_FLASH_OVERLAY
  }
}

// IMPORTS
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { css } from '@emotion/react'
import { useLocation, Link, useHistory } from 'react-router-dom'

// COMPONENTS
import { Box, Button, Text } from 'grommet'
import { FormNext } from 'grommet-icons'
import NavParent from './NavParent'
import renderIcon from '../../../utils/renderIcon'

// ACTIONS
import { toggle, focus } from '../../../actions/sidebar'

const NavButton = (props) => {
  const { active } = useSelector(state => state.ui.sidebar)
  const [open, setOpen] = useState(false)
  const { text, url, nodes, icon } = props

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  // to highlight current route in navbar
  const matchedUrl = location?.pathname === url

  const onClick = (e) => {
    /*
      when a nav button is clicked, multiple scenarios occur:
      anything is clicked, and the sidebar is inactive:
        activate sidebar
      if the button has children, and the side bar is active:
        open the children
      if a button has no children, and the side bar is active:
        navigate to the url

      also:
      when the sidebar is inactive, close the children
    */

    e.stopPropagation()

    const hasNodes = !(nodes === undefined)

    if (!active) {
      dispatch(toggle(true))
    } else {
      if (hasNodes) {
        setOpen(open => !open)
      } else {
        dispatch(focus(false))
        history.push(url)
      }
    }
  }

  const TextComp = () => {
    if (nodes) {
      return (
        <Text size='small' color='white'>
          {text}
        </Text>
      )
    } else {
      return (
        <Link to={url}>
          <Text size='small' css={css`color: white !important;`}>
            {text}
          </Text>
        </Link>
      )
    }
  }

  return (
    <Box display='flex' background='#2B303B' margin={{ top: 'hair' }} pad='5px' flex={{ shrink: 0 }}>
      <Button plain focusIndicator onClick={onClick}>
        <Box
          margin={{ right: '3px' }}
          direction='row'
          justify='between'
          align='center'
        >
          <Box direction='row' justify='start' align='center' pad={{ left: 'small' }}>
            <TextComp />
            {nodes && (
              <FormNext
                size='10px'
                css={css`
                  transition: 0.2s ease-in-out all;
                  transform: rotate(${open ? '90deg' : 0});
                `}
              />
            )}
          </Box>
          <Box pad='10px' background={matchedUrl ? 'brand' : '#2B303B'}>
            {renderIcon(icon)}
          </Box>
        </Box>
      </Button>
      {nodes && (
        <NavParent
          open={open}
          nodes={nodes}
          onClick={onClick}
        />
      )}
    </Box>
  )
}

export default NavButton

import { useSelector } from 'react-redux'
import { Loader } from './Loader'

const GlobalLoader = () => {
  const { loading } = useSelector(state => state.ui.loader)

  if (loading) {
    return <Loader />
  } else {
    return null
  }
}

export default GlobalLoader

import { hasRole, userHasProductActivated } from '../../../utils/utilityFuncs'
import { getFetch } from '../../../actions/thunk'

export const getUsersActivations = (auth, nodes, initial) => {
  if (hasRole(['superadmin'], auth.user.roles)) {
    // superadmins should see all products. (not sure about district admins)
    return nodes.keys
  } else if (hasRole(['district-admin', 'teacher', 'student'], auth.user.roles)) {
    // if role is teacher or student we need to get user's activations
    // if no active activations then use empty payload
    const { ids, entities } = initial.activations || { ids: [], entities: {} }

    // incase a user has overlapping activations of the same product.
    const activations = new Set()

    for (const id of ids) {
      // skip empty product activation references
      if(!entities[id].product) continue

      const { name, parent } = entities[id].product

      if (parent) {
        activations.add(parent.name.toLowerCase())
      } else {
        activations.add(name.toLowerCase())
      }
    }
    return [...activations]
  } else {
    return []
  }
}

// for lessonlibrary, we want to fetch all active products. e.g ExoLab, CanSat , Etc
export const getProducts = async (parentSlug) => {
  const baseUrl = `/products`
  const payload = await getFetch(parentSlug ? baseUrl + `?parent_slug=${parentSlug}` : baseUrl)
  const { ids, entities } = payload.products
  return ids.map(id => entities[id])
}

export const generateLessonNodes = async (roles, activations) => {
  // stores stucture of each lesson node by key
  const nodes = {}
  // stores key of each node
  const keys = []

  const products = await getProducts()
  const isAuthor = hasRole(['author'], roles)
  const isAdmin = hasRole(['superadmin'], roles)

  // add links to all lessons and unapproved lessons for super admins
  if (isAdmin) {
    nodes['allLessons'] = {
      text: 'All Lessons',
      icon: 'Launch',
      url: '/lessons'
    }

    nodes['unapprovedLessons'] = {
      text: 'Unapproved Lessons',
      icon: 'Launch',
      url: '/lessons/unapproved'
    }

    nodes['archivedLessons'] = {
      text: 'Archived Lessons',
      icon: 'Launch',
      url: '/lessons/archived'
    }
  }

  for (const product of products) {
    const activated = isAuthor || isAdmin || product.isParent || userHasProductActivated(product, activations)

    if (product.isGlobal || activated) {
      const childNodes = {
        'allLessons': {
          text: 'All Lessons',
          icon: 'Launch',
          name: 'allLessons',
          url: `/lessons/product/${product.slug}`
        }
      }

      if (product.isParent) {
        const childProducts = await getProducts(product.slug)

        for (const childProduct of childProducts) {
          const childActivated = isAuthor || isAdmin || userHasProductActivated(childProduct, activations)
          if (childActivated) {
            const { name, slug } = childProduct
            childNodes[name] = {
              text: name,
              icon: 'Launch',
              name: name,
              url: `/lessons/product/${slug}`
            }
          }
        }
      }

      let key = null

      if (!product.parent) {
        // make sure to remove spaces and add "-" to avoid url mismatch
        key = product.name.toLowerCase().split(' ').join('-')
        keys.push(key)
      }

      if (key) {
        nodes[key] = {
          text: product.name,
          name: product.slug,
          key,
          icon: 'Launch',
          url: `/lessons/product/${product.slug}`,
          nodes: product.isParent ? childNodes : null,
          open: false
        }
      }

      if (product.slug === 'exolab' && activated) {
        nodes['previousMission'] = {
          text: 'Past Exolab Missions',
          icon: 'Launch',
          url: '/lessons/product/exolab/past-missions-links'
        }
      }

      if (product.slug === 'exolab' && activated) {
        nodes['otherPreviousMission'] = {
          text: 'Other Past Missions',
          icon: 'Launch',
          url: '/lessons/product/exolab/past-missions-links-others'
        }
      }
    }
  }

  /*
    Example structure:
      {
        cansat: {
          text: 'CanSat',
          icon: 'Launch',
          url: '/lessons/product/cansat'
        },
        keys: [
          'cansat'
        ]
      }
  */

  return {
    ...nodes,
    keys
  }
}

/* global fetch */
import { address, generateOptions } from './constants'
import { push } from 'redux-first-history'
import { setErrorStatus } from './errorHandler'
import { toggle } from './loader'

function redirectHandler (user) {
  const { _id } = user || {}
  const redirect = window.location.pathname
  const redirectPaths = localStorage.getItem('redirectPaths')
  
  const key = _id || 'default'
  
  const paths = redirectPaths
    ? { ...JSON.parse(redirectPaths), [key]: redirect }
    : { [key]: redirect }

  localStorage.setItem('redirectPaths', JSON.stringify(paths))
}

// checkResponses handles all response status
function checkResponses (response, json, dispatch, user) {
  /*
    Informational responses (100–199)
    Successful responses (200–299)
    Redirects (300–399)
    Client errors (400–499)
    Server errors (500–599)
  */

  if (response.status === 401) {
    redirectHandler(user)
    window.location.href = '/login'
  } else if (response.status === 422) {
    dispatch(setErrorStatus({
      code: response.status,
      json
    }))
  }
}

// fetchApi is our main fetch method returns json object
export function fetchApi (route, method, data) {
  const apiOptions = generateOptions(method, data)
  return async (dispatch, getState) => {
    const { user } = getState()?.auth
    try {
      dispatch(toggle(true))
      const response = await fetch(`${address}${route}`, apiOptions)
      const json = await response.json()

      // checker for response errors
      checkResponses(response, json, dispatch, user)

      dispatch(toggle(false))
      return json
    } catch (error) {
      throw (error)
    }
  }
}

// dispatchFetchApi is used for fetching and dispatching an action with payload.
export function dispatchFetchApi (route, action, method, data, refreshPage) {
  return async (dispatch) => {
    const json = await dispatch(fetchApi(route, method, data))

    if (json.type === 'SUCCESS') {
      dispatch(action(json.payload))
    }

    if (refreshPage) {
      window.location.reload(false)
    }
  }
}

// function for fetching data
export function fetchData (route, successAction, failAction) {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  return async (dispatch) => {
    try {
      const response = await fetch(`${address}${route}`, options)
      const { payload, type } = await response.json()

      if (type === 'SUCCESS') {
        if (successAction) {
          dispatch(successAction(payload))
        } else {
          return payload
        }
      } else {
        if (failAction) dispatch(failAction(payload))
      }
    } catch (error) {
      console.error(error)
    }
  }
}

// for updating, creating data.
export function sendData (route, method, clientPayload, successAction, failAction, redirectTo, payloadType) {
  const options = {
    method,
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(clientPayload)
  }

  return async (dispatch) => {
    try {
      const response = await fetch(`${address}${route}`, options)
      const { payload, type } = await response.json()

      if (type === 'SUCCESS') {
        if (successAction) dispatch(successAction(payload))

        if (redirectTo) {
          if (payloadType) {
            dispatch(push(`${redirectTo}/${payload[payloadType].slug}`))
          } else {
            dispatch(push(redirectTo))
          }
        }
      } else {
        if (failAction) dispatch(failAction(payload))
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export async function getFetch (route) {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    const response = await fetch(`${address}${route}`, options)
    const { payload, type } = await response.json()

    if (type === 'SUCCESS') {
      return payload
    } else {
      return false
    }
  } catch (error) {
    console.error(error)
  }
}

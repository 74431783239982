import {
  Box,
  Typography,
  Link as Anchor
} from '@mui/material'

const Footer = ({ stacked }) => {
  const year = new Date().getFullYear()

  return (
    <Box>
      <hr style={{ color: 'grey', width: '100%' }} />
      <Box 
        sx={{
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <Box>
          <Typography textAlign='center' fontSize='14px'> 
            {`© ${year}` } <a target='_blank' href='https://magnitude.io'> Magnitude.io. </a> {`All rights reserved.`}
          </Typography>
        </Box>
        <Box
          sx={{
            gap: '10px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Anchor target='_blank' href='https://magnitude.io/terms-of-use/'>Terms of Use</Anchor>
          |
          <Anchor target='_blank' href='https://magnitude.io/privacy-policy/'>Privacy Policy</Anchor>
          |
          <Anchor target='_blank' href='https://magnitude.io/disclaimer/'>Disclaimer</Anchor>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer

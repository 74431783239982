// district props
export const districtProps = [
  {
    name: 'Name',
    value: 'name'
  },
  {
    name: 'Location',
    value: 'location'
  },
  {
    name: 'Zip Code',
    value: 'zipCode'
  }
]

// school props
export const schoolProps = [
  {
    name: 'Name',
    value: 'name'
  },
  {
    name: 'Location',
    value: 'location'
  },
  {
    name: 'Country',
    value: 'countryName'
  }
]

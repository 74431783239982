import {
  LESSON_GET,
  LESSON_ADD,
  LESSON_DELETE,
  LESSON_CLONE,
  LESSON_UI_CLEAR
} from '../../actions/lessons'

import {
  ADD_QUESTION_SUCCESS
} from '../../actions/quiz'

import {
  SECTION_DELETE,
  SECTION_MOVE
} from '../../actions/sections'

import initialState from '../initialState'

export const lesson = (state = initialState.ui.lesson, { payload, type }) => {
  switch (type) {
    case LESSON_GET: {
      const { lesson } = payload

      // sort sections based on position
      const sortedSection = lesson.sections ? lesson.sections.sort((a, b) => a.position - b.position) : []
      return {
        ...state,
        isFetched: true,
        ...lesson,
        sections: sortedSection
      }
    }
    case SECTION_DELETE: {
      const { _id } = payload.section
      const newSection = state.sections.filter(section => section._id !== _id)
      return {
        ...state,
        sections: newSection
      }
    }
    case LESSON_ADD: {
      return {
        ...state,
        isFetched: true,
        ...payload.lesson
      }
    }
    case LESSON_CLONE: {
      return {
        isFetched: true,
        ...payload.lesson,
        sections: payload.lesson.sections.sort((a, b) => a.positions - b.position)
      }
    }
    case LESSON_DELETE: {
      return initialState.ui.lesson
    }
    case LESSON_UI_CLEAR: {
      return initialState.ui.lesson
    }
    case ADD_QUESTION_SUCCESS: {
      const { updatedQuiz } = payload
      const sections = [...state.sections]

      for (let i = 0; i < sections.length; i++) {
        if (sections[i].quiz._id === updatedQuiz._id) {
          sections[i].quiz = updatedQuiz
          break
        }
      }
      return {
        ...state,
        sections
      }
    }
    case SECTION_MOVE: {
      return {
        ...state,
        sections: payload.sections
      }
    }
    default:
      return state
  }
}

export default lesson

import { useState, useEffect } from 'react'
import { Layer, Text } from 'grommet'
import { Loader } from '../../components/GlobalLoader/Loader'

const ConfirmationLoader = () => {
  // const statuses = [
  //   'Linking information to your account',
  //   'Creating your personalized class',
  //   'Assigning lessons to your class',
  //   "You're all set!"
  // ]

  // const [index, setIndex] = useState(0)

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex(index + 1)
  //   }, 1500)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  return <Loader />

  // return (
  //   <Layer full margin='25%'>
  //     <Text> {statuses[index]}</Text>
  //     <Loader />
  //   </Layer>
  // )
}

export default ConfirmationLoader

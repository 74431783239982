import initialState from '../initialState'

// ACTION TYPES
// import * as UserActions from '../../actions/user'

// SIBLING REDUCERS
import districtsReducer from './districts'
import schoolsReducer from './schools'
import classesReducer from './classes'
import lessonsReducer from './lessons'
import sectionsReducer from './sections'
import usersReducer from './users'
import rolesReducer from './roles'
import notesReducer from './notes'
import productsReducer from './products'
import labsReducer from './labs'
import devicesReducer from './devices'
import experimentsReducer from './experiments'
import quizzesReducer from './quizzes'
import announcementsReducer from './announcements'

import { LOGOUT_USER_SUCCESS } from '../../actions/user'

// MAIN REDUCER
export default function api (
  // include subset of initialState so our reducers start with something
  state = initialState.api,
  action
) {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS: {
      return initialState.api
    }
    default: {
      return {
        districts: districtsReducer(state.districts, action),
        schools: schoolsReducer(state.schools, action),
        classes: classesReducer(state.classes, action),
        lessons: lessonsReducer(state.lessons, action),
        sections: sectionsReducer(state.sections, action),
        users: usersReducer(state.users, action),
        roles: rolesReducer(state.roles, action),
        notes: notesReducer(state.notes, action),
        products: productsReducer(state.products, action),
        labs: labsReducer(state.labs, action),
        devices: devicesReducer(state.devices, action),
        experiments: experimentsReducer(state.experiments, action),
        quizzes: quizzesReducer(state.quizzes, action),
        announcements: announcementsReducer(state.announcements, action)
      }
    }
  }
}

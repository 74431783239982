import { fetchData, sendData, getFetch, dispatchFetchApi, fetchApi } from './thunk'
import { deleteSection } from '../actions/sections'

// Add question constants + actions
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS'
export const ADD_QUESTION_FAIL = 'ADD_QUESTION_FAIL'

const addQuestionSuccess = (payload) => {
  return {
    type: ADD_QUESTION_SUCCESS,
    payload
  }
}

const addQuestionFail = (error) => {
  return {
    type: ADD_QUESTION_FAIL,
    payload: error
  }
}

export const addQuestion = (payload) => {
  const { quizId } = payload
  return sendData(
    `/quizzes/${quizId}/questions`,
    'POST',
    payload,
    addQuestionSuccess,
    addQuestionFail
  )
}

// Edit question constants + actions
export const EDIT_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
export const EDIT_QUESTION_FAIL = 'EDIT_QUESTION_FAIL'

const editQuestionSuccess = (payload) => {
  return {
    type: EDIT_QUESTION_SUCCESS,
    payload
  }
}

const editQuestionFail = (error) => {
  return {
    type: EDIT_QUESTION_FAIL,
    payload: error
  }
}

export const editQuestion = (payload) => {
  const { quizId, questionId } = payload
  return sendData(
    `/quizzes/${quizId}/questions/${questionId}`,
    'PUT',
    payload,
    editQuestionSuccess,
    editQuestionFail
  )
}

// Delete question constants + actions
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL'

const deleteQuestionSuccess = (payload) => {
  return {
    type: DELETE_QUESTION_SUCCESS,
    payload
  }
}

const deleteQuestionFail = (error) => {
  return {
    type: DELETE_QUESTION_FAIL,
    payload: error
  }
}

export const deleteQuestion = (payload) => {
  const { quizId, questionId } = payload
  return sendData(
    `/quizzes/${quizId}/questions/${questionId}`,
    'DELETE',
    payload,
    deleteQuestionSuccess,
    deleteQuestionFail
  )
}

export const createQuiz = (data, createAnother) => {
  const {
    lessonSlug,
    title,
    questions,
    teacherOnly
  } = data || {}

  return async (dispatch) => {
    const sectionUrl = `/lessons/${lessonSlug}/sections`
    const section = await dispatch(fetchApi(sectionUrl, 'POST', { title, teacherOnly }))
    let redirectUrl

    if (section.type === 'SUCCESS') {
      const quiz = await dispatch(fetchApi('/quizzes', 'POST', { questions, title }))

      if (quiz.type === 'SUCCESS') {
        const { lesson: lessonId, _id: sectionId } = section.payload.lessonSection
        const connectUrl = `/lessons/${lessonId}/sections/${sectionId}/quiz`
        const connected = await dispatch(fetchApi(connectUrl, 'POST', { quiz: quiz.payload.quiz._id }))

        if (connected.type === 'SUCCESS') {
          redirectUrl = `/lessons/${lessonSlug}`

          if (createAnother) {
            redirectUrl = redirectUrl + '/sections/create'
          }
        }
      } else {
        // delete section is quiz response was not successful
        dispatch(deleteSection({ _id: section._id, slug: lessonSlug }))
      }
    }
    return redirectUrl
  }
}

/* Edit quiz constants + actions */

export const EDIT_QUIZ_SUCCESS = 'EDIT_QUIZ_SUCCESS'
export const EDIT_QUIZ_FAIL = 'EDIT_QUIZ_FAIL'

export const editQuizSuccess = (payload) => {
  return {
    type: EDIT_QUIZ_SUCCESS,
    payload
  }
}

export const editQuizFail = (payload) => {
  return {
    type: EDIT_QUIZ_FAIL,
    payload
  }
}

export const editQuiz = (payload) => {
  const { quiz, lessonSlug, description, title, questions } = payload

  return async (dispatch) => {
    const url = `/quizzes/${quiz._id}`
    const { type, payload } = await dispatch(fetchApi(url, 'PUT', { description, title, questions }))

    if (type === 'SUCCESS') {
      dispatch(editQuizSuccess(payload))
    }
    
    return `/lessons/${lessonSlug}`
  }
}

export const deleteQuiz = ({ quiz, sectionId, lessonSlug }) => {
  return async dispatch => {
    const { type } = await dispatch(fetchApi(`/quizzes/${quiz._id}`, 'DELETE', {}))

    if (type === 'SUCCESS') {
      dispatch(deleteSection({
        _id: sectionId,
        slug: lessonSlug
      }))
    }
  }
}

/* -------------------------------------------------------------------------------- */

// Get all quiz instance associated with class and lesson (teachers and superadmin)
export const GET_QUIZ_INSTANCES_SUCCESS = 'GET_QUIZ_INSTANCES_SUCCESS'
export const GET_QUIZ_INSTANCES_FAIL = 'GET_QUIZ_INSTANCES_FAIL'

export const getQuizInstancesSuccess = (payload) => {
  return {
    type: GET_QUIZ_INSTANCES_SUCCESS,
    payload
  }
}

export const getQuizInstancesFail = (error) => {
  return {
    type: GET_QUIZ_INSTANCES_FAIL,
    payload: error
  }
}

// returns lesson by class quiz instances
export const getQuizInstances = ({ classId, lessonId }) => {
  return async (dispatch) => {
    // to store out quiz instance results
    try {
      const url = `/classes/${classId}/lessons/${lessonId}/quiz-instances`
      const { payload } = await dispatch(fetchApi(`${url}`))
      dispatch(getQuizInstancesSuccess({ quizzes: payload.quizInstances }))
    } catch (err) {
      throw (err)
    }
  }
}

// Starts a new Quiz for student by creating a Quiz Instance (students)
export const CREATE_QUIZ_INSTANCE_SUCCESS = 'CREATE_QUIZ_INSTANCE_SUCCESS'
export const CREATE_QUIZ_INSTANCE_FAIL = 'CREATE_QUIZ_INSTANCE_FAIL'

export const createQuizInstanceSuccess = (payload) => {
  return {
    type: CREATE_QUIZ_INSTANCE_SUCCESS,
    payload
  }
}

export const createQuizInstanceFail = (error) => {
  return {
    type: CREATE_QUIZ_INSTANCE_FAIL,
    payload: error
  }
}

export const createQuizInstance = (payload) => {
  const { classId, lessonId, sectionId } = payload
  return sendData(
    `/classes/${classId}/lessons/${lessonId}/sections/${sectionId}/quiz/start`,
    'POST',
    payload,
    createQuizInstanceSuccess,
    createQuizInstanceFail
  )
}

// Get the Quiz Instance for the class, lesson for student (student)
export const GET_QUIZ_INSTANCE_SUCCESS = 'GET_QUIZ_INSTANCE_SUCCESS'
export const GET_QUIZ_INSTANCE_FAIL = 'GET_QUIZ_INSTANCE_FAIL'

export const getQuizInstanceSuccess = (payload) => {
  return {
    type: GET_QUIZ_INSTANCE_SUCCESS,
    payload
  }
}

export const getQuizInstanceFail = (error) => {
  return {
    type: GET_QUIZ_INSTANCE_FAIL,
    payload: error
  }
}

// get a quiz instance by on ID for (students, teachers, superadmin)
export const getQuizInstance = (payload) => {
  const { _id } = payload
  return fetchData(
    `/quiz-instances/${_id}`,
    getQuizInstanceSuccess,
    getQuizInstanceFail
  )
}

// Answer a Quiz Instance question for the student (student)
export const ANSWER_QUIZ = 'ANSWER_QUIZ'

export const answerQuizSuccess = (payload) => {
  return {
    type: ANSWER_QUIZ,
    payload
  }
}

export const answerQuiz = (payload) => {
  const { quizInstanceId, questionId, type } = payload

  let data

  if (type === 'multiple') {
    data = {
      ...payload,
      answer: [payload.answer._id]
    }
  } else {
    data = payload
  }

  return dispatchFetchApi(
    `/quiz-instances/${quizInstanceId}/questions/${questionId}/answer`,
    answerQuizSuccess,
    'POST',
    data
  )
}

// Adds a teacher note to a Quiz Instance answer
export const ADD_TEACHER_QUIZ_RESPONSE_SUCCESS = 'ADD_TEACHER_QUIZ_RESPONSE_SUCCESS'
export const ADD_TEACHER_QUIZ_RESPONSE_FAIL = 'ADD_TEACHER_QUIZ_RESPONSE_FAIL'

export const addTeacherQuizResponseSuccess = (payload) => {
  return {
    type: ADD_TEACHER_QUIZ_RESPONSE_SUCCESS,
    payload
  }
}

export const addTeacherQuizResponseFail = (error) => {
  return {
    type: ADD_TEACHER_QUIZ_RESPONSE_FAIL,
    payload: error
  }
}

export const addTeacherQuizResponse = (payload) => {
  const { quizInstanceId, answerId } = payload
  return sendData(
    `/quiz-instances/${quizInstanceId}/answers/${answerId}/note`,
    'POST',
    payload,
    addTeacherQuizResponseSuccess,
    addTeacherQuizResponseFail
  )
}

// Remove teacher note from a Quiz Instance answer
export const DELETE_TEACHER_QUIZ_RESPONSE_SUCCESS = 'DELETE_TEACHER_QUIZ_RESPONSE_SUCCESS'
export const DELETE_TEACHER_QUIZ_RESPONSE_FAIL = 'DELETE_TEACHER_QUIZ_RESPONSE_FAIL'

export const deleteTeacherQuizResponseSuccess = (payload) => {
  return {
    type: DELETE_TEACHER_QUIZ_RESPONSE_SUCCESS,
    payload
  }
}

export const deleteTeacherQuizResponseFail = (error) => {
  return {
    type: DELETE_TEACHER_QUIZ_RESPONSE_FAIL,
    payload: error
  }
}

export const deleteTeacherQuizResponse = (payload) => {
  const { quizInstanceId, answerId } = payload
  return sendData(
    `/quiz-instances/${quizInstanceId}/answers/${answerId}/note`,
    'DELETE',
    {},
    deleteTeacherQuizResponseSuccess,
    deleteTeacherQuizResponseFail
  )
}

// Deletes a Quiz Instance (superadmin)
export const DELETE_QUIZ_INSTANCE_SUCCESS = 'DELETE_QUIZ_INSTANCE_SUCCESS'
export const DELETE_QUIZ_INSTANCE_FAIL = 'DELETE_QUIZ_INSTANCE_FAIL'

export const deleteQuizInstanceSuccess = (payload) => {
  return {
    type: DELETE_QUESTION_SUCCESS,
    payload
  }
}

export const deleteQuizInstanceFail = (error) => {
  return {
    type: DELETE_QUIZ_INSTANCE_FAIL,
    payload: error
  }
}

export const deleteQuizInstance = (payload) => {
  const { _id } = payload
  return sendData(
    `/quiz-instances/${_id}`,
    'DELETE',
    {},
    deleteQuizInstanceSuccess,
    deleteQuizInstanceFail
  )
}

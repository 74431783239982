// IMPORTS
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// ACTIONS
import { getUserInfo } from '../actions/user'
import { getUserProviders } from '../actions/googleClassroom'

export const UserProvider = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserInfo())
    dispatch(getUserProviders())
  }, [])

  return children
}

// Centralized file that contains constants such as API constants and address.
// Will add credentials here later on.
export const API_IP = process.env.API_IP
export const API_PORT = process.env.API_PORT
export const API_HTTPS = process.env.API_HTTPS
export const address = `${API_HTTPS ? 'https' : 'http'}://${API_IP}:${API_PORT}`

export const options = {
  credentials: 'include',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

export const generateOptions = (method, data) => {
  if (method === 'GET') {
    return options
  } else {
    return {
      ...options,
      method,
      body: JSON.stringify(data)
    }
  }
}


/*
  We want to check if we have a timer set in the local storage.
  If there is a timer that means we should not see the banner being shown.
*/

const hasDismissedTimer = () => {
  return window.localStorage.getItem('freeReminderDismissedTimer')
}

const initialState = {
  show: !hasDismissedTimer()
}

export const announcementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CLOSE_ANNOUNCEMENTS': {
      return {
        ...state,
        show: false
      }
    }
    default: {
      return state
    }
  }
}

export default announcementReducer

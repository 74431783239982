import initialState from '../initialState'
import { LOGOUT_USER_SUCCESS } from '../../actions/user'

// SIBLING REDUCERS
import loaderReducer from './loader'
import cansatReducer from './cansat'
import sketchReducer from './sketch'
import lessonReducer from './lesson'
import sidebarReducer from './sidebar'
import entryReducer from './entry'
import sectionReducer from './section'
import classroomReducer from './classroom'
import labReducer from './lab'
import experimentReducer from './experiment'
import flashOverlayReducer from './flashOverlay'
import lessonsLibraryReducer from './lessonsLibrary'
import onboardingReducer from './onboarding'
import announcementsReducer from './announcements'

// MAIN REDUCER
export default function ui (
  // include subset of initialState so our reducers start with something
  state = initialState.ui,
  action
) {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS: {
      return initialState.ui
    }
    default: {
      return {
        cansat: cansatReducer(state.cansat, action),
        lesson: lessonReducer(state.lesson, action),
        section: sectionReducer(state.section, action),
        loader: loaderReducer(state.loader, action),
        sketch: sketchReducer(state.sketch, action),
        sidebar: sidebarReducer(state.sidebar, action),
        entry: entryReducer(state.entry, action),
        classroom: classroomReducer(state.classroom, action),
        lab: labReducer(state.lab, action),
        experiment: experimentReducer(state.experiment, action),
        flashOverlay: flashOverlayReducer(state.flashOverlay, action),
        lessonsLibrary: lessonsLibraryReducer(state.lessonsLibrary, action),
        onboarding: onboardingReducer(state.onboarding, action),
        announcements: announcementsReducer(state.announcements, action)
      }
    }
  }
}

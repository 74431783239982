import { fetchApi } from '../actions/thunk'
import Moment from 'moment'

// ACTIONS
export const CANSAT_DATA_VIEW = 'CANSAT_DATA_VIEW'
export const CANSAT_DATA_VIEW_PENDING = 'CANSAT_DATA_VIEW_PENDING'
export const CANSAT_DATA_VIEW_SUCCESS = 'CANSAT_DATA_VIEW_SUCCESS'
export const CANSAT_DATA_VIEW_FAIL = 'CANSAT_DATA_VIEW_FAIL'

export const CANSAT_DATA_DOWNLOAD = 'CANSAT_DATA_DOWNLOAD'
export const CANSAT_DATA_DOWNLOAD_PENDING = 'CANSAT_DATA_DOWNLOAD_PENDING'
export const CANSAT_DATA_DOWNLOAD_SUCCESS = 'CANSAT_DATA_DOWNLOAD_SUCCESS'
export const CANSAT_DATA_DOWNLOAD_FAIL = 'CANSAT_DATA_DOWNLOAD_FAIL'

// ACTION CREATORS
// VIEW
export function dataViewPending () {
  return {
    type: CANSAT_DATA_VIEW_PENDING,
    payload: true
  }
}

export function dataViewSuccess (payload) {
  return {
    type: CANSAT_DATA_VIEW_SUCCESS,
    payload
  }
}

export function dataViewFail (error) {
  return {
    type: CANSAT_DATA_VIEW_FAIL,
    payload: error
  }
}

// DOWNLOAD
export function dataDownloadPending () {
  return {
    type: CANSAT_DATA_VIEW_PENDING,
    payload: true
  }
}

export function dataDownloadSuccess (payload) {
  return {
    type: CANSAT_DATA_VIEW_SUCCESS,
    payload
  }
}

export function dataDownloadFail (error) {
  return {
    type: CANSAT_DATA_VIEW_FAIL,
    payload: error
  }
}

// API CALLS
export function dataViewAPI (expId) {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/experiments/${expId}`))
    const { experiment } = payload
    const { startTime, endTime } = experiment

    // convert to unix timestamp
    const startUtc = Moment(startTime).utc().valueOf()
    const endUtc = Moment(endTime).utc().valueOf()
    const dataJson = await dispatch(fetchApi(`/experiments/${expId}/data/?begin=${startUtc}&end=${endUtc}`))
    const data = dataJson.payload.data

    return {
      experiment,
      events: data.length > 0 ? data : null
    }
  }
}

export function dataDownload (payload) {
  return function (dispatch, getState) {
    console.log('Data Download Thunk fired:', payload)

    dispatch(dataDownloadPending())
  }
}

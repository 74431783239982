import { FormField } from 'grommet'
import { FlexBoxWithText } from '../Boxes'

export const FormItem = ({ children, label, name, ...rest }) => {
  return (
    <FormField name={name} htmlFor={name} {...rest}>
      <FlexBoxWithText text={label} size='medium'>
        {children}
      </FlexBoxWithText>
    </FormField>
  )
}

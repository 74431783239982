import { LOGOUT_USER_SUCCESS } from '../../actions/user'
import {
  MY_LESSON_DELETE,
  MY_LESSON_ADD,
  MY_LESSON_EDIT
} from '../../actions/lessons'
import { mapQuizInstances } from '../api/util'
import { DISABLE_GOOGLE_CLASSROOM } from '../../actions/googleClassroom'
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const SET_INITIAL_QUIZ = 'SET_INITIAL_QUIZ'
export const SET_USER_GC_PROFILE = 'SET_USER_GC_PROFILE'

const initiaState = {
  isFetched: false
}

const initialReducer = (state = initiaState, { payload, type }) => {
  switch (type) {
    case SET_INITIAL_DATA: {
      localStorage.setItem('jwt', payload.jwt)
      return {
        ...state,
        isFetched: true,
        ...payload
      }
    }
    case MY_LESSON_EDIT: {
      const { _id } = payload.lesson

      return {
        ...state,
        clonedLessons: {
          ...state.clonedLessons,
          entities: {
            ...state.clonedLessons.entities,
            [_id]: payload.lesson
          }
        }
      }
    }
    case MY_LESSON_DELETE: {
      const { _id } = payload.lesson
      const { ids, entities } = { ...state.clonedLessons }

      const newIds = [...ids].filter(id => id !== _id)
      const newEntities = { ...entities }
      delete newEntities[_id]

      return {
        ...state,
        clonedLessons: {
          ids: newIds,
          entities: newEntities
        }
      }
    }
    case MY_LESSON_ADD: {
      const { _id } = payload.lesson
      const newIds = [...state.clonedLessons.ids, _id]
      const newEntities = {
        ...state.clonedLessons.entities,
        [_id]: payload.lesson
      }

      return {
        ...state,
        clonedLessons: {
          ids: newIds,
          entities: newEntities
        }
      }
    }
    case 'CLASS_ADD': {
      const newClass = payload.class

      const ids = state.classes ? [...state.classes.ids] : []
      const entities = state.classes ? { ...state.classes.entities } : {}

      ids.push(newClass._id)
      entities[newClass._id] = newClass

      return {
        ...state,
        classes: {
          ...state.classes,
          ids,
          entities
        }
      }
    }
    case 'CLASS_ARCHIVE': {
      const { _id } = payload.class

      return {
        ...state,
        classes: {
          ...state.classes,
          entities: {
            ...state.classes.entities,
            [_id]: payload.class
          }
        }
      }
    }
    case LOGOUT_USER_SUCCESS: {
      return initiaState
    }
    case SET_INITIAL_QUIZ: {
      const { ids, entities } = payload
      return {
        ...state,
        quizzes: mapQuizInstances(ids, entities)
      }
    }
    case 'CLEAR_INITIAL_DATA': {
      return initiaState
    }
    case 'GET_USER_PROVIDERS': {
      const { ids, entities } = payload.userProviders

      const providers = {}

      for (let i = 0; i < ids.length; i++) {
        const el = entities[ids[i]]
        providers[el.provider] = el
      }

      return {
        ...state,
        providers
      }
    }
    case DISABLE_GOOGLE_CLASSROOM: {
      return {
        ...state,
        providers: {
          ...state.providers,
          'google': payload.userProvider
        }
      }
    }
    default: {
      return state
    }
  }
}

export default initialReducer

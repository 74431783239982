import {
  SET_ONBOARDING_STATE,
  SET_DISTRICT_STATE,
  SET_SCHOOL_STATE,
  CLEAR_ONBOARDING_STATE,
  CLEAR_DISTRICT_STATE,
  CLEAR_SCHOOL_STATE
} from '../../actions/onboarding'

import {
  DISTRICT_SEARCH
} from '../../actions/district'

import {
  SCHOOL_SEARCH
} from '../../actions/school'

export const initDistrictState = {
  name: '',
  location: '',
  zipCode: ''
}

export const initSchoolState = {
  name: '',
  location: '',
  countryName: '',
  latitude: '',
  longitude: '',
  altitude: ''
}

export const initialState = {
  nextBtnDisabled: true,
  districtSelection: null,
  showFlowButtons: false,
  districtQuery: '',
  schoolQuery: '',
  schoolSelection: null,
  showModal: false,
  districtState: initDistrictState,
  schoolState: initSchoolState,
  organizationType: null,
  educationLevels: new Set(),
  showDistrictCreate: false,
  showSchoolCreate: false
}

export const onboardingReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_ONBOARDING_STATE: {
      return {
        ...state,
        ...payload
      }
    }
    case SET_DISTRICT_STATE: {
      return {
        ...state,
        districtState: {
          ...state.districtState,
          ...payload
        }
      }
    }
    case SET_SCHOOL_STATE: {
      return {
        ...state,
        schoolState: {
          ...state.schoolState,
          ...payload
        }
      }
    }
    case CLEAR_ONBOARDING_STATE: {
      return initialState
    }
    case CLEAR_DISTRICT_STATE: {
      return {
        ...state,
        initDistrictState
      }
    }
    case CLEAR_SCHOOL_STATE: {
      return {
        ...state,
        initSchoolState
      }
    }
    case DISTRICT_SEARCH: {
      return {
        ...state,
        districtSearchResults: payload.district
      }
    }
    case SCHOOL_SEARCH: {
      return {
        ...state,
        schoolsSearchResults: payload.school
      }
    }
    default: {
      return state
    }
  }
}

export default onboardingReducer

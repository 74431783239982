import { Anchor, Box, Text } from 'grommet'
import Logo from '../../Logo'

const SuccessMessage = ({ firstName }) => {
  return (
    <Box margin='medium' gap='large'>
      <Box height='75px' width='280px' alignSelf='start' margin={{ vertical: 'small' }}>
        <Logo dark />
      </Box>
      <Text size='xlarge'> {`Thank you for signing up with Magnitude.io, ${firstName}!`} </Text>
      <Box gap='small'>
        <Text> You registered an account, before being able to use your account you need to verify that this is your email address. </Text>
        <Text> Please check your email for next steps. </Text>
        <Anchor label='Return to login' href='/login' alignSelf='start' />
      </Box>
    </Box>
  )
}

export default SuccessMessage

import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { clearErrorStatus } from '../actions/errorHandler'
import { showError } from '../actions/flashOverlay'
import NotFoundPage from '../screens/NotFoundPage'

const ErrorHandler = () => {
  const { code, json } = useSelector(state => state.auth.errorStatus) || {}

  const history = useHistory()
  const dispatch = useDispatch()

  // clear error code once url changes.
  useEffect(() => {
    const unlisten = history.listen(() => dispatch(clearErrorStatus(undefined)))
    return unlisten
  }, [])

  if (code === 404) {
    return <NotFoundPage />
  }

  // handle any other errors here
  if (code === 422) {
    dispatch(showError({ message: json.payload }))
  }

  return null
}

export default ErrorHandler

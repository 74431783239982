import { dispatchFetchApi, fetchApi } from './thunk'
import Moment from 'moment'
import { initiateDeviceReminder } from './utils'

export const GET_EXPERIMENTS = 'GET_EXPERIMENTS'

export const getExperimentsAction = (payload) => {
  return {
    type: GET_EXPERIMENTS,
    payload
  }
}

// Get all experiments
export const getAllExperiments = (product, status) => {
  return dispatchFetchApi(
    `/experiments?product=${product}&status=${status}`,
    getExperimentsAction
  )
}

// Get users exolab experiments
export const getUsersExperiments = (status) => {
  return dispatchFetchApi(
    `/experiments/exolab/me?status=${status}`,
    getExperimentsAction
  )
}

// Get all experiments marked as isGloballyShared
export const getGlobalExperiments = (productSlug) => {
  return dispatchFetchApi(
    `/experiments/${productSlug}/globally-shared`,
    getExperimentsAction
  )
}

// Get active class experiments
export const getClassExperiments = (classId) => {
  return dispatchFetchApi(
    `/classes/${classId}/experiments`,
    getExperimentsAction
  )
}

// returns only active experiments
function filterExperiments (course, experiments) {
  const { ids, entities } = experiments
  let count = 0

  for (let i = 0; i < ids.length; i++) {
    if (entities[ids[i]].user._id === course.teacher._id) {
      count = count + 1
    }
  }

  return {
    experiments: {
      ids: ids ? ids.filter(id => !entities[id].endTime) : [],
      entities: entities || {},
      count
    }
  }
}

export function checkClassroomExperiments (course) {
  return async (dispatch) => {
    // first check if classroom has active experiments
    const { payload: classPayload } = await dispatch(fetchApi(`/classes/${course._id}/experiments`))

    const classExperiments = filterExperiments(course, classPayload.experiments)

    // we need to make sure that student personal experiments aren't counted here.
    if (classExperiments.count > 0) {
      dispatch(getExperimentsAction(classExperiments))
    } else {
      // if we don't find an active experiment in this class we look in other classes
      // next check if user has active experiment among other classes
      const { payload: allExpPayload } = await dispatch(fetchApi('/experiments/exolab/me'))

      // make sure to combine both classExperiments and allExperiments at this point
      const allExperiments = filterExperiments(course, allExpPayload.experiments)
      dispatch(getExperimentsAction(allExperiments))
    }
  }
}

// Add a new experiment
export const ADD_EXPERIMENT = 'ADD_EXPERIMENT'

export const addExperimentAction = (payload) => {
  return {
    type: ADD_EXPERIMENT,
    payload
  }
}

export function addExperiment (data, classrooms, history) {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi('/experiments', 'POST', data))
    const { _id } = payload.experiment

    if (_id) {
      for (let i = 0; i < classrooms.length; i++) {
        await dispatch(fetchApi(`/classes/${classrooms[i]}/experiments/${_id}/assign`))
      }

      dispatch(addExperimentAction(payload))
      initiateDeviceReminder(_id, history)
    }
  }
}

// Get an experiment's config settings
export const GET_EXPERIMENT_CONFIG = 'GET_EXPERIMENT_CONFIG'

export const getExperimentConfigAction = (payload) => {
  return {
    type: GET_EXPERIMENT_CONFIG,
    payload
  }
}

export const getExperimentConfig = (payload) => {
  return dispatchFetchApi(
    '/api/experiments/config',
    getExperimentConfigAction
  )
}

// Get an experiment
export const GET_EXPERIMENT = 'GET_EXPERIMENT'

export const getExperimentAction = (payload) => {
  return {
    type: GET_EXPERIMENT,
    payload
  }
}

export const getExperiment = (_id) => {
  return dispatchFetchApi(
    `/experiments/${_id}/?includeDataEventDates=true`,
    getExperimentAction
  )
}

// Update an experiment
export const UPDATE_EXPERIMENT = 'UPDATE_EXPERIMENT'

export const updateExperimentAction = (payload) => {
  return {
    type: UPDATE_EXPERIMENT,
    payload
  }
}

export const updateExperiment = (data, id, add, remove, history) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/experiments/${id}`, 'PUT', data))

    for (let i = 0; i < add.length; i++) {
      await dispatch(fetchApi(`/classes/${add[i]}/experiments/${id}/assign`))
    }

    for (let j = 0; j < remove.length; j++) {
      await dispatch(fetchApi(`/classes/${remove[j]}/experiments/${id}/unassign`))
    }

    dispatch(updateExperimentAction(payload))
    initiateDeviceReminder(id, history)
  }
}

// Archives an experiment
export const ARCHIVE_EXPERIMENT = 'ARCHIVE_EXPERIMENT'

export const archiveExperimentAction = (payload) => {
  return {
    type: ARCHIVE_EXPERIMENT,
    payload
  }
}

export const archiveExperiment = (data) => {
  const { _id } = data

  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/experiments/${_id}/archive`, 'POST', data))
    dispatch(archiveExperimentAction(payload))
    return payload
  }
}

// Get experiment data
export const GET_EXPERIMENT_DATA = 'GET_EXPERIMENT_DATA'

export const getExperimentDataAction = (payload) => {
  return {
    type: GET_EXPERIMENT_DATA,
    payload
  }
}

export const getExperimentData = (id, begin, end, sortDirection) => {
  const beginTime = Moment.utc(begin).valueOf()
  const endTime = Moment.utc(end).valueOf()
  return dispatchFetchApi(
    `/experiments/${id}/data?begin=${beginTime}&end=${endTime}&sortDirection=${sortDirection || ''}`,
    getExperimentDataAction
  )
}

export function getExperimentByDay (id, day) {
  return fetchApi(`/experiments/${id}/days/${day}/data`)
}

// set time range
export const SET_TIME_RANGE = 'SET_TIME_RANGE'

export const setTimeRange = (start, end) => {
  return {
    type: SET_TIME_RANGE,
    payload: {
      start,
      end
    }
  }
}

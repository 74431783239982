// ACTION TYPES
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_USER_SUCCESS,
  GET_USER_INFO,
  MY_ACCOUNT_EDIT
} from '../../actions/user'

import {
  SET_ERROR_STATUS,
  CLEAR_ERROR_STATUS
} from '../../actions/errorHandler'

const initialState = {
  isAuthenticated: false,
  user: null,
  failed: false,
  errorStatus: null
}

// MAIN REDUCER
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state
    }
    case LOGIN_SUCCESS: {
      return {
        isAuthenticated: true,
        user: action.payload,
        failed: false
      }
    }
    case LOGIN_FAIL: {
      return {
        failed: true
      }
    }
    case LOGOUT_USER_SUCCESS: {
      return initialState
    }
    case GET_USER_INFO: {
      return {
        ...state,
        isAuthenticated: true,
        ...action.payload
      }
    }
    case MY_ACCOUNT_EDIT: {
      const { firstName, lastName, email, username } = action.payload.user
      return {
        ...state,
        user: {
          ...state.user,
          firstName,
          lastName,
          email,
          username
        }
      }
    }
    case SET_ERROR_STATUS: {
      const { code, json } = action.payload
      return {
        ...state,
        errorStatus: {
          code,
          json
        }
      }
    }
    case CLEAR_ERROR_STATUS: {
      return {
        ...state,
        errorStatus: null
      }
    }
  }
}

export default authReducer

import {
  GET_LAB_SUCCESS,
  GET_LAB_DATA_SUCCESS,
  CLEAR_LAB
} from '../../actions/labs'

const initialState = {
  isFetched: false
}

export const labReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    default: {
      return state
    }
    case 'CLEAR_EXPERIMENT': {
      return initialState
    }
    case GET_LAB_SUCCESS: {
      return {
        isFetched: true,
        ...payload.lab
      }
    }
    case GET_LAB_DATA_SUCCESS: {
      if (payload === 'No data received' || payload.data < 1) {
        return {
          ...state,
          isFetched: true,
          lab: payload.lab,
          data: null
        }
      }
      // get data information
      const { device } = payload.data[0]
      let sensor, image, timeStamp

      for (let i = payload.data.length - 1; i >= 0; i--) {
        const { type, data, time } = payload.data[i]

        if (sensor && image) {
          break
        }

        if (type === 'sensor') {
          timeStamp = time
          sensor = data
        } else {
          timeStamp = time
          image = data
        }
      }

      return {
        ...state,
        isFetched: true,
        lab: payload.lab,
        device,
        data: {
          sensor,
          image,
          time: timeStamp
        }
      }
    }
    case CLEAR_LAB: {
      return initialState
    }
  }
}

export default labReducer

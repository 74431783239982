import {
  LESSONS_LIST,
  LESSON_ADD,
  LESSON_EDIT,
  LESSON_MOVE,
  LIST_PAST_MISSIONS
} from '../../actions/lessons'

import {
  GET_CLASS_LESSONS
} from '../../actions/class'
import { arrayify } from '../../utils/utilityFuncs'

const initialState = {
  isFetched: false,
  ids: [],
  entities: {}
}

export const lessonsReducers = (state = initialState, { payload, type }) => {
  switch (type) {
    case LESSONS_LIST: {
      const { ids, entities } = payload.lessons
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case LIST_PAST_MISSIONS: {
      const { missions } = payload

      const ids = []
      const entities = {}

      for (let i = 0; i < missions.length; i++) {
        for (let j = 0; j < missions[i].lessons.length; j++) {
          const lesson = missions[i].lessons[j]
          ids.push(lesson._id)
          entities[lesson._id] = lesson
        }
      }

      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case GET_CLASS_LESSONS: {
      const { ids, entities } = payload.classLessons
      const classLessons = arrayify(ids, entities)

      const newIds = []
      const newEntities = {}

      for (let i = 0; i < classLessons.length; i++) {
        const { lesson } = classLessons[i]
        newIds.push(lesson._id)
        newEntities[lesson._id] = lesson
      }

      return {
        isFetched: true,
        ids: newIds,
        entities: newEntities
      }
    }
    case LESSON_MOVE: {
      const newEntities = { ...state.entities }

      if (payload.lessons) {
        const { ids, entities } = payload.lessons
        

        for (let i = 0; i < ids.length; i++) {
          const { order } = entities[ids[i]]
          newEntities[ids[i]] = {
            ...state.entities[ids[i]],
            order
          }
        }
      }

      return {
        ...state,
        entities: newEntities
      }
    }
    case LESSON_ADD: {
      const { _id } = payload.lesson

      // Spread id and add
      const newIds = [...state.ids]
      newIds.push(_id)

      // spread and add lesson
      const newEntities = { ...state.entities }
      newEntities[_id] = payload.lesson

      return {
        ...state,
        entities: newEntities,
        ids: newIds
      }
    }
    case LESSON_EDIT: {
      const { _id } = payload.lesson

      // replace new payload with old payload
      const newEntities = { ...state.entities }
      newEntities[_id] = payload.lesson

      return {
        ...state,
        entities: newEntities
      }
    }
    case 'CLEAR_LESSONS': {
      return initialState
    }
    case 'CLEAR_INIT_DATA': {
      return initialState
    }
    default:
      return state
  }
}

export default lessonsReducers

/* global fetch */
// UTILS
import { push } from 'redux-first-history'
import { dispatchFetchApi, fetchApi, getFetch } from './thunk'

// API url
import { address } from './constants'
import { showSuccess } from './flashOverlay'

/* Login constants + actions */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_PENDING = 'LOGIN_PENDING'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export function loginSuccess (payload) {
  return {
    type: LOGIN_SUCCESS,
    payload
  }
}

export function loginPending () {
  return {
    type: LOGIN_PENDING,
    payload: true
  }
}

export function loginFail (error) {
  return {
    type: LOGIN_FAIL,
    payload: error
  }
}

function handleRedirectPaths (user) {
  const { _id } = user

  const redirectPaths = JSON.parse(localStorage.getItem('redirectPaths')) || {}
  const redirectPath = redirectPaths[_id] || redirectPaths['default'] 
  delete redirectPaths[_id]
  delete redirectPaths['default']
  localStorage.setItem('redirectPaths', JSON.stringify(redirectPaths))

  return redirectPath
}

// login action
export const loginUser = (data, history) => {
  return async (dispatch) => {
    const { payload, type } = await dispatch(fetchApi(`/authenticate`, 'POST', data))

    if (type === 'FAIL') {
      dispatch(loginFail())
    } else {
      const redirect = payload.redirect || handleRedirectPaths(payload.user) || '/'

      dispatch(loginSuccess(payload.user))
      history.push(redirect)
    }
  }
}

/* Google Login constants + actions */
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS'
export const LOGIN_GOOGLE_PENDING = 'LOGIN_GOOGLE_PENDING'
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL'

export const google = () => {
  return async (dispatch) => {
    dispatch(loginPending())

    try {
      const response = await fetch(`${address}/authenticate/google`)
      const json = await response.json()

      dispatch(loginSuccess({ json }))
      dispatch(push('/'))
    } catch (error) {
      dispatch(loginFail(error))
    }
  }
}

/* Microsoft Login constants + actions */
export const LOGIN_MS_SUCCESS = 'LOGIN_MS_SUCCESS'
export const LOGIN_MS_PENDING = 'LOGIN_MS_PENDING'
export const LOGIN_MS_FAIL = 'LOGIN_MS_FAIL'

/* Logout constants + actions */
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL'

export function logoutSuccess (payload) {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload
  }
}

export function logoutFail (error) {
  return {
    type: LOGOUT_USER_FAIL,
    payload: error
  }
}

export const logoutUser = () => {
  return async (dispatch) => {
    const { type } = await dispatch(fetchApi(`/authenticate/logout`))

    if (type === 'SUCCESS') {
      dispatch(logoutSuccess())
    }
  }
}

/* List users constants + actions */
export const USERS_LIST = 'USERS_LIST'

export function listUsersAction (payload) {
  return {
    type: USERS_LIST,
    payload
  }
}

export const getUsers = () => {
  return dispatchFetchApi(
    '/users',
    listUsersAction
  )
}

/* Add users constants + actions */
export const USER_ADD = 'USER_ADD'

export function addUserAction (payload) {
  return {
    type: USER_ADD,
    payload
  }
}

export const addUser = (data) => {
  return async (dispatch) => {
    const { payload, type } = await dispatch(fetchApi('/users', 'POST', data))

    if (type === 'SUCCESS') {
      dispatch(addUserAction(payload))
      dispatch(showSuccess({ message: 'Successfully created new user.' }))

      return `/users/${payload.user._id}/edit`
    }
  }
}

/* Edit users constants + actions */
export const USER_EDIT = 'USER_EDIT'
export const MY_ACCOUNT_EDIT = 'MY_ACCOUNT_EDIT'

export function editUserAction (payload) {
  return {
    type: USER_EDIT,
    payload
  }
}

export function myAccountEditAction (payload) {
  return {
    type: MY_ACCOUNT_EDIT,
    payload
  }
}

export const editUser = (data) => {
  return dispatchFetchApi(
    `/users/${data._id}`,
    editUserAction,
    'PUT',
    data
  )
}

export const editMyAccount = (data) => {
  return dispatchFetchApi(
    `/users/${data._id}`,
    myAccountEditAction,
    'PUT',
    data
  )
}

/* Delete users constants + actions */
export const USER_DELETE = 'USER_DELETE'

export function deleteUserAction (payload) {
  return {
    type: USER_DELETE,
    payload
  }
}

export const deleteUser = (id) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/users/${id}`, 'DELETE', { _id: id }))
    dispatch(deleteUserAction(payload))
    return '/users'
  }
}

/* Get user information constants + actions */
export const GET_USER_INFO = 'GET_USER_INFO'

export function getUserInfoAction (payload) {
  return {
    type: GET_USER_INFO,
    payload
  }
}

export const getUserInfo = () => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi('/users/me'))

    const { user, quizzes, ...rest } = payload

    if (quizzes) {
      dispatch({
        type: 'SET_INITIAL_QUIZ',
        payload: quizzes
      })
    }

    dispatch({
      type: 'SET_INITIAL_DATA',
      payload: rest
    })

    dispatch(getUserInfoAction({ user }))
  }
}

/* Get a user's information */
export const getAUsersData = (userId) => {
  return fetchApi(`/users/${userId}`)
}

/* Reset user's password constants + actions */
export const RESET_PASSWORD = 'RESET_PASSWORD'

export const resetPwAction = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload
  }
}

export const resetPassword = (data) => {
  return async (dispatch) => {
    const { type } = await dispatch(fetchApi(
      `/users/${data._id}/password`,
      'PUT',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(showSuccess({ message: 'Your password has been successfully changed.' }))
    }
  }
}

export const resetEmail = (data) => {
  return async (dispatch) => {
    const response = await dispatch(fetchApi(
      `/users/email/reset`,
      'POST',
      data
    ))

    return response 
  }
}

export const getUsersByRole = (type) => {
  return dispatchFetchApi(
    `/roles/${type}/users`,
    listUsersAction
  )
}

import {
  GET_CLASS,
  CLEAR_CLASS_DASHBOARD
} from '../../actions/class'

import {
  ADD_TEAM,
  EDIT_TEAM,
  GET_TEAMS,
  DELETE_TEAM,
  UPDATE_TEAMS_STATE
} from '../../actions/teams'

import {
  LESSON_UNASSIGN
} from '../../actions/lessons'

import {
  UNLINK_GC_COURSE
} from '../../actions/googleClassroom'

const teamsState = {
  isFetched: false,
  ids: [],
  entities: {},
  masterStudentsList: []
}

const initalState = {
  isFetched: false,
  course: null,
  lessons: null,
  students: null,
  teams: { ...teamsState },
  viewerRoles: {}
}

export const classroomReducer = (state = initalState, { payload, type }) => {
  switch (type) {
    default: {
      return state
    }
    case GET_CLASS: {
      return {
        ...state,
        isFetched: true,
        course: payload.class,
        students: payload.class?.users,
        lessons: payload.class?.lessons,
        viewerRoles: payload.viewerRoles,
      }
    }
    case GET_TEAMS: {
      return {
        ...state,
        teams: {
          ...state.teams,
          isFetched: true,
          ids: payload.teams.ids,
          entities: payload.teams.entities
        }
      }
    }
    case ADD_TEAM: {
      const incomingRoster = new Set([...payload.team.students.map(({ _id }) => _id)])
      const newMastersList = [...state.teams.masterStudentsList].filter(({ _id }) => !incomingRoster.has(_id))
      const teamRoster = [...state.teams.masterStudentsList].filter(({ _id }) => incomingRoster.has(_id))

      return {
        ...state,
        teams: {
          ...state.teams,
          ids: [...state.teams.ids, payload.team._id],
          entities: {
            ...state.teams.entities,
            [payload.team._id]: {
              ...payload.team,
              students: teamRoster
            }
          },
          masterStudentsList: newMastersList
        }
      }
    }
    case DELETE_TEAM: {
      const { _id: deletedTeamId, students } = payload.team
      const newEntities = { ...state.teams.entities }
      delete newEntities[deletedTeamId]

      return {
        ...state,
        teams: {
          ...state.teams,
          ids: [...state.teams.ids].filter(id => id !== deletedTeamId),
          entities: newEntities,
          masterStudentsList: [...state.teams.masterStudentsList, ...students]
        }
      }
    }
    case EDIT_TEAM: {
      const { updatedStudentsList, team } = payload

      return {
        ...state,
        teams: {
          ...state.teams,
          entities: {
            ...state.teams.entities,
            [team._id]: team
          },
          masterStudentsList: updatedStudentsList
        }
      }
    }
    case UPDATE_TEAMS_STATE: {
      return {
        ...state,
        teams: {
          ...state.teams,
          ...payload
        }
      }
    }
    case 'CLEAR_TEAMS': {
      return {
        ...state,
        teams: { ...teamsState }
      }
    }
    case LESSON_UNASSIGN: {
      const newLessons = new Set(payload.class.lessons)
      const lessons = state.lessons.filter(lesson => newLessons.has(lesson._id))

      return {
        ...state,
        course: {
          ...state.course,
          lessons
        },
        lessons
      }
    }
    case UNLINK_GC_COURSE: {
      const newState = { ...state }
      delete newState.course.google

      return newState
    }
    case CLEAR_CLASS_DASHBOARD: {
      return initalState
    }
  }
}

export default classroomReducer

// IMPORTS
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../actions/user'
import { toggle } from '../../../actions/sidebar'
import { useState } from 'react'
import { getHighestRole } from '../../../utils/utilityFuncs'

import {
  AppBar,
  Menu,
  Box,
  Toolbar,
  IconButton,
  MenuItem,
  Typography
} from '@mui/material'

import {
  Menu as MenuIcon,
  AccountCircle
} from '@mui/icons-material'
import Logo from '../../Logo'

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { firstName, lastName, roles } = useSelector(state => state.auth.user) || {}
  const history = useHistory()
  const dispatch = useDispatch()

  const onClick = () => {
    history.push('/')
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const goToMyAccount = () => {
    setAnchorEl(null)
    
    setTimeout(() => {
      history.push('/my-account')
    }, 100)

  }

  const onLogout = () => {
    dispatch(logoutUser())
    history.push('/login')
    setAnchorEl(null)
  }

  return (
    <AppBar position='relative' sx={{ backgroundColor: '#f4f5f7' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <IconButton onClick={onClick} disableRipple>
            <Logo dark height='40px' />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <IconButton onClick={() => dispatch(toggle(true))}> <MenuIcon /> </IconButton>
        </Box>
        <Box
          sx={{ display: 'flex' }}
        >
          <IconButton
            disableRipple
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup
            onClick={handleMenu}
            color='black'
          >
            <AccountCircle />
            <Box 
              sx={{ display: { xs: 'none', sm: 'grid' }, marginLeft: '5px' }}
            >
              <Typography sx={{ 'fontWeight': 'bold', fontSize: '14px', textAlign: 'start' }}> {firstName} {lastName} </Typography>
              <Typography sx={{ fontSize: '12px', color: 'grey', textAlign: 'start' }}> {getHighestRole(roles).type} </Typography>
            </Box>
          </IconButton>
        </Box>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={goToMyAccount}>My Account</MenuItem>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header

import { css, keyframes } from '@emotion/react'
import { Box } from 'grommet'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Loader = () => {
  return (
    <Box
      justify='center'
      align='center'
      css={css`
          background: rgba(255,255,255,0.5);
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 300;
        `
      }>
      <Box
        css={css`
            border: 2px solid #f3f3f3;
            border-top: 2px solid black;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            animation: ${spin} 2s linear infinite;
          `}
      />
    </Box>
  )
}

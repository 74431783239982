import { indicator } from 'ordinal'
import Moment from 'moment'
import LessonsList from '../components/Lessons/List'

// Returns an array of objects associated with keys
export const arrayify = (keys, object) => {
  return keys.map(key => object[key])
}

// Returns an array of students Id
// parameters - accepts two arguments - associatedIds from getClassesByTeacherId func
// and classes entities.
// Removes duplicate students
export const getStudentIdsByTeacher = (associatedIds, classes) => {
  const { entities } = classes
  let set = new Set()

  // Loop through array of class ids
  for (let i = 0; i < associatedIds.length; i++) {
    // Loop through array of users
    const { users } = entities[associatedIds[i]]
    for (let j = 0; j < users.length; j++) {
      set.add(users[j])
    }
  }
  return [...set]
}

// return ids of users specified by role
export const filterUsersByRole = (users, role) => {
  const { ids, entities } = users
  return ids.filter(id => entities[id].roles[0].type === role)
}

// Returns id of matching slug
export const getIdFromSlug = (ids, entities, slug) => {
  for (let i = 0; i < ids.length; i++) {
    if (entities[ids[i]].slug === slug) {
      return ids[i]
    }
  }
  return false
}

// returns true if grade is within range
function inGradeRange(a, b, c) {
  if (!a) return true

  function helper(x) {
    return x === 'K' ? 0 : parseInt(x)
  }

  return helper(a) >= helper(b) && helper(a) <= helper(c)
}

function checkTags(string, lesson) {
  const { author, description, subject, title, tags } = lesson

  const tagsOptions = tags || []

  // array of searchable items
  const searchables = [
    ...tagsOptions, description, subject, title, `${author.firstName} ${author.lastName}`
  ]

  for (const t of searchables) {
    const tag = t.toLowerCase()
    const value = string.toLowerCase()

    if (tag.includes(value)) {
      return true
    }
  }
  return false
}

export const getLessonsByProduct = ({ lessons, products, filters, notDraggable, isVertical, parent }) => {
  const { language, product, subject, grade, search, isFreeOnly, tag } = filters
  const { ids, entities } = lessons


  const lists = {}

  for (let p = 0; p < products.length; p++) {
    lists[products[p]._id] = []
  }

  for (let l = 0; l < ids.length; l++) {
    const lesson = entities[ids[l]]

    // subject filter
    if (subject && subject.value !== lesson.subject.trim()) {
      continue
    }

    // language filter
    if (language && (lesson.language !== language.value)) {
      continue
    }

    // product filter
    if (product !== '' && (product?.value !== lesson.product?.slug)) {
      continue
    }

    // grade filters
    if (!inGradeRange(grade.value, lesson.grade.start, lesson.grade.end)) {
      continue
    }

    // search tags filters
    if (search !== '' && !checkTags(search, lesson)) {
      continue
    }

    if (isFreeOnly && !lesson.isFree) {
      continue
    }

    if (lesson.product && lists.hasOwnProperty(lesson.product._id)) {
      lists[lesson.product._id].push(lesson)
    }
  }

  return products.map((product, i) => {
    const orderedLists = lists[product?._id].sort((a, b) => a.order - b.order)

    return (
      <LessonsList
        isVertical={isVertical}
        notDraggable={notDraggable}
        key={i}
        product={product}
        lessons={orderedLists}
      />
    )
  })
}

// returns string literal for grade
export function formatGrade({ start, end }) {
  function checkType(grade) {
    // if grade is a number
    if (!isNaN(parseInt(grade))) {
      return `${grade}${indicator(grade)}`
    } else {
      return grade
    }
  }

  if (start && end) {
    if (start === end) {
      return checkType(start)
    } else {
      return `${checkType(start)} - ${checkType(end)}`
    }
  } else if (!end) {
    return checkType(start)
  } else {
    return checkType(end)
  }
}

// targetRoles must be an array of roles ['role1', 'role2'] \ ['role1']
// userRoles is the roles object pull from auth reducer
export const hasRole = (targetRoles, usersRoles) => {
  // This function accepts an array of target roles and checks to see if the user's roles object contain at least 1 role.
  // returns true if target role is found, otherwise return false

  if (targetRoles.length < 1) {
    return false
  }

  for (let t = 0; t < targetRoles.length; t++) {
    for (let r = 0; r < usersRoles.length; r++) {
      // target role is found within users roles object
      if (targetRoles[t] === usersRoles[r].type) {
        return true
      }
    }
  }

  return false
}

// Retrieves the 'highest' role (least index) according to hardcoded roleHierarchy
export const getHighestRole = (roles) => {
  // Roles must be defined in .../src/components/GlobalLayout/Sidebar/constants.js
  const roleHierarchy = ['superadmin', 'admin', 'teacher', 'student', 'author']
  const sortedRoles = [...roles].sort(function (a, b) {
    if (roleHierarchy.indexOf(a.type) === -1) {
      return 1
    }

    if (roleHierarchy.indexOf(b.type) === -1) {
      return -1
    }

    return roleHierarchy.indexOf(a.type) < roleHierarchy.indexOf(b.type) ? -1 : 1
  })

  return sortedRoles[0]
}

// return time difference
export const getTimeDiff = (dateA, dateB) => {
  // get days diff between startdate and currentdate
  const timeA = Moment(dateA)
  const timeB = dateB ? Moment(dateB) : Moment.utc()

  return timeB.diff(timeA, 'days') + 1
}

// returns timestamp in pretty form
export const getTimeStamp = (time) => {
  const date = Moment(time).toString()
  const fixedDate = date.split(' ')
  fixedDate.pop()
  return fixedDate.join(' ')
}

// return timestamp in utc format
export const getUtcTimestamp = (time) => {
  return Moment.utc(time).valueOf()
}

export const formatTime = (time, format) => {
  return Moment(time).format(format)
}

// returns true if user has product activated
export function userHasProductActivated(product, activations) {
  const { ids, entities } = activations || { ids: [], entities: {} }
  for (let a = 0; a < ids.length; a++) {
    if (entities[ids[a]].product?._id === product._id) {
      return true
    }
  }
  return false
}

// returns true if user has product activated
export function checkActivationBySlug(productSlug, activations) {
  if (!activations) return false

  const { ids, entities } = activations
  for (let a = 0; a < ids.length; a++) {
    if (entities[ids[a]].product.slug === productSlug) {
      return true
    }
  }
  return false
}

// check teacheronly field
export function checkTeacherOnly(section) {
  if (section.hasOwnProperty('teacherOnly')) {
    return section.teacherOnly
  } else {
    return section.blacklistRoles.length > 0
  }
}

// returns latest event from payload
export function parseEvents(events) {
  let sensor, image, imageTime, receivedTime, sensorTime

  for (let i = 0; i < events.length; i++) {
    const { type, data, time, received } = events[i]

    if (sensor && image) {
      break
    }

    if (!sensor && type === 'sensor') {
      sensor = data
      receivedTime = received
      sensorTime = time
    }

    if (!image && type === 'image') {
      imageTime = time
      image = data
    }
  }

  const format = 'MM/DD/YYYY HH:mm:ss'

  return {
    sensor,
    image,
    imageTime: imageTime && formatTime(imageTime, format),
    sensorTime: sensorTime && formatTime(sensorTime, format),
    received: receivedTime && formatTime(receivedTime, format)
  }
}

// check date types
export function checkDateType(date) {
  if (typeof date === 'object') {
    return date.toISOString()
  }
  return date
}

// accept two parameters userProviders and targetProviders
// returns the providers if found, otherwise return false
export function hasGoogleProvider({ google }) {
  return google && google.permissions && google.permissions.google && google.permissions.google.classroom && google.permissions.google.classroom.enabled
}

export function getNumberOfDays(start, end) {
  const date1 = new Date(start)
  const date2 = new Date(end)

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime()

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay)

  return diffInDays
}

export const applyOperand = (operand, a, b) => {
  const supportedOps = 
  {
    '+': (a, b) => a + b,
    '-': (a, b) => a - b,
    '*': (a, b) => a * b,
    '/': (a, b) => a / b,
    '%': (a, b) => a % b,
    '^': (a, b) => Math.pow(a, b)
  }
  return supportedOps[operand](a, b)
}
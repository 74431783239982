// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `figure > img {
  max-width: 100%;
}

body, html, #page-container, .scrollable-page, .ps, .panel {
  height: 100% !important;
  width: 100% !important;
  display: inline-block;
}

@media print {
  body, html, #page-container, .scrollable-page, .ps, .panel {
    height: 100% !important;
    width: 100% !important;
    display: inline-block;
  }
}

.ck-editor__editable_inline {
  max-height: 30% !important;
  height: 30vh !important;
  width: 100% !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 30% !important;
}

.youtube-container, figure > div > div {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
  width: 60vw !important;
	height:0;
	overflow:hidden;
}

figure > div > div > iframe, .youtube-container iframe, .youtube-container object, .youtube-container embed {
	position:absolute;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
}

.dragabble{
  top:auto !important;
  left: auto !important;
  }

`, "",{"version":3,"sources":["webpack://./src/main.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE;IACE,uBAAuB;IACvB,sBAAsB;IACtB,qBAAqB;EACvB;AACF;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;CACC,iBAAiB;CACjB,qBAAqB;CACrB,gBAAgB;EACf,sBAAsB;CACvB,QAAQ;CACR,eAAe;AAChB;;AAEA;CACC,iBAAiB;CACjB,KAAK;CACL,MAAM;CACN,WAAW;CACX,YAAY;AACb;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB","sourcesContent":["figure > img {\n  max-width: 100%;\n}\n\nbody, html, #page-container, .scrollable-page, .ps, .panel {\n  height: 100% !important;\n  width: 100% !important;\n  display: inline-block;\n}\n\n@media print {\n  body, html, #page-container, .scrollable-page, .ps, .panel {\n    height: 100% !important;\n    width: 100% !important;\n    display: inline-block;\n  }\n}\n\n.ck-editor__editable_inline {\n  max-height: 30% !important;\n  height: 30vh !important;\n  width: 100% !important;\n}\n\n.ck.ck-editor__main > .ck-editor__editable {\n  min-height: 30% !important;\n}\n\n.youtube-container, figure > div > div {\n\tposition:relative;\n\tpadding-bottom:56.25%;\n\tpadding-top:30px;\n  width: 60vw !important;\n\theight:0;\n\toverflow:hidden;\n}\n\nfigure > div > div > iframe, .youtube-container iframe, .youtube-container object, .youtube-container embed {\n\tposition:absolute;\n\ttop:0;\n\tleft:0;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.dragabble{\n  top:auto !important;\n  left: auto !important;\n  }\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

/* global fetch */
import { address, generateOptions } from '../actions/constants'
/**
 *  Retrieve pre-sign payload from backend server
*/

export const getPresignedUrl = async (file, { type, id }) => {
  
  const options = generateOptions('POST', {
    filename: file.name,
    mimetype: file.type
  })

  try {
    const response = await fetch(`${address}/${type}/${id}/uploads/images`, options)
    const json = await response.json()

    return json
  } catch (error) {
    console.error(error)
  }
}

export const getUserPresignedUrl = async (type) => {
  const options = generateOptions('POST', { mimetype: type })
  try {
    const response = await fetch(`${address}/users/me/images`, options)
    const json = await response.json()

    return json
  } catch (error) {
    console.log(error)
  }
}

/**
 *  Uploads image to AWS s3 bucket
*/
export const uploadImageToAws = async (signedUrl, image) => {
  const options = {
    method: 'PUT',
    body: image
  }

  try {
    const response = await fetch(signedUrl, options)
    return response
  } catch (err) {
    console.error(err)
  }
}

/**
 *  Handles getting presign URL and upload of image
*/
export const getImagePath = async (image, payload) => {
  console.log(payload)
  try {
    const signedUrlResponse = await getPresignedUrl(image, payload)
    const { signedS3Url, imageUrl } = signedUrlResponse.payload

    const response = await uploadImageToAws(signedS3Url, image)

    if (response.status === 200) {
      return imageUrl
    } else {
      return false
    }
  } catch (err) {
    console.error(err)
  }
}

import {
  GET_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  ARCHIVE_ANNOUNCEMENT
} from '../../actions/announcements'

const initialState = {
  isFetched: false,
  entities: {},
  ids: []
}

export const announcementsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case 'CLEAR_ANNOUNCEMNETS': {
      return initialState
    }
    case GET_ANNOUNCEMENTS: {
      const { ids, entities } = payload.announcements
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case CREATE_ANNOUNCEMENT: {
      const { _id } = payload.announcement
      return {
        ...state,
        entities: { ...state.entities, [_id]: payload.announcement },
        ids: [_id, ...state.ids]
      }
    }
    case EDIT_ANNOUNCEMENT: {
      const { _id } = payload.announcement
      return {
        ...state,
        entities: { ...state.entities, [_id]: payload.announcement }
      }
    }
    case ARCHIVE_ANNOUNCEMENT: {
      const { _id } = payload.announcement
      return {
        ...state,
        entities: { ...state.entities, [_id]: payload.announcement }
      }
    }
    case DELETE_ANNOUNCEMENT: {
      const { _id } = payload.announcement

      const newIds = [...state.ids].filter(id => id !== _id)
      const newEntities = { ...state.entities }
      delete newEntities[_id]

      return {
        ...state,
        ids: newIds,
        entities: newEntities
      }
    }
    default: {
      return state
    }
  }
}

export default announcementsReducer

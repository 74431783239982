// IMPORTS
import { sendData, dispatchFetchApi, fetchApi } from './thunk'

// TYPES
export const PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS'
export const PRODUCTS_LIST_FAIL = 'PRODUCTS_LIST_FAIL'

export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS'
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL'

export const PRODUCT_EDIT_SUCCESS = 'PRODUCT_EDIT_SUCCESS'
export const PRODUCT_EDIT_FAIL = 'PRODUCT_EDIT_FAIL'

export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

// CREATORS
export const listProductsSuccess = (payload) => {
  return {
    type: PRODUCTS_LIST_SUCCESS,
    payload
  }
}

export const listProductsFail = (error) => {
  return {
    type: PRODUCTS_LIST_FAIL,
    payload: error
  }
}

export const addProductSuccess = (payload) => {
  return {
    type: PRODUCT_ADD_SUCCESS,
    payload
  }
}

export const addProductFail = (error) => {
  return {
    type: PRODUCT_ADD_FAIL,
    payload: error
  }
}

export const editProductSuccess = (payload) => {
  return {
    type: PRODUCT_EDIT_SUCCESS,
    payload
  }
}

export const editProductFail = (error) => {
  return {
    type: PRODUCT_EDIT_FAIL,
    payload: error
  }
}

export const deleteProductSuccess = (payload) => {
  return {
    type: PRODUCT_DELETE_SUCCESS,
    payload
  }
}

export const deleteProductFail = (error) => {
  return {
    type: PRODUCT_DELETE_FAIL,
    payload: error
  }
}

export const getProducts = (parentSlug) => {
  const url = !parentSlug ? `/products` : `/products?parent_slug=${parentSlug}`
  return dispatchFetchApi(
    url,
    listProductsSuccess
  )
}

export const addProduct = (data) => {
  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      '/products',
      'POST',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(addProductSuccess(payload))
      return '/products'
    }
  }
}

export const editProduct = (data) => {
  const { _id } = data
  
  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      `/products/${_id}`,
      'PUT',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(editProductSuccess(payload))
      return '/products'
    }
  }
}

export const deleteProduct = (payload) => {
  return sendData(
    '/products',
    'DELETE',
    payload,
    deleteProductSuccess,
    deleteProductFail
  )
}

export const activateProduct = (data) => {
  return async (dispatch) => {
    const json = await dispatch(fetchApi('/product-activations', 'POST', data))
    return json
  }
}

export const bulkActivations = (payloads) => {
  let postActivationResponses = []
  return async dispatch => {
    // array that holds post activation responses
    for (let i = 0; i < payloads.length; i++) {
      const { payload, type } = await dispatch(activateProduct(payloads[i]))

      if (type === 'SUCCESS') {
        postActivationResponses.push(payload.activation)
      }
    }
    return postActivationResponses
  }
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ListManager } from 'react-beautiful-dnd-grid'
import { reorder } from './util'
import { Modal, Box, Typography, TextField, Button } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DraggableContainer = ({ items, direction, maxItems, component, updateOnPropsChange }) => {
  const [listItems, setItems] = useState(items)
  const [modalProp, setModalProp] = useState(false)
  const [newOrder, setOrder] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    setItems(items)
  }, [items])

  function onDragEnd(sourceIndex, destinationIndex) {
    if (sourceIndex === destinationIndex) {
      return
    }

    const reorderedItems = reorder(
      listItems,
      sourceIndex,
      destinationIndex
    )

    setItems(reorderedItems)

    dispatch(listItems[sourceIndex].moveAction({
      draggedIndex: sourceIndex,
      droppedIndex: destinationIndex
    }))
  }

  const Component = component

  function onSubmit () {
    onDragEnd(modalProp.order, Number(newOrder))
    setOrder('')
    setModalProp('')
  }

  return (
    <Box>
      {modalProp && (
        <Modal open={Boolean(modalProp)} onClose={() => setModalProp(false)}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Moving Lesson: {modalProp.title}
            </Typography>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                'display': 'flex',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="standard-basic" label="Current Order" variant="standard" disabled value={modalProp.order} />
              <TextField id="standard-basic" autoFocus label="New Order" variant="standard" placeholder='number' value={newOrder} onChange={(e) => setOrder(e.target.value)} />
            </Box>
            <Box>
              <Button variant="contained" disabled={newOrder === ''} onClick={onSubmit}>Set Order</Button>
            </Box>
          </Box>
        </Modal>
      )}
      <ListManager
        items={listItems}
        direction={direction}
        maxItems={maxItems}
        render={item => <Component {...item.props} onDragEnd={onDragEnd} setModalProp={setModalProp} newOrder={newOrder} />}
        onDragEnd={onDragEnd}
      />
    </Box>
  )
}

export default DraggableContainer

// THUNK FUNCTIONS
import { dispatchFetchApi, fetchApi } from './thunk'
import { showSuccess } from '../actions/flashOverlay'
import { listSchoolsAction } from '../actions/school'

/* List district actions + constants */
export const DISTRICTS_LIST = 'DISTRICTS_LIST'

// ACTION CREATORS
export function listDistrictsAction (payload) {
  return {
    type: DISTRICTS_LIST,
    payload
  }
}

export const getDistricts = () => {
  return dispatchFetchApi(
    '/districts',
    listDistrictsAction
  )
}

export const getDistrict = (slug) => {
  return fetchApi(`/districts/${slug}`)
}

/* Add District actions + constants */
export const DISTRICT_ADD = 'DISTRICT_ADD'

export function addDistrictAction (payload) {
  return {
    type: DISTRICT_ADD,
    payload
  }
}

export const addDistrict = (data) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(
      '/districts',
      'POST',
      data
    ))

    dispatch(listDistrictsAction(payload))
    return payload
  }
}

/* Edit District actions + constants */
export const DISTRICT_EDIT = 'DISTRICT_EDIT'

export function editDistrictAction (payload) {
  return {
    type: DISTRICT_EDIT,
    payload
  }
}

export const editDistrict = (data) => {
  const { _id } = data

  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(
      `/districts/${_id}`,
      'PUT',
      data
    ))

    dispatch(editDistrictAction(payload))
    dispatch(showSuccess({ message: 'Successfully modified district.' }))
    return payload.district.slug
  }
}

/* Delete District constants + actions */
export const DISTRICT_DELETE = 'DISTRICT_DELETE'

export function deleteDistrictAction (payload) {
  return {
    type: DISTRICT_DELETE,
    payload
  }
}

export const deleteDistrict = (data) => {
  return dispatchFetchApi(
    '/districts',
    deleteDistrictAction,
    'DELETE',
    data
  )
}

/* Grab a district's school constants + actions */
export const listDistrictsSchools = (districtId) => {
  return dispatchFetchApi(
    `/districts/${districtId}/schools`,
    listSchoolsAction
  )
}

/* Grab all teachers from district */
export const getDistrictTeachers = (id) => {
  return fetchApi(`/districts/${id}/teachers`)
}

export const DISTRICT_SEARCH = 'DISTRICT_SEARCH'

export const districtSearchAction = (payload) => {
  return {
    type: DISTRICT_SEARCH,
    payload
  }
}

export const districtSearch = (query) => {
  return fetchApi(
    `/districts/search?name=${query}`
  )
}

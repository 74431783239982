const defaultFilters = {
  language: { name: 'English', value: 'en' },
  subject: '',
  grade: '',
  product: '',
  tags: new Set(),
  tag: null,
  approvedOnly: true,
  isFreeOnly: false,
  search: ''
}

const defaultFilterKeys = ['language', 'subject', 'grade', 'product', 'search']

// only return true if we find an active filter
function hasActiveFilterOn (filters) {
  if (!filters.approvedOnly || !filters.isFreeOnly) {
    return true
  }

  if (!filters.tags.size > 0) {
    return true
  }

  for (let i = 0; i < defaultFilterKeys.length; i++) {
    if (filters[defaultFilterKeys[i]] !== '') {
      return true
    }
  }
  return false
}

export const initialState = {
  isReady: false,
  isTagReady: false,
  hasActiveFilters: false,
  showApprovedButton: false,
  allowDragging: false,
  showFilters: true,
  filters: {
    showSingleProductOptions: false,
    ...defaultFilters
  }
}

export const lessonLibraryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPDATE_LESSON_LIBRARY_FILTERS': {
      const filters = {
        ...state.filters,
        ...payload
      }

      return {
        ...state,
        hasActiveFilters: hasActiveFilterOn(filters),
        filters
      }
    }
    case 'UPDATE_LESSON_LIBRARY_STATE': {
      return {
        ...state,
        ...payload
      }
    }
    case 'RESET_LESSON_LIBRARY': {
      return initialState
    }
    case 'LESSONS_DRAGGING_ENABLED': {
      return {
        ...state,
        filters: {
          ...defaultFilters,
          language: state.filters.language
        }
      }
    }
    case 'RESET_LESSON_LIBRARY_DEFAULT_FILTERS': {
      return {
        ...state,
        hasActiveFilters: false,
        filters: {
          ...state.filters,
          ...defaultFilters,
          product: // don't reset product if showSingleProductOptions is active
            state.filters.showSingleProductOptions
              ? state.filters.product
              : ''
        }
      }
    }
    default: {
      return state
    }
  }
}

export default lessonLibraryReducer

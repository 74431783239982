import {
  USERS_LIST,
  USER_EDIT,
  USER_DELETE
} from '../../actions/user'

import {
  GET_ALL_TEACHERS,
  GET_STUDENTS_BY_TEACHER
} from '../../actions/teachers'

const initialState = {
  isFetched: false,
  entities: {},
  ids: []
}

export default function users (state = initialState, { payload, type }) {
  switch (type) {
    default: {
      return state
    }
    case USERS_LIST: {
      const { entities, ids } = payload.users
      return {
        isFetched: true,
        entities,
        ids
      }
    }
    case USER_EDIT: {
      const { _id } = payload.user
      const newEntities = { ...state.entities }
      newEntities[_id] = payload.user

      return {
        ...state,
        entities: newEntities
      }
    }
    case USER_DELETE: {
      const { _id } = payload.removedUser
      const newIds = [...state.ids].filter(id => id !== _id)

      const newEntities = { ...state.entities }
      delete newEntities[_id]

      return {
        ...state,
        ids: newIds,
        entities: newEntities
      }
    }
    case GET_ALL_TEACHERS: {
      const { ids, entities } = payload
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case GET_STUDENTS_BY_TEACHER: {
      const { ids, entities } = payload
      return {
        isFetched: true,
        ids,
        entities
      }
    }
  }
}

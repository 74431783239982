// IMPORTS
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import createRootReducer from './generateRootReducer'
import { unstable_batchedUpdates } from 'react-dom';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ 
  history: createBrowserHistory(),
  batch: unstable_batchedUpdates,
  reduxTravelling: true
})

export const store = createStore(
  combineReducers({
    router: routerReducer,
    ...createRootReducer
  }),
  compose(
    applyMiddleware(
      routerMiddleware,
      promise,
      thunk
    ),
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
)

export const history = createReduxHistory(store)

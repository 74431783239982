// COMPONENTS
import { Box, Collapsible } from 'grommet'
import { useSelector } from 'react-redux'
import NavButton from './NavButton'
import { getHighestRole } from '../../../utils/utilityFuncs'

const NavParent = ({ open, nodes, onClick }) => {
  const { roles } = useSelector(state => state.auth.user)
  const isStudent = getHighestRole(roles).type === 'student'

  function renderChildNodes (nodes) {
    const items = []

    for (const key in nodes) {
      // do not include the keys array
      if (key !== 'keys') {
        const node = nodes[key]

        // block students from seeing cansat devices
        if (node.url === '/cansat/devices' && isStudent) {
          continue
        }
        items.push(
          <NavButton
            key={key}
            flex={{ shrink: 0 }}
            {...node}
          />
        )
      }
    }
    return items
  }

  return (
    <Collapsible direction='vertical' open={open}>
      <Box pad={{ horizontal: 'small' }} onClick={onClick} background='#2B303B'>
        {renderChildNodes(nodes)}
      </Box>
    </Collapsible>
  )
}

export default NavParent

import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Text, Box, Button } from 'grommet'
import { fetchApi } from '../../actions/thunk'
import HorizontalLine from '../../components/HorizontalRule'
import ConfirmationLoader from './ConfirmationLoader'
import { educationOptions } from './constants'

const ConfirmationScreen = () => {
  const {
    organizationType,
    educationLevels,
    schoolQuery,
    districtQuery,
    districtSelection,
    schoolSelection,
    districtState,
    schoolState
  } = useSelector(state => state.ui.onboarding)

  const [showLoader, setLoader] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const districtInfo = districtSelection || districtState
  const schoolInfo = schoolSelection || schoolState
  const showDistrict = organizationType.name !== 'other'

  const returnDistrictData = () => {
    if (schoolQuery === '' && districtQuery === '') {
      return schoolInfo
    } else {
      return districtInfo
    }
  }

  const onSubmitClicked = async () => {
    setLoader(true)

    const data = {
      district: returnDistrictData(),
      school: schoolSelection || {
        ...schoolState,
        countryName: schoolState.countryName.value
      },
      educationLevels: [...educationLevels]
    }
    const { type } = await dispatch(fetchApi('/users/me/onboarding', 'POST', data))

    if (type === 'SUCCESS') {
      setTimeout(() => {
        setLoader(false)
        history.go(0)
      }, 3000)
    }
  }

  const BoxStyle = ({ title, children, ...rest }) => {
    return (
      <Box basis='1/2' height='400' pad='xsmall' margin='small' {...rest}>
        <Text weight='bold'> {title} </Text>
        <HorizontalLine />
        {children}
      </Box>
    )
  }

  const TextStyle = ({ text }) => (
    <Text size='small' color='dark-3' weight='bold'> {text} </Text>
  )

  const districtProps = ['name', 'location', 'zipCode']
  const schoolProps = ['name', 'location']
  return (
    <Box>
      {showLoader && <ConfirmationLoader />}
      <Text weight='bold' size='large' color='brand'> Onboarding Confirmation </Text>
      <HorizontalLine />
      <Box background='light-1' round='5px'>
        <Box direction='row'>
          <BoxStyle title='Education Type:'>
            <TextStyle text={organizationType.text} />
          </BoxStyle>
          <BoxStyle title='Education Level(s):'>
            {educationOptions.map(({ name, text }, i) => {
              return educationLevels.has(name) && <TextStyle key={i} text={text} />
            })}
          </BoxStyle>
        </Box>
        <Box direction='row'>
          {showDistrict && (
            <BoxStyle title='District:'>
              {districtProps.map((key, i) => <TextStyle key={i} text={districtInfo[key]} />)}
            </BoxStyle>
          )}
          <BoxStyle title={organizationType.name === 'other' ? 'Organization:' : 'School:'}>
            {schoolProps.map((key, i) => <TextStyle key={i} text={schoolInfo[key]} />)}
            {schoolInfo.countryName && schoolInfo.countryName.label && (
              <TextStyle text={schoolInfo.countryName.label} />
            )}
          </BoxStyle>
        </Box>
      </Box>
      <Box margin={{ top: 'small' }}>
        <Button
          label='Submit Information'
          pad={{ horizontal: 'medium' }}
          alignSelf='end'
          color='brand'
          primary
          onClick={onSubmitClicked}
        />
      </Box>
    </Box>
  )
}

export default ConfirmationScreen

import { fetchData, fetchApi, dispatchFetchApi } from './thunk'

/* Add exolab device constant and actions */
export const ADD_DEVICES = 'ADD_DEVICES'

export const addDevicesAction = (payload) => {
  return {
    type: ADD_DEVICES,
    payload
  }
}

export const addDevices = (data) => {
  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      '/magnitude-streams/devices/bulk',
      'POST',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(addDevicesAction(payload))
      return true
    }
  }
}

/* Edit device constant and actions */
export const EDIT_DEVICE = 'EDIT_DEVICE'

export const editDeviceAction = (payload) => {
  return {
    type: EDIT_DEVICE,
    payload
  }
}

export const editDevice = (payload) => {
  return dispatchFetchApi(
    '/product-devices/',
    editDeviceAction
  )
}
/* List devices constant and actions */
export const GET_DEVICES = 'GET_DEVICES'

export const getDevicesAction = (payload) => {
  return {
    type: GET_DEVICES,
    payload
  }
}

export const getStreamDevices = () => {
  return fetchData(
    '/magnitude-streams/devices/exolab/available',
    getDevicesAction
  )
}

export const getDevices = () => {
  return fetchData(
    '/product-devices',
    getDevicesAction
  )
}

/* Register exolab device actions + constants */
export const REGISTER_EXOLAB_DEVICE = 'REGISTER_EXOLAB_DEVICE'

export const registerExoLabDeviceAction = (payload) => {
  return {
    type: REGISTER_EXOLAB_DEVICE,
    payload
  }
}

export const registerExoLabDevice = (data, setDevice, toggleForm) => {
  return async dispatch => {
    const { payload } = await dispatch(fetchApi(
      '/product-devices/register/exolab',
      'POST',
      data
    ))

    if (setDevice) {
      setDevice(payload.productDevice)
    } else {
      dispatch(registerExoLabDeviceAction(payload))
    }

    if (toggleForm) {
      toggleForm(false)
    }
    return payload
  }
}

/* Get product devices constants + actions */
export const GET_PRODUCT_DEVICES = 'GET_PRODUCT_DEVICES'

export const getProductDevicesAction = (payload) => {
  return {
    type: GET_PRODUCT_DEVICES,
    payload
  }
}

export const getProductDevices = (payload) => {
  return fetchData(
    '/product-devices',
    getProductDevicesAction
  )
}

/* Get users product constants + actions */
export const GET_USER_DEVICES = 'GET_USER_DEVICES'

export const getUserDevicesSuccess = (payload) => {
  return {
    type: GET_PRODUCT_DEVICES,
    payload
  }
}

export const getUserDevices = (productSlug) => {
  return dispatchFetchApi(
    `/product-devices/${productSlug}/me`,
    getDevicesAction
  )
}

export const getDeviceList = (productSlug) => {
  return fetchApi(`/product-devices/${productSlug}/me`)
}

/* Unassign a product constants + actions */
export const UNASSIGN_DEVICE = 'UNASSIGN_DEVICE'

export const unassignDeviceAction = (payload) => {
  return {
    type: UNASSIGN_DEVICE,
    payload

  }
}

export const unassignDeviceFromMagniApi = (deviceId) => {
  return async dispatch => {
    const { type, payload } = await dispatch(fetchApi(`/product-devices/${deviceId}`, 'DELETE', {}))

    if (type === 'SUCCESS') {
      return payload
    } else {
      return false
    }
  }
}

export const unassignDevice = (deviceId) => {
  return async (dispatch) => {
    const magniResponse = await dispatch(unassignDeviceFromMagniApi(deviceId))
    dispatch(unassignDeviceAction(magniResponse))
  }
}

export const addDevicesToWatchList = (devices) => {
  return async (dispatch) => {
    const responses = await Promise.all(devices.map(async (device) => {
      const { payload } = await dispatch(fetchApi('/device-watches', 'POST', { device }))
      return payload.device
    }))

    return responses
  }
}

export const deleteDeviceFromWatchList = (id) => {
  return fetchApi(
    `/device-watches/${id}`,
    'DELETE'
  )
}

export const deleteProductDevice = (id) => {
  return dispatchFetchApi(
    `/product-devices/${id}`,
    unassignDeviceAction,
    'DELETE'
  )
}

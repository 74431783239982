import {
  SCHOOLS_LIST,
  SCHOOL_ADD,
  SCHOOL_EDIT,
  SCHOOL_DELETE
} from '../../actions/school'

export default function schools (
  state = {
    isFetched: false,
    entities: {},
    ids: []
  },
  { payload, type }
) {
  switch (type) {
    default: {
      return state
    }
    case SCHOOLS_LIST: {
      const { entities, ids } = payload.schools
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case SCHOOL_ADD: {
      const { _id } = payload.school
      const newIds = [...state.ids, _id]
      const newEntities = { ...state.entities }
      newEntities[_id] = payload.school
      return {
        isFetched: true,
        ids: newIds,
        entities: newEntities
      }
    }
    case SCHOOL_EDIT: {
      const { _id } = payload.school
      const newEntities = { ...state.entities }
      newEntities[_id] = payload.school
      return {
        ...state,
        entities: newEntities
      }
    }
    case SCHOOL_DELETE: {
      const { _id } = payload.school

      const newIds = [...state.ids].filter(id => id !== _id)
      const newEntities = { ...state.entities }
      delete newEntities[_id]
      return {
        ...state,
        ids: newIds,
        entities: newEntities
      }
    }
  }
}

// IMPORTS
import React from 'react'

// COMPONENTS
import {
  Grommet,
  grommet as grommetTheme
} from 'grommet'

// UTILS
import { deepMerge } from 'grommet/utils'

/*
  This theme component configures all the global styles that its
  children inherit.
*/

// MAGNITUDE V1 THEME
/*
  This object contains CSS variables based on the original
  Magnitude platform as of 9/14/19
*/

export const magnitude = deepMerge(grommetTheme, {
  global: {
    focus: {
      border: {
        color: 'none'
      }
    },
    breakpoints: {
      xsmall: {
        value: 768
      },
      small: {
        value: 992,
        edgeSize: {
          none: '0px',
          small: '6px',
          medium: '12px',
          large: '24px'
        }
      },
      medium: {
        value: 1400,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px'
        }
      },
      large: {
        value: 2000,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px'
        }
      },
      xlarge: {
        value: 3000,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px'
        }
      }
    },
    font: {
      family: 'proxima-nova, proxima nova, helvetica neue, helvetica, arial, sans-serif;',
      size: '14px'
    },
    colors: {
      brand: '#6d5cae',
      focus: 'rgb(109, 92, 174)',
      'accent-1': '#3a8fc8',
      'accent-2': 'rgb(52, 60, 102)',
      'status-ok': '#6d5cae',
      'status-critical': '#eb1c23',
      'neutral-3': '#525252'
    }
  },
  anchor: {
    color: '#007bff',
    fontWeight: '400'
  },
  button: {
    border: {
      radius: '3px',
      width: '1px'
    },
    padding: {
      vertical: '5px',
      horizontal: '10px'
    },
    extend: {
      fontWeight: '350',
      fontSize: '14px'
    }
  },
  textInput: {
    extend: {
      padding: '3px'
    }
  },
  table: {
    header: {
      align: 'left'
    },
    body: {
      align: 'left',
      pad: {
        vertical: 'small'
      }
    },
    extend: {
      'tbody > tr:hover': {
        backgroundColor: '#DAEFFD'
      },
      'td > button': {
        zIndex: '1'
      },
      thead: {
        fontWeight: 'bold',
        textAlign: 'left'
      },
      tr: {
        borderBottom: '1px solid #d1d1d1'
      },
      table: {
        fontSize: '2px'
      }
    }
  },
  formField: {
    border: {
      position: 'none'
    },
    label: {
      color: 'dark-3',
      size: 'small',
      margin: 'xxsmall',
      weight: 400
    },
    disabled: {
      background: {
        color: 'status-disabled',
        opacity: true
      }
    },
    content: {
      pad: 'xxsmall'
    },
    error: {
      margin: 'xxsmall',
      size: 'xsmall'
    },
    margin: 'none'
  }
})

const MagnitudeTheme = ({ children }) => (
  <Grommet
    cssVars
    theme={magnitude}
    background='white'
  >
    {children}
  </Grommet>
)

export default MagnitudeTheme

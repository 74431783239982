import {
  ADD_DEVICES,
  GET_DEVICES,
  UNASSIGN_DEVICE,
  REGISTER_EXOLAB_DEVICE
} from '../../actions/devices'

const initialState = {
  isFetched: false,
  ids: [],
  entities: {}
}

export const devices = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_DEVICES: {
      const { ids, entities } = payload.devices
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case ADD_DEVICES: {
      const { ids, entities } = payload.devices

      return {
        ...state,
        ids: [...state.ids, ...ids],
        entities: { ...state.entities, ...entities }
      }
    }
    case UNASSIGN_DEVICE: {
      const { _id } = payload.device
      const ids = [...state.ids].filter(id => id !== _id)

      const entities = { ...state.entities }
      delete entities[_id]

      return {
        ...state,
        ids,
        entities
      }
    }
    case REGISTER_EXOLAB_DEVICE: {
      const { _id } = payload.productDevice

      return {
        ...state,
        ids: [...state.ids, _id],
        entities: {
          ...state.entities,
          [_id]: payload.productDevice
        }
      }
    }
    default: {
      return state
    }
  }
}

export default devices

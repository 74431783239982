// ACTION TYPES
import {
  CANSAT_DATA_VIEW_SUCCESS,
  CANSAT_DATA_VIEW_FAIL
} from '../../actions/cansat'

// MAIN REDUCER
export default function cansat (
  state = {
    device: null,
    flight: null,
    data: null
  },
  action
) {
  switch (action.type) {
    default: {
      return state
    }
    case CANSAT_DATA_VIEW_SUCCESS: {
      const newState = {
        ...state
      }

      const { device, flight, data } = action.payload

      newState.device = device
      newState.flight = flight
      newState.data = data

      return newState
    }
  }
}

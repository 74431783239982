import React from 'react'
import { BoxWithTitle } from '../../components/Boxes'
import { Box, Text, Card, CardHeader, CardBody, CardFooter, Heading, Grid, Button } from 'grommet'
import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)
const isValidBrowser = browser.satisfies({
  windows: {
    'Microsoft Edge': '>=79' // Below version 79 corresponds to old non-chromium Edge
  },
  'Chrome OS': {
    chrome: '>=12'
  }
})

const Extensions = () => {
  const OS = browser.getOSName()
  const browserName = browser.getBrowserName()
  console.log(`Detected ${browserName} running on ${OS}`)

  return isValidBrowser
    ? (
      <BoxWithTitle title='INSTALL EXTENSION' align='center' width={{ max: '60%' }} margin={{ top: '50px', horizontal: 'auto' }}>
        <Grid
          fill='horizontal'
          justify='center'
          justifyContent='center'
          rows={['300px']}
          columns={['medium', 'medium']}
          gap='xsmall'
          areas={[
            { name: 'left', start: [0, 0], end: [0, 0] },
            { name: 'right', start: [1, 0], end: [1, 0] }
          ]}
        >
          <Box gridArea='left' align='center' justify='center'>
            <Text size='large'>Please install this extension.</Text>
          </Box>
          <Card gridArea='right' align='center' height={{ max: '90%' }} width={{ max: '60%' }} background='brand'>
            <CardHeader pad='medium'>
              <Heading level='3' textAlign='center' margin='none'>Magnitude Streamer</Heading>
            </CardHeader>
            <CardBody pad='medium'>
              {browserName == 'Chrome' ? (
                <Button primary
                  size='medium'
                  label='Install'
                  href='https://chrome.google.com/webstore/detail/magnitude-streamer/ldemlkiokjhlgfafepcjnlnkbmbllohc' target='_blank'
                />)
                : (<Button primary
                  size='medium'
                  label='Install'
                  href='https://microsoftedge.microsoft.com/addons/detail/magnitude-streamer-edge/jookhbjpbkaomfokhefmnonhibleeffn' target='_blank'
                />)}
            </CardBody>
            <CardFooter pad={{ horizontal: 'xsmall' }} > {browserName + ' Extension'} </CardFooter>
          </Card>
        </Grid>

      </BoxWithTitle>
    )
    : (
      <BoxWithTitle title='UNSUPPORTED PLATFORM' margin={{ vertical: '50px', horizontal: '200px' }}>
        <Text weight={200} size='25px'> This feature does not currently support this platform or browser. </Text>
        <Text weight='normal' size='18px' margin={{ 'top': '25px' }}>Supported Platforms:</Text>
        <ul>
          <li>Chrome OS (Chrome browser) </li>
          <li>Windows (Microsoft Edge browser)</li>
        </ul>
      </BoxWithTitle>
    )
}

export default Extensions

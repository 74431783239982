import { Layer, Box, Text } from 'grommet'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clearFlashOverlay } from '../../actions/flashOverlay'
import { Checkmark, Close } from 'grommet-icons'
import { css } from '@emotion/react'

// FlashOverlay component is added to our application on the global scope.
// Use actions to display flash messages anywhere in the application.
const FlashOverlay = () => {
  const [show, setShow] = useState(false)
  const { showOverlay, type, message } = useSelector(state => state.ui.flashOverlay) || {}
  const dispatch = useDispatch()

  useEffect(() => {
    if (showOverlay) {
      setShow(true)
    }
  }, [showOverlay])

  function onClose () {
    dispatch(clearFlashOverlay())
    setShow(false)
  }

  const successColor = '#2c9667' // dark-green
  const errorColor = '#f46d66' // light-red
  const warningColor = '#eed202'

  function returnMsgType () {
    if (type === 'success') {
      return {
        color: successColor,
        icon: Checkmark,
        title: 'SUCCESS'
      }
    } else if (type === 'error') {
      return {
        color: errorColor,
        icon: Close,
        title: 'ERROR'
      }
    } else {
      return {
        color: warningColor,
        icon: null,
        title: 'NOTICE'
      }
    }
  }

  const Overlay = () => {
    const { color, icon, title } = returnMsgType()
    const Icon = icon
    return (
      <Box direction='row' border={{ color, size: 'small' }} elevation='small' round='5px' pad='large' gap='medium'>
        <Box alignSelf='center' pad='medium' basis='2/6'>
          <Text alignSelf='center' color={color} size='large' weight='bold'> {title}</Text>
        </Box>
        <Box alignSelf='center' basis='1/2'>
          <Text size='small' textAlign='center'> {message} </Text>
        </Box>
        {icon && (
          <Box alignSelf='center' basis='1/6'>
            <Box background={color} round='50%' height='35px' width='40px' alignContent='center'>
              <Icon color='white' css={css`margin:auto;`} />
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box>
      {show && (
        <Layer
          style={{ zIndex: 1 }}
          onEsc={onClose}
          onClickOutside={onClose}
          width='800px'
        >
          <Overlay />
        </Layer>
      )}
    </Box>
  )
}

export default FlashOverlay

export const SET_ONBOARDING_STATE = 'SET_ONBOARDING_STATE'

export const setOnboardingState = (payload) => {
  return {
    type: SET_ONBOARDING_STATE,
    payload
  }
}

export const SET_DISTRICT_STATE = 'SET_DISTRICT_STATE'

export const setDistrictState = (payload) => {
  return {
    type: SET_DISTRICT_STATE,
    payload
  }
}

export const SET_SCHOOL_STATE = 'SET_SCHOOL_STATE'

export const setSchoolState = (payload) => {
  return {
    type: SET_SCHOOL_STATE,
    payload
  }
}

export const CLEAR_ONBOARDING_STATE = 'CLEAR_ONBOARDING_STATE'

export const clearOnboardingState = (payload) => {
  return {
    type: CLEAR_ONBOARDING_STATE,
    payload
  }
}

export const CLEAR_DISTRICT_STATE = 'CLEAR_DISTRICT_STATE'

export const clearDistrictState = () => {
  return {
    type: CLEAR_DISTRICT_STATE
  }
}

export const CLEAR_SCHOOL_STATE = 'CLEAR_SCHOOL_STATE'

export const clearSchoolState = () => {
  return {
    type: CLEAR_SCHOOL_STATE
  }
}

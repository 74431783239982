// map quiz instances
export function mapQuizInstances (ids, entities) {
  const questionsMap = {}
  const questionsKeys = []
  const answerIds = []
  const answersEntities = {}

  for (let i = 0; i < ids.length; i++) {
    const { quiz, answered, user, section } = entities[ids[i]]

    const { title } = quiz

    if (!questionsMap.hasOwnProperty(title)) {
      questionsMap[title] = []
      questionsKeys.push(title)
    }

    for (let j = 0; j < quiz.questions.length; j++) {
      const answer = answered.filter(answer => answer.questionId === quiz.questions[j]._id)

      if (answer.length > 0) {
        const id = answer[0]._id
        questionsMap[title].push(id)
        answerIds.push(id)
        answersEntities[id] = {
          ...answer[0],
          user,
          question: quiz.questions[j],
          quizInstanceId: ids[i],
          section
        }
      }
    }
  }

  return {
    questionsMap,
    questionsKeys,
    ids: answerIds,
    entities: answersEntities
  }
}

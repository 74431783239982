import {
  SECTIONS_LIST_SUCCESS,
  SECTIONS_LIST_FAIL
} from '../../actions/sections'

export default function sections (
  state = {
    isFetched: false,
    entities: {},
    ids: []
  },
  action
) {
  switch (action.type) {
    default: {
      return state
    }
    case SECTIONS_LIST_SUCCESS: {
      const newState = { ...state }
      const { entities, ids } = action.payload.lessonSections

      newState.isFetched = true
      newState.entities = entities
      newState.ids = ids

      return newState
    }
    case SECTIONS_LIST_FAIL: {
      const newState = { ...state }
      newState.isFetched = false
      return newState
    }
  }
}

import {
  GET_SECTION_NOTES_SUCCESS,
  ADD_SECTION_NOTE_SUCCESS,
  UPDATE_SECTION_UI,
  ADD_QUESTION_STATE,
  CLEAR_QUESTION_STATE,
  UPDATE_QUESTION_STATE,
  UPDATE_QUIZ_INSTANCE,
  CLEAR_QUIZ_INSTANCE
} from '../../actions/sections'

const initialState = {
  notes: null,
  quizInstance: null,
  currentSectionIndex: null,
  lastCompletedSectionIndex: null,
  quizEntries: [
    /*
      {
        quizInstanceId: String,
        questionId: String,
        type: 'essay',
        answer: 'hello'
      },
      {
        quizInstance: String,
        questionId: String,
        type: 'multiple',
        answer: _id
      }
    */
  ]
}

export const sectionReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    default: {
      return state
    }
    case CLEAR_QUIZ_INSTANCE: {
      return {
        ...state,
        quizInstance: null,
        quizEntries: []
      }
    }
    case GET_SECTION_NOTES_SUCCESS: {
      const { ids, entities } = payload.notes
      return {
        ...state,
        notes: {
          isFetched: true,
          ids,
          entities
        }
      }
    }
    case ADD_SECTION_NOTE_SUCCESS: {
      const { _id } = payload.note
      const newIds = [...state.notes.ids, _id]
      const newEntities = { ...state.notes.entities }
      newEntities[_id] = payload.note

      return {
        ...state,
        notes: {
          isFetched: true,
          ids: newIds,
          entities: newEntities
        }
      }
    }
    case UPDATE_SECTION_UI: {
      return {
        ...state,
        ...payload
      }
    }
    case ADD_QUESTION_STATE: {
      return {
        ...state,
        quizEntries: [
          ...state.quizEntries,
          payload
        ]
      }
    }
    case CLEAR_QUESTION_STATE: {
      return {
        ...state,
        quizEntries: []
      }
    }
    case UPDATE_QUESTION_STATE: {
      const { index, newUpdate } = payload
      const { quizEntries } = state
      const question = quizEntries[index]

      const newQuizEntries = [
        ...quizEntries.slice(0, index),
        {
          ...question,
          ...newUpdate
        },
        ...quizEntries.slice(index + 1)
      ]

      return {
        ...state,
        quizEntries: newQuizEntries
      }
    }
    case UPDATE_QUIZ_INSTANCE: {
      const quiz = payload
      const answers = {}

      for (let i = 0; i < quiz.answered.length; i++) {
        const answer = quiz.answered[i]
        answers[answer.questionId] = answer
      }

      return {
        ...state,
        quizInstance: {
          ...quiz,
          answers
        }
      }
    }
  }
}

export default sectionReducer

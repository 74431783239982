// IMPORTS
import { useHistory, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'

// COMPONENTS
import { Box, Button, Text, Image } from 'grommet'
import DragIcon from './DragIcon'

// UTILS
import { css } from '@emotion/react'
import { FlexBox } from '../../Boxes'
import { formatGrade, hasRole } from '../../../utils/utilityFuncs'

const defaultImg = 'https://lesson-images-legacy.s3-us-west-1.amazonaws.com/img/img-thumb.png'

const Card = ({
  grade,
  imageThumbnail,
  subject,
  author,
  title,
  order,
  description,
  slug,
  classSlug,
  notDraggable,
  isFree,
  isArchived
}) => {
  const { firstName, lastName } = author
  const { roles } = useSelector(state => state.auth.user)
  const canEdit = hasRole(['author', 'superadmin'], roles) && !notDraggable
  const isStudent = hasRole(['student'], roles)

  const returnURL = () => {
    const lessonsPath = `/lessons/${slug}`
    return isStudent ? `/classes/${classSlug}${lessonsPath}` : lessonsPath
  }

  const onToggleClicked = (e) => {
    // stop event bubbling from outer onClick
    e.cancelBubble = true
    e.stopPropagation()
    toggleTruncate(!truncateLines)
  }

  const [truncateLines, toggleTruncate] = useState(true)

  return (
    <Box
      elevation='medium'
      width='300px'
      margin='10px'
      border
    >
      <Link to={returnURL()}>
        <FlexBox>
          <Box pad='xsmall' background='brand' basis='1/2'>
            <Text truncate>{subject}</Text>
          </Box>
          <Box direction='row' basis='1/2'>
            <Box background='dark-2' basis={!canEdit ? 'full' : '3/4'} align='center' pad='xsmall'>
              {formatGrade(grade)}
            </Box>
            {(canEdit && !notDraggable) && <DragIcon />}
          </Box>
        </FlexBox>
        {notDraggable && (
          <Box background='light-4' height='15vw'>
            <Image fit='cover' src={imageThumbnail || defaultImg} />
          </Box>
        )}
        <Box pad='small' justify='center' background='light-1'>
          <Text weight='bold' size='small'>{title}</Text>
        </Box>
      </Link>
      {!notDraggable && (
        <Box pad='small' justify='center' background='light-1'>
          <Text weight='bold' size='small'>Order: {order}</Text>
        </Box>
      )}
      {notDraggable && (
        <Box pad='small' background='light-1' css={css`min-height: 80px;`}>
          <Box>
            <Text size='small' truncate={truncateLines}> {description} </Text>
          </Box>
          {description.length > 60 && (
            <Button
              alignSelf='start'
              color='brand'
              plain
              margin={{ top: 'xsmall' }}
              onClick={onToggleClicked}
              label={truncateLines ? 'More' : 'Less'}
            />
          )}
        </Box>
      )}
      <Link to={returnURL()}>
        <Box pad='small' background='light-1' justify='between' direction='row'>
          <Text weight='bold' size='small' truncate>Author : {`${firstName} ${lastName} `}</Text>
          {isArchived && (
            <Box alignSelf='center'>
              <Text weight='bold' size='small' color='red'> Archived </Text>
            </Box>
          )}
          {isFree && (
            <Box alignSelf='end'>
              <Text weight='bold' size='small' color='brand'> Free </Text>
            </Box>
          )}
        </Box>
      </Link>
    </Box>
  )
}

export default Card

import { dispatchFetchApi, fetchApi } from './thunk'

/* Get teams in class constants + actions */
export const GET_TEAMS = 'GET_TEAMS'

export function getTeamsAction (payload) {
  return {
    type: GET_TEAMS,
    payload
  }
}

export const getTeams = (classId) => {
  return dispatchFetchApi(
    `/classes/${classId}/teams`,
    getTeamsAction
  )
}

export const UPDATE_TEAMS_STATE = 'UPDATE_TEAMS_STATE'

export function updateTeamsState (payload) {
  return {
    type: UPDATE_TEAMS_STATE,
    payload
  }
}

/* Add teams for a classroom */
export const ADD_TEAM = 'ADD_TEAM'

export function addTeamAction (payload) {
  return {
    type: ADD_TEAM,
    payload
  }
}

export const addTeam = (data) => {
  return dispatchFetchApi(
    '/teams',
    addTeamAction,
    'POST',
    data
  )
}

export const EDIT_TEAM = 'EDIT_TEAM'

// pass the updated master list through here
export function editTeamAction (payload) {
  return {
    type: EDIT_TEAM,
    payload
  }
}

export const editTeam = (id, data, updatedStudentsList) => {
  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      `/teams/${id}`,
      'PUT',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(editTeamAction({
        team: payload.team,
        updatedStudentsList
      }))
    }
  }
}

export const DELETE_TEAM = 'DELETE_TEAM'

export function deleteTeamAction (payload) {
  return {
    type: DELETE_TEAM,
    payload
  }
}

export const deleteTeam = (id) => {
  return dispatchFetchApi(
    `/teams/${id}`,
    deleteTeamAction,
    'DELETE',
    {}
  )
}

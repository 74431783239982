import { GET_EXPERIMENTS } from '../../actions/experiments'

const initialState = {
  isFetched: false,
  ids: [],
  entities: {}
}

export const experimentsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_EXPERIMENTS: {
      const { ids, entities } = payload.experiments
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case 'CLEAR_EXPERIMENTS': {
      return initialState
    }
    default:
      return state
  }
}

export default experimentsReducer

import { useSelector } from 'react-redux'
import { Box } from 'grommet'
import OrganizationTypes from './OrganizationTypes'
import ConfirmationScreen from './ConfirmationScreen'
import Steps from '../Steps'
import DistrictSearchBox from './Search/District'
import SchoolSearchBox from './Search/School'

const AdditionalInformation = () => {
  const { name } = useSelector(state => state.ui.onboarding.organizationType) || {}

  const views = [
    {
      name: 'Education',
      component: OrganizationTypes
    },
    {
      name: 'District',
      component: DistrictSearchBox,
      hide: name === 'other'
    },
    {
      name: name === 'other' ? 'Organization' : 'School',
      component: SchoolSearchBox
    },
    {
      name: 'Confirmation',
      component: ConfirmationScreen
    }
  ]

  return (
    <Box margin='medium' gap='xsmall' animation='fadeIn'>
      <Steps views={views} />
    </Box>
  )
}

export default AdditionalInformation

import { Text, Box } from 'grommet'
import HorizontalRule from '../../HorizontalRule'

const ExampleSearch = ({ type }) => {

  const desiredResult = type === 'District'
    ? `"Milky Way Galaxy Unified School District", "Milky Way Galaxy USD"`
    : `"Starship Academy", "Moonrock Elementary", "Moonrock Elementary School"`

  const badSearches = type === 'District'
    ? `"Milky Way", "Galaxy"`
    : `"Starship", "Starship Acad", "Moonrock", "Moonrock Elem"`

  return (
    <Box basis='1/2' border pad='small'>
      <Text size='small' weight='bold'> Example Search </Text>
      <HorizontalRule />
      <Text size='xsmall' weight='bold'> {`You must enter the exact name to get a matching result.`}</Text>
      <Text size='xsmall'> Desired Result: {desiredResult} </Text>
      <Text size='xsmall'> Bad Searches: {badSearches} </Text>
    </Box>
  )
}

export default ExampleSearch

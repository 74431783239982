import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Text, Button } from 'grommet'
import AdditionalInformation from './AdditionalInformation'
import { hideNavbar } from '../../actions/sidebar'
import HorizonalLine from '../HorizontalRule'

const DistrictSchoolFlow = () => {
  const { user } = useSelector(state => state.auth)
  const [isReady, setReady] = useState(false)
  const [showOnboarding, setOnboarding] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(hideNavbar())
    setReady(true)
  }, [])

  const { firstName } = user

  if (isReady) {
    return (
      <Box margin={{ vertical: 'large', horizontal: 'xlarge' }} background='white' pad='medium' round='5px' border={{ size: '1px', color: 'light-3', style: 'solid' }}>
        {!showOnboarding
          ? (
            <>
              <Box background='light-2' pad={{ vertical: 'medium' }}>
                <Text textAlign='center' weight='bold'> Thanks for signing up, {firstName}. </Text>
                <Text textAlign='center' color='dark-1' size='xsmall'> We just need a few more details, then you're ready to go! </Text>
              </Box>
              <HorizonalLine />
              <Box>
                <Button label='Begin onboarding process' onClick={() => setOnboarding(true)} primary alignSelf='center' />
              </Box>
            </>
          ) : (
            <AdditionalInformation />
          )
        }
      </Box>
    )
  } else {
    return null
  }
}

export default DistrictSchoolFlow

import { useSelector } from 'react-redux'
import { Box, Heading, Text } from 'grommet'

const NotFoundPage = () => {
  const { errorStatus } = useSelector(state => state.auth)
  return (
    <Box margin='3%'>
      <Heading level={1}> Page Not Found </Heading>
      <Text size='small'> The resource you requested doesn't exist.</Text>
      {
        (errorStatus && errorStatus.code !== 404) && (
          <Box pad='small' border background='beige'>
            <Text size='small'> Error: "{errorStatus.json.payload}" </Text>
          </Box>
        )
      }
    </Box>
  )
}

export default NotFoundPage

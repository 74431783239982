import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StepCircle from './StepCircle'
import { Box, Button, Text } from 'grommet'
import { setOnboardingState } from '../../actions/onboarding'

const Steps = ({ views }) => {
  const options = views.filter(view => !view.hide)
  const [currentStep, setStep] = useState(0)
  const { component: Component, name } = options[currentStep]
  const { nextBtnDisabled, organizationType } = useSelector(state => state.ui.onboarding)
  const dispatch = useDispatch()

  const onNextClicked = () => {
    setStep(currentStep + 1)
    dispatch(setOnboardingState({
      nextBtnDisabled: true
    }))
  }

  return (
    <Box gap='small'>
      <Box direction='row' justify='around' gap='large' align='center' alignSelf='center'>
        {organizationType && Array.from({ length: options.length }, (x, i) => i).map((number, index) =>
          <Box align='center' animation='fadeIn' key={index}>
            <StepCircle number={number} currentStep={currentStep} />
            <Text size='xsmall' weight='bold' color={name === options[number].name ? 'brand' : 'black'}> {options[number].name }</Text>
          </Box>
        )}
      </Box>
      <Component setStep={setStep} />
      <Box>
        {currentStep > 0 && (
          <Button label='Back' onClick={() => setStep(currentStep - 1)} primary alignSelf='start' />
        )}
        {!nextBtnDisabled && (
          <Button label='Next' onClick={onNextClicked} primary alignSelf='end' disabled={nextBtnDisabled} />
        )}
      </Box>
    </Box>

  )
}

export default Steps

// FETCH THUNK
import { dispatchFetchApi, fetchApi } from './thunk'

// TYPES
export const ROLES_LIST = 'ROLES_LIST'
export const ROLE_ADD = 'ROLE_ADD'
export const ROLE_EDIT = 'ROLE_EDIT'
export const ROLE_DELETE = 'ROLE_DELETE'

// CREATORS
export function listRolesAction (payload) {
  return {
    type: ROLES_LIST,
    payload
  }
}

export function addRoleAction (payload) {
  return {
    type: ROLE_ADD,
    payload
  }
}

export function editRoleAction (payload) {
  return {
    type: ROLE_EDIT,
    payload
  }
}

export function deleteRoleAction (payload) {
  return {
    type: ROLE_DELETE,
    payload
  }
}

export const getRoles = () => {
  return dispatchFetchApi(
    '/roles',
    listRolesAction
  )
}

export const addRole = (type) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi('/roles', 'POST', { type }))
    dispatch(addRoleAction(payload))
    return payload
  }
}

export const editRole = (type, roleId) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/roles/${roleId}`, 'PUT', { type }))
    dispatch(editRoleAction(payload))
    return payload
  }
}

export const deleteRole = (data) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi('/roles', 'DELETE', data))
    dispatch(deleteRoleAction(payload))
    return payload
  }
}

import {
  GET_EXPERIMENT,
  GET_EXPERIMENT_DATA,
  SET_TIME_RANGE
} from '../../actions/experiments'

import { getUtcTimestamp } from '../../utils/utilityFuncs'

const initialState = {
  isFetched: false,
  timeRange: null
}

export const experimentReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    default: {
      return state
    }
    case 'CLEAR_EXPERIMENT': {
      return initialState
    }
    case GET_EXPERIMENT: {
      return {
        isFetched: true,
        ...payload.experiment
      }
    }
    case SET_TIME_RANGE: {
      //this reducer builds an array of timestamps of equally spaced timestamps using the firstDataEventTime and lastDataEventTime
      const { start, end } = payload
      const utcStart = getUtcTimestamp(start)
      const utcEnd = getUtcTimestamp(end || new Date())

      const msDiff = utcEnd - utcStart
      const steps = 500
      const interval = msDiff / steps
      const timestamps = []

      let current = utcStart

      for (let i = 0; i < steps - 1; i++) {
        timestamps.push(new Date(current).toUTCString())
        current += interval
      }

      timestamps.push(end)
      return {
        ...state,
        timeRange: timestamps
      }
    }
    case GET_EXPERIMENT_DATA: {
      if (payload === 'No data received' || payload.data < 1) {
        return {
          ...state,
          isFetched: true,
          experiment: payload.experiment,
          data: null
        }
      }
      // get data information
      const { device } = payload.data[0]
      let sensor, image, timeStamp

      for (let i = payload.data.length - 1; i >= 0; i--) {
        const { type, data, time } = payload.data[i]

        if (sensor && image) {
          break
        }

        if (type === 'sensor') {
          timeStamp = time
          sensor = data
        } else {
          timeStamp = time
          image = data
        }
      }

      return {
        ...state,
        device,
        data: {
          sensor,
          image,
          time: timeStamp
        }
      }
    }
  }
}

export default experimentReducer

import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import NavList from './NavList'
import { toggle } from '../../../actions/sidebar'
import logoWhite from '../../../../assets/logo_white.png'

const Sidebar = () => {
  const { active, show } = useSelector(state => state.ui.sidebar)
  const dispatch = useDispatch()

  function onToggle() {
    dispatch(toggle(!active))
  }

  function onDefocus(e) {
    e.stopPropagation()
    dispatch(toggle(false))
  }

  if (show) {
    return (
      <Box
        sx={{
          backgroundColor: '#262B35',
          width: '265px',
          left: { xs: active ? '0px' : '-265px', sm: active ? '0px' : '-214px' },
          position: 'fixed',
          top: '0',
          bottom: '0',
          transition: '0.3s left ease-in-out',
          zIndex: 5
        }}
        onClick={onToggle}
        onMouseLeave={onDefocus}
      >
        <Box
          sx={{
            cursor: 'pointer',
            alignSelf: 'center',
            background: '#262B35',
            height: '50px',
            padding: '15px'
          }}
        >
          <img src={logoWhite} width='125px' height='40px' />
        </Box>
        <NavList />
      </Box>
    )
  } else {
    return null
  }
}

export default Sidebar

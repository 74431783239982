import { Box } from 'grommet'

const StepCircle = ({ number, currentStep }) => {
  const returnBackgroundColor = () => {
    if (currentStep > number) {
      return 'black'
    } else if (currentStep === number) {
      return 'brand'
    } else {
      return 'white'
    }
  }

  const style = {
    font: '15px Arial, sans-serif',
    width: '2em',
    height: '2em',
    boxsizing: 'initial',
    border: '0.1em solid #666',
    textAlign: 'center',
    borderRadius: '50%',
    lineHeight: '2em',
    boxSizing: 'content-box'
  }

  return <Box background={returnBackgroundColor()} style={style}> {number + 1} </Box>
}

export default StepCircle

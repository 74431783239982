import { fetchApi, dispatchFetchApi } from './thunk'

export const LINK_GC_COURSE = 'LINK_GC_COURSE'

export function linkGCCourseAction (payload) {
  return {
    type: LINK_GC_COURSE,
    payload
  }
}

export function linkGCCourse (magId, course, cb) {
  return async (dispatch) => {
    const { type } = await dispatch(
      fetchApi(
        `/classes/${magId}/link-gc-course`,
        'POST',
        { ...course }
      )
    )

    if (type === 'SUCCESS') {
      if (cb) cb()

      dispatch({
        type: 'UPDATE_GC_STATE',
        payload: { syncComplete: true }
      })
    }
  }
}

export const UNLINK_GC_COURSE = 'LINK_GC_COURSE'

export function unlinkGCCourseAction (payload) {
  return {
    type: UNLINK_GC_COURSE,
    payload
  }
}

export function unlinkGCCourse (id) {
  return dispatchFetchApi(
    `/classes/${id}/unlink-gc-course`,
    unlinkGCCourseAction,
    'POST',
    {}
  )
}

export const GET_ALL_GC_COURSES = 'GET_GC_COURSES'

export function getAllGCCoursesAction (payload) {
  return {
    type: GET_ALL_GC_COURSES,
    payload
  }
}

// get google classroom courses
export function getAllGCCourses () {
  return dispatchFetchApi(
    `/google-classroom/courses/me`,
    getAllGCCoursesAction
  )
}

// add new google classroom course
export const ADD_GC_COURSE = 'ADD_GC_COURSE'

export function addGCCourseAction (payload) {
  return {
    type: ADD_GC_COURSE,
    payload
  }
}

export function addGCCourse (data, cb) {
  return async (dispatch) => {
    const { payload, type } = await dispatch(fetchApi(
      '/google-classroom/courses',
      'POST',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(addGCCourseAction(payload))
      cb && cb()
    }
  }
}

// Get a single google classroom course
export const GET_GC_COURSE = 'GET_GC_COURSE'

export function getGCCourseAction (payload) {
  return {
    type: GET_GC_COURSE,
    payload
  }
}

export function getGCCourse (id) {
  return fetchApi(
    `/google-classroom/courses/${id}`
  )
}

// Gets the Google Classroom profile info for the currently logged in User
export const GET_USER_PROVIDERS = 'GET_USER_PROVIDERS'

export function getUserProvidersAction (payload) {
  return {
    type: GET_USER_PROVIDERS,
    payload
  }
}

export function getUserProviders () {
  return dispatchFetchApi(
    `/users/me/providers`,
    getUserProvidersAction
  )
}

// add google coursework
export const ADD_GC_COURSEWORK = 'ADD_GC_COURSEWORK'

export function addGCCourseworkAction (payload) {
  return {
    type: ADD_GC_COURSEWORK,
    payload
  }
}

export function addGCCoursework (magClassId, lessonId, courseId, data) {
  return async (dispatch) => {
    const { payload, type } = await dispatch(fetchApi(
      `/google-classroom/courses/${courseId}/courseWork`,
      'POST',
      data
    ))

    if (type === 'SUCCESS') {
      // link courseWork
      dispatch(linkGCCourseWork(magClassId, lessonId, {
        id: payload.courseWork.id,
        courseId,
        workType: 'ASSIGNMENT'
      }))
    }
  }
}

// link class lesson document to Google Classroom CourseWork
export function linkGCCourseWork (classId, lessonId, data) {
  return fetchApi(
    `/classes/${classId}/lessons/${lessonId}/link-gc-courseWork`,
    'POST',
    data
  )
}

export const SYNC_GC_STUDENTS = 'SYNC_GC_STUDENTS'

export function syncGCStudentsAction (payload) {
  return {
    type: SYNC_GC_STUDENTS,
    payload
  }
}

export function syncGCStudents (id, history) {
  return async (dispatch) => {
    const { payload, type } = await dispatch(fetchApi(
      `/classes/${id}/sync-gc-students`,
      'POST',
      {}
    ))

    if (type === 'SUCCESS') {
      dispatch(syncGCStudentsAction(payload))
      history.go(0)
    }
  }
}

// submit student lesson completion
export const SUBMIT_ASSIGNMENT = 'SUBMIT_ASSIGNMENT'

export function submitGCAssignmentAction (payload) {
  return {
    type: SUBMIT_ASSIGNMENT,
    payload
  }
}

export function submitGCAssignment (classId, lessonId) {
  return dispatchFetchApi(
    `/classes/${classId}/lessons/${lessonId}/progress/gc-turn-in`,
    submitGCAssignmentAction,
    'POST',
    {}
  )
}

// remove disable google flow
export const DISABLE_GOOGLE_CLASSROOM = 'DISABLE_GOOGLE_CLASSROOM'

export const disableGoogleClassroomAction = (payload) => {
  return {
    type: DISABLE_GOOGLE_CLASSROOM,
    payload
  }
}

export const disableGoogleClassroom = (data) => {
  return dispatchFetchApi(
    `/users/me/providers/google`,
    disableGoogleClassroomAction,
    'PUT',
    data
  )
}

// get gc coursework
export const GET_GC_COURSEWORK = 'GET_GC_COURSEWORK'

export const getGCCourseWorkAction = (payload) => {
  return {
    type: GET_GC_COURSEWORK,
    payload
  }
}

export const getGCCourseWork = (id) => {
  return fetchApi(
    `/google-classroom/courses/${id}/courseWork`,
    getGCCourseAction
  )
}

export const CLEAR_GC_STATE = 'CLEAR_GC_STATE'

export const clearGCStateAction = (payload) => {
  return {
    type: CLEAR_GC_STATE,
    payload
  }
}

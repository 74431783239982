import { LOADER_TOGGLE } from '../../actions/loader'
import { LOGIN_PENDING, LOGIN_SUCCESS } from '../../actions/user'

const initialState = {
  loading: false
}

export const loader = (state = initialState, { payload, type }) => {
  switch (type) {
    default: {
      return state
    }
    case LOADER_TOGGLE: {
      return {
        loading: payload
      }
    }
    case LOGIN_PENDING: {
      return {
        loading: true
      }
    }
    case LOGIN_SUCCESS: {
      return {
        loading: false
      }
    }
  }
}

export default loader

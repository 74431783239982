import { Text, Box } from 'grommet'
import { FlexBox } from '../Boxes'

const FieldText = (props) => {
  const { text, bold } = props
  return (
    <Text weight={bold ? 'bold' : 200} size='medium' margin={{ vertical: 'auto' }}>{text}</Text>
  )
}

const TextPair = (props) => {
  const { textOne, boldOne, textTwo, boldTwo, ...rest } = props
  return (
    <FlexBox gap='xsmall'>
      <Text {...rest} size='15px' weight={boldOne ? 'bold' : 400}>{textOne}</Text>
      <Text {...rest} size='15px' weight={boldTwo ? 'bold' : 400}>{textTwo}</Text>
    </FlexBox>
  )
}

const TextBox = (props) => {
  const { textOne, textTwo, size } = props
  return (
    <Box direction='row'>
      <Box basis='xsmall'>
        <Text size={size}>{textOne}</Text>
      </Box>
      <Text size={size}>{textTwo}</Text>
    </Box>
  )
}

export {
  TextPair,
  FieldText,
  TextBox
}

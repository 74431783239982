import { useDispatch, useSelector } from 'react-redux'
import { Box, Text } from 'grommet'
import { setOnboardingState } from '../../actions/onboarding'
import { educationOptions } from './constants'

const BoxStyle = ({ text, value }) => {
  const {
    educationLevels
  } = useSelector(state => state.ui.onboarding)

  const dispatch = useDispatch()

  const updateSelection = () => {
    const set = new Set([...educationLevels])

    if (set.has(value.name)) {
      set.delete(value.name)
    } else {
      set.add(value.name)
    }

    dispatch(setOnboardingState({
      nextBtnDisabled: !set.size,
      educationLevels: set
    }))
  }

  const returnBackground = () => {
    if (educationLevels.has(value.name)) {
      return 'brand'
    } else {
      return 'light-3'
    }
  }

  return (
    <Box
      background={returnBackground()}
      onClick={updateSelection}
      align='center'
      basis='1/2'
      pad='small'
      style={{ cursor: 'pointer' }}
      round='5px'
    >
      <Text size='xsmall' textAlign='center' weight='bold'> {text} </Text>
    </Box>
  )
}

const EducationLevels = () => {
  const {
    organizationType,
    educationLevels
  } = useSelector(state => state.ui.onboarding)

  if (organizationType) {
    return (
      <Box margin={{ vertical: 'small' }} gap='medium'>
        <Text size='small' weight='bold'> 1b) Please select education level. (You may select multiple education levels.) </Text>
        <Text textAlign='center' weight='bold' color='brand'>
          {!educationLevels.size && 'Please select level(s) of education'}
        </Text>
        <Box direction='row' justify='around' gap='small'>
          {educationOptions.map(({ text, name }, index) =>
            <BoxStyle
              key={index}
              text={text}
              value={{ index, name, text }}
            />
          )}
        </Box>
      </Box>
    )
  } else {
    return null
  }
}

export default EducationLevels

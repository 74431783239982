import { useState } from 'react'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { MoreHoriz } from '@mui/icons-material'

const MoreDropdown = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const newItems = items.filter(i => !i.hide)

  if (newItems.length) {
    return (
      <div>
        <IconButton
          onClick={handleClick}> 
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
        >
          {newItems.map(({ label, onClick }, i) =>
            <MenuItem key={i} onClick={onClick}>
              {label}
            </MenuItem>
          )}
        </Menu>
      </div>
    )
  } else {
    return null
  }
}

export default MoreDropdown

// COMPONENTS
import { Box } from 'grommet'
import { Pan } from 'grommet-icons'

// UTILS
import { css } from '@emotion/react'

export default () => (
  <Box
    background='brand'
    basis='1/4'
    align='center'
    pad='xsmall'
    css={css`
      &:hover {
        cursor: pointer;
        background: var(--accent-2);
      }
    `}
  >
    <Pan />
  </Box>
)

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Text } from 'grommet'
import SearchFilterComp from './SearchFilter'
import { setOnboardingState } from '../../../actions/onboarding'
import { schoolSearch } from '../../../actions/school'
import { fetchApi } from '../../../actions/thunk'
import CreateSchool from '../Create/School'

const SchoolSearchBox = () => {
  const {
    organizationType,
    districtSelection,
    schoolQuery,
    schoolSelection,
    showDistrictCreate,
    showSchoolCreate
  } = useSelector(state => state.ui.onboarding)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!showDistrictCreate) {
      dispatch(setOnboardingState({
        nextBtnDisabled: !schoolSelection
      }))
    }
  }, [])

  const onChange = (e) => {
    dispatch(setOnboardingState({
      schoolQuery: e.target.value,
      showFlowButtons: false
    }))
  }

  const apiMethod = async () => {
    let districtId = null
    // we have to handle the work options that are not "school".
    if (organizationType.name === 'other') {
      // we use the schoolQuery here because for non school options we set district and schools to the same name
      const { payload } = await dispatch(fetchApi(`/districts/search?name=${schoolQuery}`))

      if (payload && payload.district) {
        dispatch(setOnboardingState({
          districtState: payload.district
        }))
        districtId = payload.district._id
      }
    }

    if (districtId || districtSelection?._id) {
      return dispatch(schoolSearch(districtId || districtSelection?._id, schoolQuery))
    } else {
      return false
    }
  }

  const returnNum = () => {
    return organizationType.name === 'other' ? '2)' : '3)'
  }

  const returnText = () => {
    return organizationType.name === 'other' ? 'organization' : 'school'
  }

  if (showSchoolCreate) {
    return <CreateSchool />
  } else {
    return (
      <Box animation='fadeIn' gap='small' basis='1/2'>
        <Text size='small' weight='bold'> {returnNum()} Which school are you part of? </Text>
        <Text textAlign='center' weight='bold' color='brand' size='small'>
          {!schoolSelection && `Please enter ${returnText()} you wish to search for.`}
        </Text>
        <SearchFilterComp
          onChange={onChange}
          searchValue={schoolQuery}
          value={schoolSelection}
          name='schoolSelection'
          displayName='School'
          setState={(e) => dispatch(setOnboardingState(e))}
          apiMethod={apiMethod}
        />
      </Box>
    )
  }
}

export default SchoolSearchBox

import {
  EDIT_LAB_SUCCESS,
  GET_LABS_SUCCESS
} from '../../actions/labs'

const initialState = {
  isFetched: false,
  ids: [],
  entities: {}
}

export const labs = (state = initialState, { payload, type }) => {
  switch (type) {
    default:
      return state
    case GET_LABS_SUCCESS: {
      const { labs } = payload
      return {
        isFetched: true,
        ids: labs.ids,
        entities: labs.entities
      }
    }
    case EDIT_LAB_SUCCESS: {
      const { _id } = payload.lab

      return {
        ...state,
        entities: {
          ...state.entities,
          [_id]: payload.lab
        }
      }
    }
    case 'CLEAR_LABS': {
      return initialState
    }
  }
}

export default labs

// IMPORTS
import { hasRole } from '../../utils/utilityFuncs'

const REACT_APP_GROOVE_WIDGET_ID = '6cd67883-ca2c-410e-8c6a-15c1e940ad53'
const REACT_APP_GROOVE_ALLOWED_ROLES = ['teacher']

export const GrooveWidget = (props) => {
  window.groove.widget = window.groove.createWidget()

  // initialize widget and display for teachers only
  if (hasRole(REACT_APP_GROOVE_ALLOWED_ROLES, props.roles)) {
    window.groove.widget.init(REACT_APP_GROOVE_WIDGET_ID, {})
  } else { // in case existing widget has already been initialized
    // TODO: The toggle method isn't currently working. Research an alternative method.
    // https://help.groovehq.com/help/groove-widget-advanced-usage
    groove.widget.toggle()
  }

  return null
}

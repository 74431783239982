import * as LoaderActions from './loader'

// FETCH THUNKS
import { sendData } from './thunk'

// ACTIONS
export const SKETCH_EDIT = 'SKETCH_EDIT'
export const SKETCH_SELECT = 'SKETCH_SELECT'
export const SKETCH_SHIELD_SELECT = 'SKETCH_SHIELD_SELECT'
export const SKETCH_PORT_SELECT = 'SKETCH_PORT_SELECT'
export const SKETCH_LIST_SUCCESS = 'SKETCH_LIST_SUCCESS'
export const SKETCH_LIST_FAIL = 'SKETCH_LIST_FAIL'
export const SKETCH_LOAD_SUCCESS = 'SKETCH_LOAD_SUCCESS'
export const SKETCH_LOAD_FAIL = 'SKETCH_LOAD_FAIL'
export const SKETCH_COMPILE_SUCCESS = 'SKETCH_COMPILE_SUCCESS'
export const SKETCH_COMPILE_FAIL = 'SKETCH_COMPILE_FAIL'
export const SKETCH_DEPLOY_SUCCESS = 'SKETCH_DEPLOY_SUCCESS'
export const SKETCH_DEPLOY_FAIL = 'SKETCH_DEPLOY_FAIL'

// ACTION CREATORS
export function sketchEdit (payload) {
  return {
    type: SKETCH_EDIT,
    payload
  }
}

export function sketchSelect (payload) {
  return {
    type: SKETCH_SELECT,
    payload
  }
}

export function shieldSelect (payload) {
  return {
    type: SKETCH_SHIELD_SELECT,
    payload
  }
}

export function portSelect (payload) {
  return {
    type: SKETCH_PORT_SELECT,
    payload
  }
}

export function listSketchesSuccess (payload) {
  return {
    type: SKETCH_LIST_SUCCESS,
    payload
  }
}

export function listSketchesFail (error) {
  return {
    type: SKETCH_LIST_FAIL,
    payload: error
  }
}

export function getSketchSuccess (payload) {
  return {
    type: SKETCH_LOAD_SUCCESS,
    payload
  }
}

export function getSketchFail (error) {
  return {
    type: SKETCH_LOAD_FAIL,
    payload: error
  }
}

export function compileSketchSuccess (payload) {
  return {
    type: SKETCH_COMPILE_SUCCESS,
    payload
  }
}

export function compileSketchFail (error) {
  return {
    type: SKETCH_COMPILE_FAIL,
    payload: error
  }
}

export function deploySketchSuccess (payload) {
  return {
    type: SKETCH_DEPLOY_SUCCESS,
    payload
  }
}

export function deploySketchFail (error) {
  return {
    type: SKETCH_DEPLOY_FAIL,
    payload: error
  }
}

export const getSketch = (payload) => {
  return sendData(
    '/sketches',
    'POST',
    payload,
    getSketchSuccess,
    getSketchFail
  )
}

// Note: previously this function was getting state within getState function.
// Make sure to pass payload as a argument
/*
  const payload = { shield }
*/

export const getSketchListForShield = (payload) => {
  return sendData(
    '/sketches/list',
    'POST',
    payload,
    listSketchesSuccess,
    listSketchesFail
  )
}

// Note: same as above
/*
  const payload = { name, source }
*/
export const compileSketch = (payload) => {
  return sendData(
    '/sketches/compile',
    'POST',
    payload,
    compileSketchSuccess,
    compileSketchFail
  )
}

export const deploySketch = {
  /*
    we use the same route parameters as compile sketch.
    we just need to modify the thunk so that it hands
    the compiled HEX code to our chrome app, which sends
    the code to the USB device.
  */
  ...compileSketch,
  /*
    passing handlers to thunks like this isn't standard Redux practice,
    but this is a one-off.
  */
  thunk: (payload, extensionMessage) => {
    return async (dispatch, getState) => {
      const state = getState()
      const { port } = state.ui.sketch

      /*
      this code goes away when we remove the port select
      if (!port) {
        // block the user from going further if the port isn't set
        const error = 'USB port not set!'
        console.error(error)
        dispatch(
          deploySketchFail(error)
        )
        return
      }
      */

      const { route, method, credentials, headers } = compileSketch
      const { source, name } = payload

      dispatch(LoaderActions.toggle(true))

      try {
        const response = await fetch(route, {
          method,
          headers,
          credentials,
          body: JSON.stringify({
            source,
            name
          })
        })

        const json = await response.json()

        if (json.type === 'SUCCESS') {
          // hand our HEX code to our Chrome app via a message
          const message = {
            type: 'DEPLOY_HEX',
            payload: {
              hex: json.payload,
              port
            }
          }
          // we can do more here based on our Chrome app's response
          extensionMessage(message)

          dispatch(
            deploySketchSuccess(json.payload)
          )
          dispatch(LoaderActions.toggle(false))
        } else {
          dispatch(
            deploySketchFail(json.payload)
          )
          dispatch(LoaderActions.toggle(false))
        }
      } catch (error) {
        console.error(error)
        dispatch(LoaderActions.toggle(false))
      }
    }
  }
}

import { Box, Text, ResponsiveContext } from 'grommet'
import { FieldText } from '../Texts'
import MoreDropdown from '../MoreDropdown'

const BoxWithTitle = ({ title, children, color, margin, items, hideItems, ...rest }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box elevation='5px' round='5px' pad='2vh' background='light-1' margin={margin || size} border {...rest}>
          <FlexBox justify='between' pad={{ vertical: 'small' }}>
            <Text size='small' weight='bold' margin={{ vertical: '5px' }} color={color}>{title}</Text>
            {items && !hideItems && <MoreDropdown items={items} />}
          </FlexBox>
          {children}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const FlexBox = (props) => {
  return (
    <Box direction='row' {...props}>
      {props.children}
    </Box>
  )
}

const FlexBoxWithText = (props) => {
  const { text, size, children, bold, ...rest } = props
  return (
    <FlexBox margin='xsmall' {...rest}>
      <Box basis='small'>
        <FieldText bold={bold} text={text} />
      </Box>
      <Box basis={size}>
        {children}
      </Box>
    </FlexBox>
  )
}

const ScrollableContainer = (props) => {
  return (
    <Box>
      {props.children}
    </Box>
  )
}

export {
  BoxWithTitle,
  FlexBox,
  FlexBoxWithText,
  ScrollableContainer
}

// IMPORTS
import { useSelector } from 'react-redux'

// COMPONENTS
import { Box, Text, Image, Tip } from 'grommet'
import { Edit } from 'grommet-icons'
import DragIcon from './DragIcon'

// UTILS
import { FlexBox } from '../../Boxes'
import { hasRole } from '../../../utils/utilityFuncs'

const defaultImg = 'https://lesson-images-legacy.s3-us-west-1.amazonaws.com/img/img-thumb.png'

const CompactCard = (props) => {
  const {
    imageThumbnail,
    subject,
    title,
    order,
    notDraggable,
    setModalProp
  } = props
  const { roles } = useSelector(state => state.auth.user)
  const canEdit = hasRole(['author', 'superadmin'], roles) && !notDraggable

  return (
    <Box
      elevation='medium'
      width='300px'
      margin='5px'
      border
    >
      <FlexBox>
        <Box direction='row' basis='full'>
          <Box pad='xsmall' background='brand' basis='1/4'>
            <Text truncate>{subject}</Text>
          </Box>
          <Box background='dark-2' pad='xsmall' basis='1/2'>
            <Box direction='row' gap='small'>
              <Text weight='bold' size='small'>Order: {order} </Text>
              <Box alignSelf='center'>
                <Edit size='15px' cursor='pointer' onClick={() => setModalProp(props)} />
              </Box>
            </Box>
          </Box>
          {(canEdit && !notDraggable) && <DragIcon />}
        </Box>
      </FlexBox>
      <Tip content={<Box background='light-4' height='300px'>
        <Image fit='cover' src={imageThumbnail || defaultImg} />
      </Box>}>
        <Box pad='small' justify='center' background='light-1' basis='1/2'>
          <Text weight='bold' size='small'>Title: {title}</Text>
        </Box>
      </Tip>
    </Box >
  )
}

export default CompactCard

import {
  ADD_TEACHER_QUIZ_RESPONSE_SUCCESS,
  ANSWER_QUIZ,
  CREATE_QUIZ_INSTANCE_SUCCESS,
  DELETE_QUIZ_INSTANCE_SUCCESS,
  DELETE_TEACHER_QUIZ_RESPONSE_SUCCESS,
  GET_QUIZ_INSTANCES_SUCCESS,
  GET_QUIZ_INSTANCE_SUCCESS
} from '../../actions/quiz'

import {
  EDIT_ESSAY_NOTE
} from '../../actions/notes'

import { mapQuizInstances } from './util'

const initialState = {
  isFetched: false,
  questionsKeys: [], // storing questions ui
  questionsMap: {}, // used to populate my essay ui
  ids: [], // storing essay id
  entities: {} // storing essay data
}

export const quizzesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    default: {
      return state
    }
    case 'CLEAR_INIT_DATA': {
      return initialState
    }
    case GET_QUIZ_INSTANCES_SUCCESS: {
      const { ids, entities } = payload.quizzes

      const quizInstanceObject = mapQuizInstances(ids, entities)

      return {
        isFetched: true,
        ...quizInstanceObject
      }
    }
    case CREATE_QUIZ_INSTANCE_SUCCESS: {
      const { quizInstance: quiz } = payload

      const ids = [...state.ids]
      const entities = { ...state.entities }

      ids.push(quiz._id)
      entities[quiz._id] = quiz

      return {
        ...state,
        ids,
        entities
      }
    }
    case GET_QUIZ_INSTANCE_SUCCESS: {
      const { quizInstance: quiz } = payload

      return {
        isFetched: true,
        ids: [quiz._id],
        entities: {
          [quiz._id]: quiz
        }
      }
    }
    case ANSWER_QUIZ: {
      return initialState
    }
    case DELETE_QUIZ_INSTANCE_SUCCESS: {
      const { quizInstance: quiz } = payload

      const ids = [...state.ids].filter(id => id !== quiz._id)
      const entities = { ...state.entities }

      delete entities[quiz._id]

      return {
        ...state,
        ids,
        entities
      }
    }
    case ADD_TEACHER_QUIZ_RESPONSE_SUCCESS: {
      const { quizInstance } = payload
      const entities = { ...state.entities }
      const answers = {}

      for (let a = 0; a < quizInstance.answered.length; a++) {
        const answer = quizInstance.answered[a]
        answers[answer._id] = {
          ...entities[answer._id],
          ...answer
        }
      }

      return {
        ...state,
        entities: {
          ...entities,
          ...answers
        }
      }
    }
    case EDIT_ESSAY_NOTE: {
      const { note } = payload
      const entities = { ...state.entities }

      for (let i = 0; i < state.ids.length; i++) {
        if (entities[state.ids[i]].note._id === note._id) {
          entities[state.ids[i]] = {
            ...entities[state.ids[i]],
            note
          }
          break
        }
      }
      return {
        ...state,
        entities
      }
    }
    case DELETE_TEACHER_QUIZ_RESPONSE_SUCCESS: {
      const { note } = payload
      const entities = { ...state.entities }

      for (let i = 0; i < state.ids.length; i++) {
        const response = entities[state.ids[i]]

        if (response.note && response.note._id === note._id) {
          entities[state.ids[i]] = {
            ...entities[state.ids[i]],
            note: null
          }
        }
      }

      return {
        ...state,
        entities
      }
    }
  }
}

export default quizzesReducer

/*
   __  ___                _ __          __        _
  /  |/  /___ ____ ____  (_) /___ _____/ /___    (_)___
 / /|_/ // _ `/ _ `/ _ \/ / __/ // / _  // -_)_ / // _ \
/_/  /_/ \_,_/\_, /_//_/_/\__/\_,_/\_,_/ \__/(_)_/ \___/
             /___/

Magnitude.io Classroom - React & Redux Application
This file is the "top" of our app and starts the component chain.
*/

// IMPORTS
import 'core-js/stable'
import 'bootstrap/dist/css/bootstrap.min.css' // global bootstrap import for reactstrap
import 'font-awesome/css/font-awesome.css'
import './main.css'
import React, { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

// COMPONENTS
import Theme from './Theme'
import GlobalLoader from './components/GlobalLoader'
import GlobalLayout from './components/GlobalLayout'
import FlashOverlay from './components/FlashOverlay'
import { UserProvider } from './components/Providers'
import ErrorHandler from './components/ErrorHandler'

// SCREENS
const LandingPage = lazy(() => import('./screens/LandingPage'))
const LoginPage = lazy(() => import('./screens/LandingPage/Login'))
const ForgotLogin = lazy(() => import('./screens/LandingPage/ForgotLogin'))
const ResetPassword = lazy(() => import('./screens/LandingPage/ResetPw'))
const NotFoundPage = lazy(() => import('./screens/NotFoundPage'))
const FreeAccountFlow = lazy(() => import('./screens/LandingPage/FreeAccountFlow'))
const Announcements = lazy(() => import('./screens/Announcements'))
const ManageAnnouncements = lazy(() => import('./screens/Announcements/Manage'))
const SocketIOTester = lazy(() => import('./screens/SocketIO/Tester'))

// - LESSONS
const LessonsLibrary = lazy(() => import('./screens/LessonsLibrary'))
const LessonCreate = lazy(() => import('./screens/LessonsLibrary/Create'))
const LessonEdit = lazy(() => import('./screens/LessonsLibrary/Edit'))
const LessonsUnapproved = lazy(() => import('./screens/LessonsLibrary/LessonsUnapproved'))
const LessonsArchived = lazy(() => import('./screens/LessonsLibrary/Archived'))
const MyLessons = lazy(() => import('./screens/MyLessons'))
const LessonsByProduct = lazy(() => import('./screens/LessonsByProduct'))
const AssignLesson = lazy(() => import('./screens/LessonsLibrary/AssignLesson'))
const MagnitudeMissionsLinks = lazy(() => import('./screens/LessonsLibrary/MagnitudePastMissions'))
const OtherMissionsLinks = lazy(() => import('./screens/LessonsLibrary/OtherPastMissions'))

// - FIZZICS
const FizzicsLessonsContent = lazy(() => import('./screens/Fizzics'))

// - LESSON
const Lesson = lazy(() => import('./screens/Lesson'))
const LessonProgress = lazy(() => import('./screens/Lesson/LessonProgress'))

// - ESSAY
const LessonEssayResults = lazy(() => import('./screens/EssayResults/TeacherView'))
const StudentEssayResults = lazy(() => import('./screens/EssayResults/StudentEssay'))

// - SECTIONS
const SectionCreate = lazy(() => import('./screens/Sections/Create'))
const SectionEdit = lazy(() => import('./screens/Sections/Edit'))

// - CANSAT
const CanSatDevices = lazy(() => import('./screens/CanSat/Devices'))
const CanSatFlightHistory = lazy(() => import('./screens/CanSat/FlightHistory'))
const CanSatDataView = lazy(() => import('./screens/CanSat/DataView'))
const FlightScreen = lazy(() => import('./screens/CanSat/Flight'))

// - INSTALL EXTENSIONS
import Extensions from './screens/CanSat/Extensions'

// - EXOLAB EXPERIMENTS
const ExoLabExperiments = lazy(() => import('./screens/Experiments/ExoLab'))
const ExoLabExperimentCreate = lazy(() => import('./screens/Experiments/ExoLab/Create'))
const ExoLabCompareWithISS = lazy(() => import('./screens/ExoLab/CompareWithISS'))
const ExoLabCompareWithSchools = lazy(() => import('./screens/ExoLab/CompareWithSchools'))
const ExoLabCharts = lazy(() => import('./screens/ExoLab/Charts'))
const ExoLabExperimentEdit = lazy(() => import('./screens/Experiments/ExoLab/Edit'))
const ExoLabConfirm = lazy(() => import('./screens/ExoLab/Confirm'))
const GroundTrials = lazy(() => import('./screens/GroundTrials'))

// - DEVICES
const CreateBatchDevices = lazy(() => import('./screens/Devices/BatchCreate'))
const CreateDevice = lazy(() => import('./screens/Devices/Create'))
const EditDevice = lazy(() => import('./screens/Devices/Edit'))
const OnlineDevices = lazy(() => import('./screens/Devices/OnlineDevices'))
const Devices = lazy(() => import('./screens/Devices'))
const AssignedDevices = lazy(() => import('./screens/Devices/AssignedDevices'))
const AssignDevice = lazy(() => import('./screens/Devices/AssignDevice'))
const WatchList = lazy(() => import('./screens/Devices/WatchList'))
const DeviceManager = lazy(() => import('./screens/Devices/Manager'))

// - ISS DEVICES
const Labs = lazy(() => import('./screens/Labs'))
const LabCreate = lazy(() => import('./screens/Labs/Create'))
const LabEdit = lazy(() => import('./screens/Labs/Edit'))
const SerialNumList = lazy(() => import('./screens/ExoLab/AddSerialNumber'))
const FeaturedLab = lazy(() => import('./screens/Labs/FeaturedLab'))

// - SET PRIVATE LABS VIEW
const SetPrivateLabs = lazy(() => import('./screens/SetPrivateLabs'))

// - SKETCHES
const Sketch = lazy(() => import('./screens/CanSat/Sketch'))
const SketchEditor = lazy(() => import('./screens/CanSat/Sketch/Editor'))

// - DISTRICTS
const Districts = lazy(() => import('./screens/Districts'))
const District = lazy(() => import('./screens/District'))
const DistrictTeachers = lazy(() => import('./screens/District/teachers'))
const DistrictsCreate = lazy(() => import('./screens/Districts/Create'))
const DistrictsEdit = lazy(() => import('./screens/Districts/Edit'))
const DistrictAdmins = lazy(() => import('./screens/Districts/Admins'))

// - CLASSES
const Classes = lazy(() => import('./screens/Classes'))
const ClassesEdit = lazy(() => import('./screens/Classes/Edit'))
const ClassCreate = lazy(() => import('./screens/Classes/Create'))

// - CLASS
const ClassDashboard = lazy(() => import('./screens/Class'))
const RabbitInterceptor = lazy(() => import('./components/Class/RabbitInterceptor'))

// - STUDENT
const Students = lazy(() => import('./screens/Students'))
const StudentsCreate = lazy(() => import('./screens/Students/Create'))
const StudentsEdit = lazy(() => import('./screens/Class/AssignedStudents/Edit'))
const LessonNotes = lazy(() => import('./screens/Class/AssignedLessons/StudentNotes'))
const StudentSignUp = lazy(() => import('./screens/Students/SignUp'))

// - TEAMS
const TeamsManager = lazy(() => import('./screens/Teams/Manager'))

// - USERS
const Users = lazy(() => import('./screens/Users'))
const UsersEdit = lazy(() => import('./screens/Users/Edit'))
const UsersCreate = lazy(() => import('./screens/Users/Create'))

// - USER
const User = lazy(() => import('./screens/User'))

// - NOTES
const MyNotes = lazy(() => import('./screens/Notes/MyNotes'))
const StudentNotes = lazy(() => import('./screens/Notes/StudentNotes'))

// - MY ACCOUNT
const MyAccount = lazy(() => import('./screens/MyAccount'))
const ResetEmail = lazy(() => import('./screens/MyAccount/ResetEmail'))

// - ROLES
const Roles = lazy(() => import('./screens/Roles'))

// - SCHOOLS
const Schools = lazy(() => import('./screens/Schools'))
const SchoolsEdit = lazy(() => import('./screens/Schools/Edit'))
const SchoolsCreate = lazy(() => import('./screens/Schools/Create'))

// - PRODUCTS
const Products = lazy(() => import('./screens/Products'))
const ProductsActivate = lazy(() => import('./screens/Products/Activate'))
const ProductsEdit = lazy(() => import('./screens/Products/Edit'))
const ProductsCreate = lazy(() => import('./screens/Products/Create'))

// UTILS
import ScrollToTop from './components/ScrollToTop'
import UserInformation from './components/LandingPage/FreeAccountFlow/UserInformation'
import PostSignUpConfirmation from './components/LandingPage/FreeAccountFlow/PostSignUpConfirmation'
import { DeviceManagerRoute } from './components/Router/DeviceManagerRoute'

// ROUTER
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import { store } from './utils/generateStore'
import PrivateRoute from './components/Router/PrivateRoute'

const App = () => (
  <Provider store={store}>
    <Router>
      <Theme>
        <ScrollToTop />  {/* Forces page to scroll to window position (0,0) on component mount */}
        <ErrorHandler /> {/* Global error handler */}
        <GlobalLoader /> {/* Global component for toggling loader */}
        <FlashOverlay /> {/* Global component for displaying flash messages */}
        <Suspense fallback={null}>
          <Switch>
            {/* PUBLIC ROUTES OPEN TO EVERYONE */}
            <Route exact path='/login' component={LoginPage} />
            <Route exact path='/forgot' component={ForgotLogin} />
            <Route exact path='/login/reset/:token' component={ResetPassword} />
            <Route exact path='/student-signup' component={StudentSignUp} />
            <Route exact path='/free-account-setup' component={FreeAccountFlow} />
            <Route exact path='/free-account-setup/user-information' component={UserInformation} />
            <Route exact path='/free-account-setup/confirmation' component={PostSignUpConfirmation} />

            <UserProvider>
              <GlobalLayout>
                <Switch>
                  {/* ROUTES WITHIN OUR APP OPEN TO ALL ROLES */}
                  <PrivateRoute path='/' exact component={LandingPage} />
                  <PrivateRoute path='/my-account' exact component={MyAccount} />

                  {/* SOCKETIO TESTER */}
                  <PrivateRoute path='/socketio-tester' exact allowedRoles={['superadmin']} component={SocketIOTester} />

                  {/* ANNOUNCEMENTS */}
                  <PrivateRoute path='/announcements' exact component={Announcements} />
                  <PrivateRoute path='/announcements/manage' exact allowedRoles={['superadmin']} component={ManageAnnouncements} />

                  {/* FIZZICS CONTENT */}
                  <PrivateRoute path='/fizzics' exact allowedRoles={['superadmin', 'teacher', 'student', 'author']} component={FizzicsLessonsContent} />
                  <PrivateRoute path='/lessons/product/fizzics-content' exact allowedRoles={['superadmin', 'teacher', 'author', 'district-admin']} component={FizzicsLessonsContent} />

                  {/* PAST EXOLAB MISSIONS */}
                  <PrivateRoute path='/lessons/product/exolab/past-missions-links' exact allowedRoles={['superadmin', 'teacher', 'district-admin']} component={MagnitudeMissionsLinks} />
                  <PrivateRoute path='/lessons/product/exolab/past-missions-links-others' exact allowedRoles={['superadmin', 'teacher', 'district-admin']} component={OtherMissionsLinks} />

                  {/* LESSON(S) ROUTES */}
                  <PrivateRoute path='/my-lessons' exact allowedRoles={['teacher', 'author']} component={MyLessons} />
                  <PrivateRoute path='/lessons' exact allowedRoles={['superadmin', 'teacher', 'author', 'district-admin']} component={LessonsLibrary} />
                  <PrivateRoute path='/lessons/unapproved' exact allowedRoles={['superadmin']} component={LessonsUnapproved} />
                  <PrivateRoute path='/lessons/archived' exact allowedRoles={['superadmin', 'teacher']} component={LessonsArchived} />
                  <PrivateRoute path='/lessons/:slug/progress' exact allowedRoles={['teacher']} component={LessonProgress} />
                  <PrivateRoute path='/lessons/create' exact allowedRoles={['superadmin', 'teacher', 'author']} component={LessonCreate} />
                  <PrivateRoute path='/lessons/product/:slug' exact allowedRoles={['superadmin', 'teacher', 'author', 'district-admin']} component={LessonsByProduct} />
                  <PrivateRoute path='/lessons/:lessonSlug' exact allowedRoles={['superadmin', 'teacher', 'author', 'student', 'district-admin']} component={Lesson} />
                  <PrivateRoute path='/lessons/:lessonSlug/assign' exact allowedRoles={['superadmin', 'teacher', 'district-admin']} component={AssignLesson} />
                  <PrivateRoute path='/lessons/:lessonSlug/edit' exact allowedRoles={['superadmin', 'teacher', 'author']} component={LessonEdit} />

                  {/* LESSON ESSAY RESULT */}
                  <PrivateRoute path='/lessons/:slug/classes/:classSlug/essay-results' exact allowedRoles={['teacher']} component={LessonEssayResults} />
                  <PrivateRoute path='/students/my-essays' exact allowedRoles={['student']} component={StudentEssayResults} />

                  {/* LESSONS SECTIONS ROUTES */}
                  <PrivateRoute path='/lessons/:lessonSlug/sections/create' exact allowedRoles={['superadmin', 'teacher', 'author']} component={SectionCreate} />
                  <PrivateRoute path='/lessons/:lessonSlug/sections/:sectionId/edit' exact allowedRoles={['superadmin', 'teacher', 'author']} component={SectionEdit} />

                  {/* DISTRICTS */}
                  <PrivateRoute path='/districts' exact allowedRoles={['superadmin']} component={Districts} />
                  <PrivateRoute path='/districts/create' exact allowedRoles={['superadmin']} component={DistrictsCreate} />
                  <PrivateRoute path='/districts/:slug' exact allowedRoles={['superadmin']} component={District} />
                  <PrivateRoute path='/districts/:slug/teachers' exact allowedRoles={['superadmin', 'district-admin']} component={DistrictTeachers} />
                  <PrivateRoute path='/districts/:slug/edit' exact allowedRoles={['superadmin', 'district-admin']} component={DistrictsEdit} />
                  <PrivateRoute path='/districts/:slug/admins' exact allowedRoles={['superadmin']} component={DistrictAdmins} />

                  {/* SCHOOLS */}
                  <PrivateRoute path='/districts/:districtSlug/schools/create' exact allowedRoles={['superadmin', 'district-admin']} component={SchoolsCreate} />
                  <PrivateRoute path='/districts/:districtSlug/schools/:schoolSlug' exact allowedRoles={['superadmin', 'district-admin']} component={Schools} />
                  <PrivateRoute path='/districts/:districtSlug/schools/:schoolSlug/edit' exact allowedRoles={['superadmin', 'district-admin']} component={SchoolsEdit} />

                  {/* CLASSES */}
                  <PrivateRoute path='/classes' exact allowedRoles={['teacher']} component={Classes} />
                  <PrivateRoute path='/classes/create' exact allowedRoles={['teacher']} component={ClassCreate} />
                  <PrivateRoute path='/classes/students' exact allowedRoles={['teacher']} component={Students} />
                  <PrivateRoute path='/classes/:classSlug' exact allowedRoles={['teacher', 'student']} component={ClassDashboard} />
                  <PrivateRoute path='/classes/:classSlug/edit' exact allowedRoles={['teacher']} component={ClassesEdit} />
                  <PrivateRoute path='/classes/:classSlug/students/manage' exact allowedRoles={['teacher']} component={StudentsCreate} />
                  <PrivateRoute path='/classes/:classSlug/students/edit' exact allowedRoles={['teacher']} component={StudentsEdit} />
                  <PrivateRoute path='/classes/:classSlug/lessons/:lessonSlug/allnotes' exact allowedRoles={['teacher', 'district-admin']} component={LessonNotes} />
                  <PrivateRoute path='/classes/:classSlug/lessons/:lessonSlug' exact allowedRoles={['teacher', 'student']} component={Lesson} />

                  {/* ROUTE FOR REDIRECTING RABBIT COLORING BOOK USERS */}
                  <PrivateRoute path='/library/coloring-book/:lessonSlug' exact allowedRoles={['teacher']} component={RabbitInterceptor} />

                  {/* ADMIN VIEW FOR CLASSES */}
                  <PrivateRoute path='/teachers/:id/classes' exact allowedRoles={['superadmin', 'district-admin']} component={Classes} />
                  <PrivateRoute path='/teachers/:id/classes/create' exact allowedRoles={['superadmin', 'district-admin']} component={ClassCreate} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug' exact allowedRoles={['superadmin', 'district-admin']} component={ClassDashboard} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug/edit' exact allowedRoles={['superadmin', 'district-admin']} component={ClassesEdit} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug/students/manage' exact allowedRoles={['superadmin', 'district-admin']} component={StudentsCreate} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug/students/edit' exact allowedRoles={['superadmin', 'district-admin']} component={StudentsEdit} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug/lessons' exact allowedRoles={['superadmin', 'district-admin']} component={LessonsLibrary} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug/lessons/:lessonSlug' exact allowedRoles={['superadmin', 'district-admin']} component={Lesson} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug/lessons/:lessonSlug/assign' exact allowedRoles={['superadmin', 'district-admin']} component={AssignLesson} />
                  <PrivateRoute path='/teachers/:id/classes/:classSlug/lessons/:lessonSlug/allnotes' exact allowedRoles={['superadmin', 'district-admin']} component={LessonNotes} />

                  {/* TEAMS */}
                  <PrivateRoute path='/classes/:slug/teams/edit' exact allowedRoles={['superadmin', 'teacher']} component={TeamsManager} />
                  {/* <PrivateRoute path='/classes/:slug/teams/create' exact allowedRoles={['superadmin', 'teacher']} component={TeamsCreate} /> */}

                  {/* USERS */}
                  <PrivateRoute path='/users' exact allowedRoles={['superadmin']} component={Users} />
                  <PrivateRoute path='/users/create' exact allowedRoles={['superadmin']} component={UsersCreate} />
                  <PrivateRoute path='/users/:id/edit' exact allowedRoles={['superadmin']} component={UsersEdit} />
                  <PrivateRoute path='/my-account/:token' exact allowedRoles={['superadmin', 'district-admin', 'teacher']} component={ResetEmail} />
                  {/* USER */}
                  <PrivateRoute path='/users/:id' exact allowedRoles={['superadmin']} component={User} />

                  {/* ROLES */}
                  <PrivateRoute path='/roles' exact allowedRoles={['superadmin']} component={Roles} />

                  {/* NOTES */}
                  <PrivateRoute path='/my-notes' exact allowedRoles={['student', 'teacher']} component={MyNotes} />
                  <PrivateRoute path='/student-notes' exact allowedRoles={['teacher', 'district-admin']} component={StudentNotes} />

                  {/* PRODUCTS */}
                  <PrivateRoute path='/products' exact allowedRoles={['superadmin']} component={Products} />
                  <PrivateRoute path='/products/activate' exact allowedRoles={['superadmin']} component={ProductsActivate} />
                  <PrivateRoute path='/products/create' exact allowedRoles={['superadmin']} component={ProductsCreate} />
                  <PrivateRoute path='/products/:slug/edit' exact allowedRoles={['superadmin']} component={ProductsEdit} />

                  {/* CANSAT */}
                  <PrivateRoute path='/cansat/devices' exact allowedRoles={['superadmin', 'teacher']} component={CanSatDevices} />
                  <PrivateRoute path='/cansat/flight-setup' exact allowedRoles={['superadmin', 'teacher', 'student']} component={FlightScreen} />
                  <PrivateRoute path='/cansat/flights' exact allowedRoles={['superadmin', 'teacher', 'student']} component={CanSatFlightHistory} />
                  <PrivateRoute path='/cansat/install-sketch' exact allowedRoles={['superadmin', 'teacher', 'student']} component={Sketch} />
                  <PrivateRoute path='/cansat/install-sketch/:sketch' exact allowedRoles={['superadmin', 'teacher', 'student']} component={SketchEditor} />
                  <PrivateRoute path='/cansat/flights/:flight' exact allowedRoles={['superadmin', 'teacher', 'student']} component={CanSatDataView} />
                  <PrivateRoute path='/cansat/install-extension' exact component={Extensions} />

                  {/* ISS DEVICES */}
                  <PrivateRoute path='/devices/iss-labs' exact allowedRoles={['superadmin']} component={Labs} />
                  <PrivateRoute path='/devices/iss-labs/create' exact allowedRoles={['superadmin']} component={LabCreate} />
                  <PrivateRoute path='/devices/iss-labs/:id/edit' exact allowedRoles={['superadmin']} component={LabEdit} />

                  {/* DEVICES */}
                  <PrivateRoute path='/devices' exact allowedRoles={['superadmin']} component={Devices} />
                  <PrivateRoute path='/devices/create' exact allowedRoles={['superadmin']} component={CreateDevice} />
                  <PrivateRoute path='/devices/:id/edit' exact allowedRoles={['superadmin']} component={EditDevice} />
                  <PrivateRoute path='/devices/batch' exact allowedRoles={['superadmin']} component={CreateBatchDevices} />
                  <PrivateRoute path='/devices/watchlist' exact allowedRoles={['superadmin']} component={WatchList} />
                  <PrivateRoute path='/devices/watchlist/add-device' exact allowedRoles={['superadmin']} component={SerialNumList} />
                  <PrivateRoute path='/devices/online' exact allowedRoles={['superadmin']} component={OnlineDevices} />
                  <PrivateRoute path='/devices/assigned' exact allowedRoles={['superadmin']} component={AssignedDevices} />
                  <PrivateRoute path='/devices/assign' exact allowedRoles={['superadmin']} component={AssignDevice} />
                  <DeviceManagerRoute path='/devices/manager' exact component={DeviceManager} />

                  {/* SET PRIVATE LAB */}
                  <PrivateRoute path='/private-labs' exact allowedRoles={['superadmin']} component={SetPrivateLabs} />

                  {/* EXPERIMENTS */}
                  <PrivateRoute path='/classes/:slug/experiments/:product/create' exact allowedRoles={['teacher', 'student']} component={ExoLabExperimentCreate} />
                  <PrivateRoute path='/experiments/exolab/create' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={ExoLabExperimentCreate} />
                  <PrivateRoute path='/experiments/:id/dashboard' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={ExoLabCompareWithISS} />
                  <PrivateRoute path='/experiments/:id/explore' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={ExoLabCompareWithSchools} />
                  <PrivateRoute path='/experiments/:id/edit' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={ExoLabExperimentEdit} />

                  {/* FEATURED LAB */}
                  <PrivateRoute path='/featured-lab' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={FeaturedLab}/>

                  {/* EXOLAB */}
                  <PrivateRoute path='/exolab' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={ExoLabExperiments} />
                  <PrivateRoute path='/exolab/ground-trials' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={GroundTrials} />
                  <PrivateRoute path='/exolab/confirm' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={ExoLabConfirm} />
                  <PrivateRoute path='/exolab/view/charts' exact allowedRoles={['superadmin', 'district-admin', 'teacher', 'student']} component={ExoLabCharts} />

                  {/* ANY NON MATCHING ROUTES WILL BE INTERCEPTED HERE */}
                  <PrivateRoute path='*' component={NotFoundPage} />
                </Switch>
              </GlobalLayout>
            </UserProvider>
          </Switch>
        </Suspense>
      </Theme>
    </Router>
  </Provider>
)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)

import {
  ROLES_LIST,
  ROLE_ADD,
  ROLE_DELETE,
  ROLE_EDIT
} from '../../actions/role'

export default function roles (
  state = {
    isFetched: false,
    entities: {},
    ids: []
  },
  action
) {
  switch (action.type) {
    default: {
      return state
    }
    case ROLES_LIST: {
      const newState = { ...state }
      const { entities, ids } = action.payload.roles

      newState.isFetched = true
      newState.entities = entities
      newState.ids = ids

      return newState
    }
    case ROLE_ADD: {
      const newState = { ...state }
      const { entities, ids } = action.payload.roles

      newState.isFetched = true
      newState.entities = entities
      newState.ids = ids

      return newState
    }
    case ROLE_EDIT: {
      const { role } = action.payload

      const newEntities = { ...state.entities }
      newEntities[role._id] = role

      return {
        ...state,
        entities: newEntities
      }
    }
    case ROLE_DELETE: {
      const newState = { ...state }
      const { _id } = action.payload.role

      delete newState.entities[ _id ]

      const index = newState.ids.indexOf(_id)
      newState.ids.splice(index, 1)

      return newState
    }
  }
}

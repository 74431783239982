import { Button, Box, Text } from 'grommet'
import { useDispatch, useSelector } from 'react-redux'
import { setOnboardingState } from '../../actions/onboarding'

const FlowButtons = ({ name }) => {
  const { showFlowButtons } = useSelector(state => state.ui.onboarding)
  const dispatch = useDispatch()

  const onYesClicked = () => {
    dispatch(setOnboardingState({
      showFlowButtons: false,
      showSchoolForm: name === 'districtSelection',
      nextBtnDisabled: false
    }))
  }

  const onNoClicked = () => {
    dispatch(setOnboardingState({
      schoolQuery: '',
      districtQuery: '',
      showSchoolForm: name === 'schoolSelection',
      [name]: null,
      showFlowButtons: false
    }))
  }

  if (showFlowButtons) {
    return (
      <Box alignSelf='center' gap='small' margin={{ top: 'medium' }}>
        <Text textAlign='center' weight='bold' color='brand' size='small'> Is this information correct? </Text>
        <Box direction='row' animation='fadeIn' gap='small' alignSelf='center'>
          <Button label='Yes' primary  onClick={onYesClicked} />
          <Button label='No' primary color='status-critical'  onClick={onNoClicked} />
        </Box>
      </Box>
    )
  } else {
    return null
  }
}

export default FlowButtons

import {
  SKETCH_EDIT,
  SKETCH_SELECT,
  SKETCH_PORT_SELECT,
  SKETCH_SHIELD_SELECT,
  SKETCH_LIST_SUCCESS,
  SKETCH_LIST_FAIL,
  SKETCH_LOAD_SUCCESS,
  SKETCH_LOAD_FAIL
} from '../../actions/sketch'

export default function sketch (
  state = {
    shield: 'green',
    sketches: [], // this should be an API reducer
    name: null,
    source: '',
    port: false
  },
  action
) {
  switch (action.type) {
    default: {
      return state
    }
    case SKETCH_EDIT: {
      const newState = { ...state }
      newState.source = action.payload
      return newState
    }
    case SKETCH_SELECT: {
      const newState = { ...state }
      newState.sketch = action.payload
      return newState
    }
    case SKETCH_PORT_SELECT: {
      const newState = { ...state }
      newState.port = action.payload
      return newState
    }
    case SKETCH_SHIELD_SELECT: {
      const newState = { ...state }
      newState.shield = action.payload
      return newState
    }
    case SKETCH_LIST_SUCCESS: {
      const newState = { ...state }
      newState.sketches = action.payload
      return newState
    }
    case SKETCH_LOAD_SUCCESS: {
      const newState = { ...state }
      const {
        name,
        source,
        tag
      } = action.payload
      newState.source = source
      newState.name = name
      return newState
    }
  }
}

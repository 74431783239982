import { Box, Text } from 'grommet'
import { useDispatch, useSelector } from 'react-redux'
import { MdSchool, MdOtherHouses } from '../../../assets/MdIcons'
import { setOnboardingState } from '../../actions/onboarding'
import { useEffect } from 'react'
import HorizonalLine from '../HorizontalRule'
import EducationLevels from './EducationLevel'
import { initialState } from '../../reducers/ui/onboarding'

export const BoxStyle = ({ text, Icon, value }) => {
  const {
    organizationType
  } = useSelector(state => state.ui.onboarding)

  const dispatch = useDispatch()

  const updateSelection = () => {
    dispatch(setOnboardingState({
      ...initialState,
      organizationType: value,
      showDistrictForm: value === 'District'
    }))
  }

  const returnBackground = () => {
    if (organizationType && organizationType.name === value.name) {
      return 'brand'
    } else {
      return 'light-3'
    }
  }

  return (
    <Box
      background={returnBackground()}
      onClick={updateSelection}
      align='center'
      basis='1/2'
      pad='small'
      style={{ cursor: 'pointer' }}
      round='5px'
    >
      <Icon size='50px' textalign='center' />
      <Text size='xsmall' textAlign='center' weight='bold'> {text} </Text>
    </Box>
  )
}

const OrganizationTypes = () => {
  const {
    showDistrictForm,
    organizationType
  } = useSelector(state => state.ui.onboarding)
  const dispatch = useDispatch()

  useEffect(() => {
    if (organizationType) {
      dispatch(setOnboardingState({
        nextBtnDisabled: false
      }))
    }
  }, [])

  return (
    <Box margin={{ vertical: 'small' }} gap='medium'>
      <Text size='small' weight='bold'> 1a) What type of organization do you work for? </Text>
      <Text textAlign='center' weight='bold' color='brand'>
        {showDistrictForm === null && 'Please select a type of organization.'}
      </Text>
      <Box direction='row' justify='around' gap='small'>
        <BoxStyle
          text='Public Schooling ("K - 12", "Universities")'
          Icon={MdSchool}
          value={{ name: 'public', text: 'Public Schooling ("K - 12", "Universities")' }} // value prop is for showing district form
        />
        <BoxStyle
          text='Others ("Private Schools", "Science Centers", "Musuems", etc...)'
          Icon={MdOtherHouses}
          value={{ name: 'other', text: 'Others ("Private Schools", "Science Centers", "Musuems", etc...)' }}
        />
      </Box>
      <HorizonalLine />
      <EducationLevels />
    </Box>
  )
}

export default OrganizationTypes

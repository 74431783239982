import { sendData, fetchData, dispatchFetchApi, fetchApi } from './thunk'

/* Section edit constant + actions */
export const SECTION_EDIT = 'SECTION_EDIT'

export function editSectionAction (payload) {
  return {
    type: SECTION_EDIT,
    payload
  }
}

export const editSection = (data) => {
  const { lessonId, sectionId, lessonSlug } = data

  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(
      `/lessons/${lessonId}/sections/${sectionId}`,
      'PUT',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(editSectionAction(payload))
      return `/lessons/${lessonSlug}`
    }
  }
}

/* Section add constants + actions */
export const SECTION_ADD = 'SECTION_ADD'

export function addSectionAction (payload) {
  return {
    type: SECTION_ADD,
    payload
  }
}

// Create a section for a lesson
export const addSection = (data, addAnother) => {
  // Lessons Slug
  const { lessonSlug } = data
  const route = `/lessons/${lessonSlug}`

  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(
      `/lessons/${lessonSlug}/sections`,
      'POST',
      data
    ))

    dispatch(addSectionAction(payload))

    let redirect = addAnother
      ? `${route}/sections/create`
      : route

    return redirect
  }
}

/* Set sections constants + actions */
export const SECTION_SET_ANSWER = 'SECTION_SET_ANSWER'

export function setSectionAnswer (payload) {
  return {
    type: SECTION_SET_ANSWER,
    payload
  }
}

/* List section constants + actions */
export const SECTIONS_LIST_FAIL = 'SECTIONS_LIST_FAIL'
export const SECTIONS_LIST_SUCCESS = 'SECTIONS_LIST_SUCCESS'

export function listSectionsFail (payload) {
  return {
    type: SECTIONS_LIST_FAIL,
    payload
  }
}

export function listSectionsSuccess (payload) {
  return {
    type: SECTIONS_LIST_SUCCESS,
    payload
  }
}

export const getSections = () => {
  return fetchData(
    '/sections',
    listSectionsSuccess,
    listSectionsFail
  )
}

/* Submit sections constants + actions */
export const SECTIONS_SUBMIT_FAIL = 'SECTIONS_SUBMIT_FAIL'
export const SECTIONS_SUBMIT_SUCCESS = 'SECTIONS_SUBMIT_SUCCESS'

export function submitSectionsFail (payload) {
  return {
    type: SECTIONS_SUBMIT_FAIL,
    payload
  }
}

export function submitSectionsSuccess (payload) {
  return {
    type: SECTIONS_SUBMIT_SUCCESS,
    payload
  }
}

export const submitSections = (payload) => {
  return sendData(
    '/entry',
    'POST',
    payload,
    submitSectionsSuccess,
    submitSectionsFail
  )
}

/* Delete section constants + actions */
export const SECTION_DELETE = 'SECTION_DELETE'

export function deleteSectionAction (payload) {
  return {
    type: SECTION_DELETE,
    payload
  }
}

// Delete a section from a lesson
export const deleteSection = (clientPayload) => {
  // Lesson slug and _id of section
  const { _id, slug } = clientPayload
  return dispatchFetchApi(
    `/lessons/${slug}/sections/${_id}`,
    deleteSectionAction,
    'DELETE',
    clientPayload
  )
}

/* Add notes to section constants + actions */
export const ADD_SECTION_NOTE_SUCCESS = 'ADD_SECTION_NOTE_SUCCESS'
export const ADD_SECTION_NOTE_FAIL = 'ADD_SECTION_NOTE_FAIL'

export function addSectionNoteSuccess (payload) {
  return {
    type: ADD_SECTION_NOTE_SUCCESS,
    payload
  }
}

export function addSectionNoteFail (error) {
  return {
    type: ADD_SECTION_NOTE_FAIL,
    payload: error
  }
}

export const addSectionNote = (payload) => {
  const { lessonId, sectionId, content } = payload
  return sendData(
    `/lessons/${lessonId}/sections/${sectionId}/notes`,
    'POST',
    { content },
    addSectionNoteSuccess,
    addSectionNoteFail
  )
}

/* Get notes from sections constants + actions */
export const GET_SECTION_NOTES_SUCCESS = 'GET_SECTION_NOTES_SUCCESS'
export const GET_SECTION_NOTES_FAIL = 'GET_SECTION_NOTES_FAIL'

export function getSectionNotesSuccess (payload) {
  return {
    type: GET_SECTION_NOTES_SUCCESS,
    payload
  }
}

export function getSectionNotesFail (error) {
  return {
    type: GET_SECTION_NOTES_FAIL,
    payload: error
  }
}

export const getSectionNotes = (payload) => {
  const { lessonId, sectionId } = payload
  return fetchData(
    `/lessons/${lessonId}/sections/${sectionId}/notes`,
    getSectionNotesSuccess,
    getSectionNotesFail
  )
}

// Order lesson sections
export const SECTION_MOVE = 'SECTION_MOVE'

export function moveSectionAction (payload) {
  return {
    type: SECTION_MOVE,
    payload
  }
}

export const moveSection = (data, lessonId, sectionId) => {
  return dispatchFetchApi(
    `/lessons/${lessonId}/sections/${sectionId}/order`,
    moveSectionAction,
    'POST',
    data
  )
}

/* Student Lesson UI */
export const UPDATE_SECTION_UI = 'UPDATE_SECTION_UI'

export const updateSectionUI = (payload) => {
  return {
    type: UPDATE_SECTION_UI,
    payload
  }
}

export const ADD_QUESTION_STATE = 'ADD_QUESTION_STATE'

export function addQuestionState (payload) {
  return {
    type: ADD_QUESTION_STATE,
    payload
  }
}

export const CLEAR_QUESTION_STATE = 'CLEAR_QUESTION_STATE'

export function clearQuestionState () {
  return {
    type: CLEAR_QUESTION_STATE
  }
}

export const UPDATE_QUESTION_STATE = 'UPDATE_QUESTION_STATE'

export function updateQuestionState (payload) {
  return {
    type: UPDATE_QUESTION_STATE,
    payload
  }
}

export const UPDATE_QUIZ_INSTANCE = 'UPDATE_QUIZ_INSTANCE'

export function updateQuizInstance (payload) {
  return {
    type: UPDATE_QUIZ_INSTANCE,
    payload
  }
}

export const CLEAR_QUIZ_INSTANCE = 'CLEAR_QUIZ_INSTANCE'

export function clearQuizInstance () {
  return {
    type: CLEAR_QUIZ_INSTANCE
  }
}

export const educationOptions = [
  {
    text: `Elementary School ("Kinder", "1st - 5th")`,
    name: 'elementary'
  },
  {
    text: 'Middle School ("6th - 8th")',
    name: 'middle school'
  },
  {
    text: 'High School/University ("9th - 12th", "Adults")',
    name: 'high school'
  }
]

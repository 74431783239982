import { dispatchFetchApi, fetchApi } from './thunk'

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS'

export const getAnnouncementsAction = (payload) => {
  return {
    type: GET_ANNOUNCEMENTS,
    payload
  }
}

export const getAnnouncements = () => {
  return dispatchFetchApi(
    '/announcements',
    getAnnouncementsAction
  )
}

export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMNETS'

export const createAnnouncementAction = (payload) => {
  return {
    type: CREATE_ANNOUNCEMENT,
    payload
  }
}

export const createAnnouncement = (data, toggle) => {
  return async (dispatch) => {
    const { payload, type } = await dispatch(fetchApi(
      `/announcements`,
      'POST',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(createAnnouncementAction(payload))
      toggle(false)
    }
  }
}

export const EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENTS'

export const editAnnouncementAction = (payload) => {
  return {
    type: EDIT_ANNOUNCEMENT,
    payload
  }
}

export const editAnnouncement = (data, toggle) => {
  const { _id } = data
  return async (dispatch) => {
    const { payload, type } = await dispatch(fetchApi(
      `/announcements/${_id}`,
      'PUT',
      data
    ))

    if (type === 'SUCCESS') {
      dispatch(editAnnouncementAction(payload))
      toggle(false)
    }
  }
}

export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENTS'

export const deleteAnnouncementAction = (payload) => {
  return {
    type: DELETE_ANNOUNCEMENT,
    payload
  }
}

export const deleteAnnouncement = (_id) => {
  return dispatchFetchApi(
    `/announcements/${_id}`,
    deleteAnnouncementAction,
    'DELETE',
    {}
  )
}

export const ARCHIVE_ANNOUNCEMENT = 'ARCHIVE_ANNOUNCMENTS'

export const archiveAnnouncementAction = (payload) => {
  return {
    type: ARCHIVE_ANNOUNCEMENT,
    payload
  }
}

export const archiveAnnouncement = (_id) => {
  return dispatchFetchApi(
    `/announcements/${_id}/archive`,
    archiveAnnouncementAction,
    'POST',
    {}
  )
}

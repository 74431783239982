import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'
import { GrooveWidget } from '../Widgets'

const GlobalLayout = (props) => {
  const { isAuthenticated, user } = useSelector(state => state.auth) || {}
  const { roles } = user || {}
  const { isFetched } = useSelector(state => state.initial)
  const { show } = useSelector(state => state.ui.sidebar)

  if (isAuthenticated && isFetched && roles) {
    return (
      <Box>
        <Sidebar />
        <Box 
          sx={{ 
            minHeight: '90vh', 
            marginLeft: { xs: '0px', sm: show ? '50px' : '0px' } 
          }}>
          <Header />
          {props.children}
        </Box>
        <Footer />
        <GrooveWidget roles={roles} />
      </Box>
    )
  } else {
    return null
  }
}

export default GlobalLayout

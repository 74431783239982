import { push } from 'redux-first-history'

// ACTIONS
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const SIDEBAR_FOCUS = 'SIDEBAR_FOCUS'
export const SIDEBAR_SUBMENU_TOGGLE = 'SIDEBAR_SUBMENU_TOGGLE'
export const SET_LESSON_NODES = 'SET_LESSONS_NODES'
export const HIDE_NAVBAR = 'HIDE_NAVBAR'
export const SHOW_NAVBAR = 'SHOW_NAVBAR'

// ACTION CREATORS
export function toggle (payload) {
  return {
    type: SIDEBAR_TOGGLE,
    payload
  }
}

export function hideNavbar () {
  return {
    type: HIDE_NAVBAR
  }
}

export function showNavbar () {
  return {
    type: SHOW_NAVBAR
  }
}

export function focus (payload) {
  return {
    type: SIDEBAR_FOCUS,
    payload
  }
}

export function submenuToggle (payload) {
  return {
    type: SIDEBAR_SUBMENU_TOGGLE,
    payload
  }
}

export function addNodes (payload) {
  return {
    type: SET_LESSON_NODES,
    payload
  }
}

// THUNKS
export function navigate (payload) {
  return function (dispatch, getState) {
    const {
      url
    } = payload

    // turn off our submenus and close the sidebar
    dispatch(toggle(false))
    dispatch(
      submenuToggle({
        key: 'ALL', // special key to target all submenus
        open: false
      })
    )
    dispatch(
      push({
        pathname: url,
        state: payload
      })
    )
  }
}

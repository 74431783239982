import { useSelector, useDispatch } from 'react-redux'
import { Box, TextInput, Text, Anchor } from 'grommet'
import { useDebounce } from '../../../hooks/useDebounce'
import { formConstants } from '../../../screens/Districts/constants'
import { FormItem } from '../../Form/FormItem'
import { setDistrictState, setOnboardingState } from '../../../actions/onboarding'
import { initDistrictState, initSchoolState } from '../../../reducers/ui/onboarding'
import { useEffect } from 'react'

const CreateDistrict = () => {
  const { districtState: state } = useSelector(state => state.ui.onboarding)
  const dispatch = useDispatch()

  const flag = formConstants.map(({ name }) => state[name]).includes('')
  const debounceFlag = useDebounce(flag, 100)

  // handles onChange
  useEffect(() => {
    dispatch(setOnboardingState({
      nextBtnDisabled: flag
    }))
  }, [debounceFlag])

  const onClick = () => {
    dispatch(setOnboardingState({
      showDistrictCreate: false,
      districtState: initDistrictState,
      schoolState: initSchoolState,
      nextBtnDisabled: true,
      showSchoolCreate: false
    }))
  }

  return (
    <Box gap='small' pad='small'>
      <Text size='small' weight='bold'> Enter district information: </Text>
      <Box>
        {formConstants.map(({ text, placeholder, name }, i) => (
          <FormItem label={text} name={name} size='medium' key={i} required>
            <TextInput
              name={name}
              id={name}
              placeholder={placeholder}
              value={state[name]}
              onChange={(e) => dispatch(setDistrictState({ [name]: e.target.value }))}
            />
          </FormItem>
        ))}
      </Box>
      <Anchor label='Return to search view' onClick={onClick} margin={{ left: 'xsmall' }} />
    </Box>
  )
}

export default CreateDistrict

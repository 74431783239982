export const formConstants = [
  {
    text: 'District Name*:',
    placeholder: 'Enter district name...',
    name: 'name'
  },
  {
    text: 'Location*:',
    placeholder: 'Enter city of district...',
    name: 'location'
  },
  {
    text: 'Zip Code*:',
    placeholder: 'Enter zipcode of district...',
    name: 'zipCode'
  }
]

/* global fetch */
import { useState } from 'react'
import { Box, Form, Text, FormField, TextInput, Button, Anchor } from 'grommet'
import { FormBox } from '../../Form/FormBox'
import SuccessMessage from './Success'
import ErrorMessage from './Error'
import { generateOptions, address } from '../../../actions/constants'
import { formFieldStyle } from './../styles'
import { Loader } from '../../GlobalLoader/Loader'
import Logo from '../../Logo'

const UserInformation = ({ firstName, setState, lastName, emailAddress, referralCode, isColoringBook }) => {
  const [showStatusPage, setStatus] = useState(null)
  const [showLoader, toggleLoader] = useState(false)
  const useSpanishText = isColoringBook && referralCode === 'conejo'

  const onFormChange = (name, value) => {
    setState({ [name]: value })
  }

  const isButtonDisabled = () => {
    return [firstName, lastName, emailAddress].includes('')
  }

  const onSubmit = async () => {
    toggleLoader(true)
    const data = {
      firstName,
      lastName,
      email: emailAddress,
      referral: referralCode
    }

    const createAccount = async () => {
      const options = generateOptions('POST', data)
      const response = await fetch(`${address}/signup/free`, options)
      const json = await response.json()
      return json
    }

    const { type } = await createAccount()
    toggleLoader(false)
    setStatus(type === 'SUCCESS')
  }

  const DescriptionText = () => {
    if (isColoringBook) {
      if (referralCode === 'rabbit') {
        return 'Primary school teachers, we understand reading and communication are fundamental in developing life-long learners. Through a grant from the ISS National Lab, we offer these free bilingual lessons and access to the digital version of a workbook/coloring book.'
      } else {
        return 'Maestros de escuela primaria, entendemos que la lectura y la comunicación son fundamentales para desarrollar estudiantes de por vida. A través de una subvención del Laboratorio Nacional de la ISS, ofrecemos estas lecciones bilingües gratuitas y acceso a la versión digital de un libro de trabajo/libro para colorear.'
      }
    } else {
      return 'Create a free educator account to experience a sample of our programming. ExoLab Replay launches in March 2025. Plan now to be sure you are included in the live mission.'
    }
  }

  if (showStatusPage !== null) {
    return showStatusPage ? <SuccessMessage firstName={firstName} /> : <ErrorMessage />
  } else {
    return (
      <Box pad={{ vertical: '3%', horizontal: '5%' }} gap='medium'>
        {showLoader && <Loader />}
        <Box height='75px' width='280px' alignSelf='start'>
          <Logo dark />
        </Box>
        <Text size='small'>
          <DescriptionText />
        </Text>
        <Form onSubmit={onSubmit}>
          <Box gap='small'>
            <FormField
              css={formFieldStyle}
              label={useSpanishText ? 'Nombre' : 'First Name'}
              name='firstName'
              placeholder={useSpanishText ? 'Ingrese el nombre' : 'Enter first name'}
              value={firstName}
              onChange={(e) => onFormChange('firstName', e.target.value)}
              required
            />
            <FormField
              css={formFieldStyle}
              label={useSpanishText ? 'Apellido' : 'Last Name'}
              name='lastName'
              placeholder={useSpanishText ? 'Ingrese apellido' : 'Enter last name'}
              value={lastName}
              onChange={(e) => onFormChange('lastName', e.target.value)}
              required
            />
            <FormField
              css={formFieldStyle}
              label={useSpanishText ? 'Correo electrónico': 'Email'}
              name='email'
              type='email'
              placeholder={useSpanishText ? 'Ingrese correo electrónico' : 'Enter email address'}
              value={emailAddress}
              onChange={(e) => onFormChange('emailAddress', e.target.value)}
            />
            <FormBox style={{ display: 'none' }}>
              <FormField label='Honeypot' name='bot' type='type'>
                <TextInput name='Bot' placeholder='Hidden field for bots' autoComplete='off' />
              </FormField>
            </FormBox>
          </Box>
          <Box margin={{ top: 'small' }} justify='between'>
            <Anchor label={useSpanishText ? 'Volver a iniciar sesión' : 'Return to login'} href='/login' alignSelf='start' />
            <Button disabled={isButtonDisabled()} label={useSpanishText ? 'Entregar' : 'Submit'} type='submit' alignSelf='end' primary />
          </Box>
        </Form>
      </Box>
    )
  }
}

export default UserInformation

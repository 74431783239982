import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Text } from 'grommet'
import SearchFilterComp from './SearchFilter'
import { setOnboardingState } from '../../../actions/onboarding'
import { districtSearch } from '../../../actions/district'
import DistrictCreate from '../Create/District'

const DistrictSearchBox = () => {
  const {
    districtSelection,
    districtQuery,
    showDistrictCreate
  } = useSelector(state => state.ui.onboarding)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!showDistrictCreate) {
      dispatch(setOnboardingState({
        nextBtnDisabled: !districtSelection
      }))
    }
  }, [])

  const onChange = (e) => {
    const { value } = e.target
    dispatch(setOnboardingState({
      districtQuery: value,
      districtSelection: null,
      schoolQuery: '',
      schoolSelection: null
    }))
  }

  if (showDistrictCreate) {
    return <DistrictCreate />
  } else {
    return (
      <Box animation='fadeIn' gap='small'>
        <Text size='small' weight='bold'> 2) Which district are you part of? </Text>
        <Text textAlign='center' weight='bold' color='brand' size='small'>
          {!districtSelection && 'Please enter district you wish to search for.'}
        </Text>
        <SearchFilterComp
          onChange={onChange}
          searchValue={districtQuery}
          value={districtSelection}
          name='districtSelection'
          displayName='District'
          setState={(e) => dispatch(setOnboardingState(e))}
          apiMethod={() => dispatch(districtSearch(districtQuery))}
        />
      </Box>
    )
  }
}

export default DistrictSearchBox

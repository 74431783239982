import { css } from '@emotion/react'

export const formFieldStyle = css`
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  padding: 5px;
`

export const buttonStyle = css`
  padding: 7px 30px;
`

export const ssoButtonStyle = css`
  padding: 0;
  margin: 0;
`

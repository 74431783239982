import {
  PRODUCTS_LIST_SUCCESS,
  PRODUCTS_LIST_FAIL,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL
} from '../../actions/products'

const initialState = {
  isFetched: false,
  entities: {},
  ids: []
}

export default function products (
  state = initialState,
  action
) {
  switch (action.type) {
    default: {
      return state
    }
    case 'CLEAR_PRODUCTS': {
      return initialState
    }
    case PRODUCTS_LIST_SUCCESS: {
      const newState = { ...state }
      const { entities, ids } = action.payload.products

      newState.isFetched = true
      newState.entities = entities
      newState.ids = ids

      return newState
    }
    case PRODUCT_ADD_SUCCESS: {
      const newState = { ...state }
      const { entities, ids } = action.payload.products

      newState.isFetched = true
      newState.entities = entities
      newState.ids = ids

      return newState
    }
    case PRODUCT_DELETE_SUCCESS: {
      const newState = { ...state }
      const { _id } = action.payload.product

      delete newState.entities[ _id ]

      const index = newState.ids.indexOf(_id)
      newState.ids.splice(index, 1)

      return newState
    }
    case PRODUCT_ADD_FAIL: {
      const newState = { ...state }
      newState.isFetched = false
      return newState
    }
    case PRODUCTS_LIST_FAIL: {
      const newState = { ...state }
      newState.isFetched = false
      return newState
    }
    case PRODUCT_DELETE_FAIL: {
      const newState = { ...state }
      newState.isFetched = false
      return newState
    }
  }
}

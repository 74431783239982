import {
  SHOW_ERROR,
  SHOW_SUCCESS,
  SHOW_MESSAGE,
  CLEAR_FLASH_OVERLAY
} from '../../actions/flashOverlay'

const initialState = {
  showOverlay: false,
  type: null,
  message: null
}

export const flashOverlayReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SHOW_ERROR: {
      return {
        showOverlay: true,
        type: 'error',
        message: payload.message
      }
    }
    case SHOW_SUCCESS: {
      return {
        showOverlay: true,
        type: 'success',
        message: payload.message
      }
    }
    case SHOW_MESSAGE: {
      return {
        showOverlay: true,
        message: payload.message
      }
    }
    case CLEAR_FLASH_OVERLAY: {
      return {
        showOverlay: false,
        type: null,
        message: null
      }
    }
    default: {
      return state
    }
  }
}

export default flashOverlayReducer

// FETCH THUNK
import { dispatchFetchApi, fetchApi } from './thunk'
import { showMessage } from './flashOverlay'

/* Get classes constants + actions  */
export const CLASSES_LIST = 'CLASSES_LIST'

export function listClassesAction (payload) {
  return {
    type: CLASSES_LIST,
    payload
  }
}

export const getClasses = () => {
  return dispatchFetchApi(
    '/classes',
    listClassesAction
  )
}

/* Get class by slug constants + actions */
export const GET_CLASS = 'GET_CLASS'

export function getClassAction (payload) {
  return {
    type: GET_CLASS,
    payload: payload
  }
}

export const returnViewersRoles = (roles) => {
  const obj = {}

  for (let i = 0; i < roles.length; i++) {
    const { type } = roles[i]
    obj[type] = true
  }
  return obj
}

export const getClass = (slug, roles) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/classes/${slug}`))

    dispatch(getClassAction({
      ...payload,
      viewerRoles: roles ? returnViewersRoles(roles) : {}
    }))
  }
}

export const getClassByCode = (code) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/classes/${code}/basic`))

    dispatch(getClassAction({
      ...payload
    }))
  }
}

/* Get class lessons */
export const GET_CLASS_LESSONS = 'GET_CLASS_LESSONS'

export function getClassLessonsAction (payload) {
  return {
    type: GET_CLASS_LESSONS,
    payload
  }
}

export const getClassLessons = (id) => {
  return dispatchFetchApi(
    `/classes/${id}/lessons`,
    getClassLessonsAction
  )
}

/* Add class actions + constants */
export const CLASS_ADD = 'CLASS_ADD'

export function addClassAction (payload) {
  return {
    type: CLASS_ADD,
    payload
  }
}

export const addClass = (data) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi('/classes', 'POST', data))
    dispatch(addClassAction(payload))
    return payload.class.slug
  }
}

/* Edit class constants + actions */
export const CLASS_EDIT = 'CLASS_EDIT'

export function editClassAction (payload) {
  return {
    type: CLASS_EDIT,
    payload
  }
}

export const editClass = (data) => {
  const { slug } = data
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/classes/${slug}`, 'PUT', data))
    dispatch(editClassAction(payload))
    return payload.class.slug
  }
}

/* Archive class constants + actions */
export const CLASS_ARCHIVE = 'CLASS_ARCHIVE'

export function archiveClassAction (payload) {
  return {
    type: CLASS_ARCHIVE,
    payload
  }
}

export const archiveClass = ({ _id }) => {
  return dispatchFetchApi(
    `/classes/${_id}/archive`,
    archiveClassAction,
    'PUT'
  )
}

/* Class unarchive constants + actions */
export const CLASS_UNARCHIVE = 'CLASS_UNARCHIVE'

export function unarchiveClassAction (payload) {
  return {
    type: CLASS_UNARCHIVE,
    payload
  }
}

export const unarchiveClass = ({ _id }) => {
  return dispatchFetchApi(
    `/classes/${_id}/unarchive`,
    unarchiveClassAction,
    'PUT'
  )
}

/* Get students in class constants + actions */
export const GET_CLASS_STUDENTS = 'GET_CLASS_STUDENTS'

export function getClassStudentsAction (payload) {
  return {
    type: GET_CLASS_STUDENTS,
    payload
  }
}

export const getClassStudents = (slug) => {
  return dispatchFetchApi(
    `/classes/${slug}/students`,
    getClassStudentsAction
  )
}

// assign experiment to class
export function assignExperimentToClass (data) {
  const { course, experimentId } = data
  return async (dispatch) => {
    await dispatch(fetchApi(`/classes/${course._id}/experiments/${experimentId}/assign`))
    return experimentId
  }
}

// Clear class dashboard
export const CLEAR_CLASS_DASHBOARD = 'CLEAR_CLASS_DASHBOARD'

export const clearClassDashboard = () => {
  return {
    type: CLEAR_CLASS_DASHBOARD
  }
}

// action for return lesson quiz report
export const exportQuizReport = (props) => {
  const { classId, lessonId } = props
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/classes/${classId}/lessons/${lessonId}/quizzes/report/export`))
    dispatch(showMessage({ message: 'Please check your email for quiz report.'}))

    return payload
  }
}

import {
  ADD_GC_COURSE,
  GET_ALL_GC_COURSES,
  CLEAR_GC_STATE
} from '../../actions/googleClassroom'

const initialState = {
  isFetched: false,
  profile: null,
  hasAccess: false,
  coursesFetched: false,
  showGCModal: false,
  courses: [],
  magnitudeClass: null,
  googleClassroomCourse: null,
  showGCForm: false,
  blurGCCourses: false,
  gcContext: null
}

const googleClassroomReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MAG_CLASS_RADIO': {
      return {
        ...state,
        magnitudeClass: action.payload
      }
    }
    case 'SET_GC_COURSE_RADIO': {
      return {
        ...state,
        googleClassroomCourse: action.payload
      }
    }
    case 'UPDATE_GC_STATE': {
      return {
        ...state,
        ...action.payload
      }
    }
    case GET_ALL_GC_COURSES: {
      const { courses } = action.payload
      return {
        ...state,
        coursesFetched: true,
        courses
      }
    }
    case ADD_GC_COURSE: {
      return {
        ...state,
        courses: [action.payload.course, ...state.courses]
      }
    }
    case CLEAR_GC_STATE: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default googleClassroomReducer

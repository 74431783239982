import { useSelector } from 'react-redux'
import { hasRole } from '../../utils/utilityFuncs'
import PrivateRoute from './PrivateRoute'

// a custom route for DeviceManager
export const DeviceManagerRoute = ({ ...rest }) => {
  const { roles } = useSelector(state => state.auth.user)
  const { enableStudentLedExoLabs } = useSelector(state => state.initial)

  const isStudent = hasRole(['student'], roles)
  const allowedRoles = ['teacher']

  if (isStudent && enableStudentLedExoLabs) {
    allowedRoles.push('student')
  }

  return (
    <PrivateRoute
      allowedRoles={allowedRoles}
      {...rest}
    />
  )
}

/* global fetch */
import { dispatchFetchApi, fetchApi } from './thunk'
import { showSuccess } from './flashOverlay'
import { getImagePath } from '../middleware/imageUpload'
import { generateOptions, address } from './constants'

/* Clear lesson constants + actions */
export const LESSON_UI_CLEAR = 'LESSON_UI_CLEAR'

export function clearLesson () {
  return {
    type: 'LESSON_UI_CLEAR'
  }
}

/* Clear lessons object */
export const CLEAR_LESSONS = 'CLEAR_LESSONS'

export function clearLessons () {
  return {
    type: CLEAR_LESSONS
  }
}

/* Get lesson constants + actions */
export const LESSON_GET = 'LESSON_GET'

export function getLessonAction (payload) {
  return {
    type: LESSON_GET,
    payload
  }
}

export const getLesson = (slug) => {
  return dispatchFetchApi(
    `/lessons/${slug}`,
    getLessonAction
  )
}

/* Get lessons constants + actions */
export const LESSONS_LIST = 'LESSONS_LIST'

export function listLessonsAction (payload) {
  return {
    type: LESSONS_LIST,
    payload
  }
}

export const LIST_PAST_MISSIONS = 'LIST_PAST_MISSIONS'

export function listPastMissionsAction (payload) {
  return {
    type: LIST_PAST_MISSIONS,
    payload
  }
}

// get all lessons (only approved lessons)
export const getLessons = (tag, isArchived) => {
  const encodeTag = encodeURIComponent(tag).toLocaleLowerCase()
  function urlBuilder () {
    let url = `/lessons`

    if (tag) {
      url = url.concat(`?tag=${encodeTag}`)
    }

    if (isArchived !== null && tag) {
      url = url.concat(`&isArchived=${isArchived}`)
    }

    return url
  }

  return dispatchFetchApi(
    urlBuilder(),
    listLessonsAction
  )
}

// get all unapproved lessons
export const getUnapprovedLessons = () => {
  return dispatchFetchApi(
    '/lessons/unapproved',
    listLessonsAction
  )
}

// get all archived lessons
export const getArchivedLessons = () => {
  return dispatchFetchApi(
    '/lessons/archived',
    listLessonsAction
  )
}

// get all past exolab missions
export const getPastExoLabMissions = () => {
  return fetchApi(
    '/lessons/exolab/past-missions'
  )
}

export const getPastExoLabMissionsTwo = () => {
  return dispatchFetchApi(
    '/lessons/exolab/past-missions',
    listPastMissionsAction
  )
}

// get all lessons for (superadmin, author)
export const getAuthorsLessons = (_id) => {
  return dispatchFetchApi(
    `/authors/${_id}/lessons`,
    listLessonsAction
  )
}

// get a user's lesson constants + actions
export const GET_TEACHERS_LESSONS = 'GET_TEACHERS_LESSONS'

export const getTeachersLessonsAction = (payload) => {
  return {
    type: GET_TEACHERS_LESSONS,
    payload
  }
}

export const getUserLessons = () => {
  return dispatchFetchApi(
    '/lessons/read',
    getTeachersLessonsAction,
    'POST',
    {}
  )
}

/* Move lesson constants + actions */
export const LESSON_MOVE = 'LESSON_MOVE'

export function moveLessonAction (payload) {
  return {
    type: LESSON_MOVE,
    payload
  }
}

export const resyncLessonsOrder = (product, lang) => {
  return async (dispatch) => {
    const json = await dispatch(fetchApi(`/lessons/${product}/${lang}/order-sync`, 'POST', {}))
    return json
  }
}

export const moveLesson = (orders, lesson) => {
  const { _id, language, product } = lesson
  const { slug } = product

  return async (dispatch) => {
    const { type, payload } = await dispatch(fetchApi(`/lessons/${_id}/order`, 'POST', orders))

    if (type === 'SUCCESS') {
      dispatch(moveLessonAction(payload))
    }

    if (type === 'FAIL' && payload === 'Lesson order out of sync from collection') {
      await dispatch(resyncLessonsOrder(slug, language))
      const { payload: data } = await dispatch(fetchApi(`/lessons/${id}/order`, 'POST', orders))
      dispatch(moveLessonAction(data))
    }
  }
}

/* Add lesson constants + actions */
export const MY_LESSON_ADD = 'MY_LESSON_ADD'
export const LESSON_ADD = 'LESSON_ADD'

export function addLessonAction (payload) {
  return {
    type: LESSON_ADD,
    payload
  }
}

export function addMyLessonAction (payload) {
  return {
    type: MY_LESSON_ADD,
    payload
  }
}

export const addLesson = ({ lessonData, imageData }) => {
  return async (dispatch) => {
    // first create lesson
    const { payload: lessonPayload } = await dispatch(fetchApi('/lessons', 'POST', lessonData))
    const { _id, slug } = lessonPayload.lesson

    // next get presign urls
    const { imageThumbnail, imageHeader } = imageData

    // if no images are being uploaded then redirect
    if (!imageThumbnail && !imageHeader) {
      return slug
    }

    let thumbnailImagePath
    let headerImagePath

    if (imageThumbnail) {
      thumbnailImagePath = await getImagePath(imageThumbnail, { type: 'lessons', id: _id })
    }

    if (imageHeader) {
      headerImagePath = await getImagePath(imageHeader, { type: 'lessons', id: _id })
    }

    // update lesson with aws url path for images
    const payload = {
      imageThumbnail: thumbnailImagePath,
      imageHeader: headerImagePath
    }

    const { payload: updatedLessonPayload } = await dispatch(fetchApi(
      `/lessons/${_id}`,
      'PUT',
      payload
    ))

    return updatedLessonPayload.lesson.slug
  }
}

/* Edit lesson constants + actions */
export const LESSON_EDIT = 'LESSON_EDIT'
export const MY_LESSON_EDIT = 'MY_LESSON_EDIT'

export function editLessonAction (payload) {
  return {
    type: LESSON_EDIT,
    payload
  }
}

export function editMyLessonAction (payload) {
  return {
    type: MY_LESSON_EDIT,
    payload
  }
}

export const editLesson = (data) => {
  const { imageHeader, imageThumbnail, route } = data

  return async (dispatch, getState) => {
    const { ui } = getState()
    const { lesson } = ui

    let thumbnailImagePath = imageThumbnail
    let headerImagePath = imageHeader

    if (imageHeader && imageHeader !== lesson.imageHeader) {
      headerImagePath = await getImagePath(imageHeader, { type: 'lessons', id: lesson._id })
    }

    if (imageThumbnail && imageThumbnail !== lesson.imageThumbnail) {
      thumbnailImagePath = await getImagePath(imageThumbnail, { type: 'lessons', id: lesson._id })
    }

    const newPayload = {
      ...data,
      imageThumbnail: thumbnailImagePath,
      imageHeader: headerImagePath
    }

    const { payload } = await dispatch(fetchApi(`/lessons/${lesson._id}`, 'PUT', newPayload))

    if (route === '/my-lessons') {
      dispatch(editMyLessonAction(payload))
    } else {
      dispatch(editLessonAction(payload))
    }

    return payload.lesson.slug
  }
}

/* Delete lesson constants + actions */
export const LESSON_DELETE = 'LESSON_DELETE'
export const MY_LESSON_DELETE = 'MY_LESSON_DELETE'

export function deleteLessonAction (payload) {
  return {
    type: LESSON_DELETE,
    payload
  }
}

export function deleteMyLessonAction (payload) {
  return {
    type: MY_LESSON_DELETE,
    payload
  }
}

export const deleteLesson = (_id, route) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(`/lessons/${_id}`, 'DELETE'))

    // my lessons vs lesson library
    if (route === '/my-lessons') {
      dispatch(deleteMyLessonAction(payload))
    } else {
      dispatch(deleteLessonAction(payload))
    }
    return route
  }
}

/* Clone lesson constants + actions */
export const LESSON_CLONE = 'LESSON_CLONE'

export function cloneLessonAction (payload) {
  return {
    type: LESSON_CLONE,
    payload
  }
}

export const cloneLesson = (data, route) => {
  return async (dispatch) => {
    const { payload } = await dispatch(fetchApi(
      '/lessons/clone',
      'POST',
      data
    ))

    if (route === '/my-lessons') {
      dispatch(addMyLessonAction(payload))
    }

    dispatch(cloneLessonAction(payload))
    dispatch(showSuccess({ message: 'Successfully cloned lesson!' }))
    return payload.lesson.slug
  }
}

/* Assign lesson constants + actions */
export const LESSON_ASSIGN = 'LESSON_ASSIGN'

export function assignLessonAction (payload) {
  return {
    type: LESSON_ASSIGN,
    payload
  }
}

export const assignLesson = (data, route, courseWorkCallBack) => {
  return async (dispatch) => {
    const options = generateOptions('POST', data)
    try {
      const response = await fetch(`${address}/classes/${data.classId}/lessons`, options)
      const { payload } = await response.json()

      if (response.status === 422) {
        // reactivate that class instead of adding it
        await dispatch(reactivateClassLesson(data.classId, data.lesson._id))
      } else {
        if (courseWorkCallBack) {
          courseWorkCallBack()
        }
        dispatch(assignLessonAction(payload))
      }
      if (route) return route

      dispatch(showSuccess({
        message: 'Successfully assigned lesson to class.'
      }))
    } catch (error) {
      throw (error)
    }
  }
}

export const LESSON_UNASSIGN = 'LESSON_UNASSIGN'

export function unassignLessonAction (payload) {
  return {
    type: LESSON_UNASSIGN,
    payload
  }
}

export const unassignLesson = (classId, lessonId) => {
  return dispatchFetchApi(
    `/classes/${classId}/lessons/${lessonId}`,
    unassignLessonAction,
    'DELETE'
  )
}

// set active field to false for a class lesson.
export const deactivateClassLesson = (classId, lessonId) => {
  return fetchApi(
    `/classes/${classId}/lessons/${lessonId}/deactivate`
  )
}

// set active field to true for a class lesson.
export const reactivateClassLesson = (classId, lessonId) => {
  return fetchApi(
    `/classes/${classId}/lessons/${lessonId}/reactivate`
  )
}

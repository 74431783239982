import {
  NOTES_LIST,
  NOTE_DELETE,
  NOTE_EDIT,
  NOTE_ADD
} from '../../actions/notes'

const initialState = {
  isFetched: false,
  entities: {},
  ids: []
}

export default function notes (state = initialState, { payload, type }) {
  switch (type) {
    default: {
      return state
    }
    case 'CLEAR_NOTES': {
      return initialState
    }
    case NOTES_LIST: {
      const { entities, ids } = payload.notes

      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case NOTE_ADD: {
      const { note } = payload
      const { _id } = note

      // add new id
      const newIds = [...state.ids]
      newIds.push(_id)

      // add note to entities object
      const newEntities = { ...state.entities }
      newEntities[_id] = note

      return {
        ...state,
        ids: newIds,
        entities: newEntities
      }
    }
    case NOTE_DELETE: {
      const { _id } = payload.updatedNote
      const newIds = [...state.ids].filter(id => id !== _id)

      const newEntities = { ...state.entities }
      delete newEntities[_id]

      return {
        ...state,
        ids: newIds,
        entities: newEntities
      }
    }
    case NOTE_EDIT: {
      const { _id } = payload.note
      const newEntities = { ...state.entities }
      newEntities[_id] = payload.note

      return {
        ...state,
        entities: newEntities
      }
    }
  }
}

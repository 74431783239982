export const SET_ERROR_STATUS = 'SET_ERROR_STATUS'

export const setErrorStatus = (payload) => {
  return {
    type: SET_ERROR_STATUS,
    payload
  }
}

export const CLEAR_ERROR_STATUS = 'CLEAR_ERROR_STATUS'

export const clearErrorStatus = () => {
  return {
    type: CLEAR_ERROR_STATUS
  }
}

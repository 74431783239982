import {
  DISTRICTS_LIST,
  DISTRICT_DELETE,
  DISTRICT_EDIT
} from '../../actions/district'

export default function district (
  state = {
    isFetched: false,
    entities: {},
    ids: []
  },
  { payload, type }
) {
  switch (type) {
    case 'CLEAR_DISTRICTS': {
      return {
        isFetched: false,
        entities: {},
        ids: []
      }
    }
    case DISTRICTS_LIST: {
      const { entities, ids } = payload.districts
      return {
        isFetched: true,
        ids,
        entities
      }
    }
    case DISTRICT_DELETE: {
      const { _id } = payload.district

      // remove district
      const newIds = [...state.ids].filter(id => id !== _id)
      const newEntities = { ...state.entities }
      delete newEntities[_id]

      return {
        ...state,
        ids: newIds,
        entities: newEntities
      }
    }
    case DISTRICT_EDIT: {
      const { _id } = payload.district
      // update state with new entities
      const newEntities = { ...state.entities }
      newEntities[_id] = payload.district

      return {
        ...state,
        entities: newEntities
      }
    }
    default: {
      return state
    }
  }
}

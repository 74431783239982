import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { hasRole } from '../../utils/utilityFuncs'
import DistrictSchoolFlow from '../../components/DistrictSchoolFlow'

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { roles, school, _id } = useSelector(state => state.auth.user) || {}

  const redirectPath = JSON.parse(localStorage.getItem('redirectPaths'))

  if (redirectPath) {
    const path = redirectPath['default'] || redirectPath[_id]

    if (path) {
      localStorage.removeItem('redirectPaths')
      return <Redirect to={{ pathname: path }} />
    }

  }


  if (roles) {
    // inject FreeUserFlow here
    if (hasRole(['teacher'], roles) && !school) return <DistrictSchoolFlow />

    return (
      <Route {...rest} render={({ location, ...moreProps }) => {
        // if no allowedRoles is passed then any role can access this view
        if (allowedRoles && !hasRole(allowedRoles, roles)) {
          return <Redirect to={{ pathname: '/' }} from={location} />
        } else {
          return <Component {...moreProps} />
        }
      }}
      />
    )
  } else {
    return null
  }
}

export default PrivateRoute
